import React from 'react';
import { usePagination, DOTS } from './usePagination';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

interface PaginationProps {
    onPageChange: (page: number) => void;
    totalCount: number;
    siblingCount?: number;
    currentPage: number;
    pageSize: number;
}

const Pagination: React.FC<PaginationProps> = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
        return null;
    }

    const lastPage = paginationRange[paginationRange.length - 1] as number;

    const onNext = () => {
        if(currentPage < lastPage){
            onPageChange(currentPage + 1);
        }
    };

    const onPrevious = () => {
        if(currentPage > 1){
            onPageChange(currentPage - 1);
        }
    };

    return (
        <nav className="px-4 py-4 flex flex-col-reverse md:flex-row items-center justify-end pt-4" aria-label="Table navigation">
            <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                <li onClick={onPrevious}>
                    <div className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === 1 ? 'text-gray-400' : 'text-[#5E72E4]'} ${currentPage === 1 ? 'bg-gray-300' : 'bg-white' } border border-[#5E72E4] rounded-s-lg ${currentPage === 1 ? '' : 'hover:bg-[#5E72E4]'} hover:text-white
                    ${currentPage === 1 ? 'pointer-events-none' : ''}`}>
                        <IoIosArrowBack />
                    </div>
                </li>
                {paginationRange.map((pageNumber, index) => {
                    if (pageNumber === DOTS) {
                        return (
                            <li key={index} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-[#5E72E4] hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                &#8230;
                            </li>
                        );
                    }

                    return (
                        <li
                            key={index}
                            className={`flex items-center justify-center px-3 h-8 leading-tight ${pageNumber === currentPage ? 'text-white' : 'text-[#5E72E4]'} ${pageNumber === currentPage ? 'bg-[#5E72E4]' : 'bg-white'} border border-[#5E72E4] hover:bg-[#5E72E4] hover:text-white cursor-pointer`}
                            onClick={() => onPageChange(pageNumber as number)}
                        >
                            {pageNumber}
                        </li>
                    );
                })}
                <li onClick={onNext}>
                    <div className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === lastPage ? 'text-gray-400' : 'text-[#5E72E4]'} ${currentPage === lastPage ? 'bg-gray-300' : 'bg-white' } border border-[#5E72E4] rounded-e-lg ${currentPage === lastPage ? '' : 'hover:bg-[#5E72E4]'} hover:text-white
                        ${currentPage === lastPage ? 'pointer-events-none' : ''}`}>
                        <IoIosArrowForward />
                    </div>
                </li>
            </ul>
        </nav>
    )
};

export default Pagination;
