import { FaUsers } from 'react-icons/fa';
import { GrTransaction } from 'react-icons/gr';
import { RiBillFill } from 'react-icons/ri';
import { Bar, Line } from 'react-chartjs-2';
import { chartBarData, chartBarOptions, chartLineData, chartLineOptions } from '../Variables/ChartConfig';
import { useDashboardStore } from '../../stores/dashboard/dashboard.store';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { MaterialGray1 } from '../utilities/Util';
import ErrorIcon from '../../assets/images/error_icon.svg';

export const DashboardPage = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [isMonth, setIsMonth] = useState<boolean>(true)
  const fetchDashboard = useDashboardStore((state) => state.fetchDashboard)
  const dashboardState = useDashboardStore((state) => state.dashboardState)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    fetchDashboard()
  }, [])

  const buildInsightCards = () => {
    switch (dashboardState.type) {
      case 'Idle':
      case 'Loading':
        return (
          <>
            {/* Card 1 */}
            <div className="bg-white shadow-md rounded-lg px-6 py-6 flex items-center justify-between flex-grow w-full sm:w-80">
              <div className="flex-grow">
                <Skeleton width="40%" height={20} highlightColor={MaterialGray1} count={1} />
                <Skeleton width="50%" height={20} highlightColor={MaterialGray1} count={1} />
                <Skeleton width="60%" height={20} highlightColor={MaterialGray1} count={1} />
              </div>
            </div>

            {/* Card 2 */}
            <div className="bg-white shadow-md rounded-lg px-6 py-6 flex items-center justify-between flex-grow w-full sm:w-80">
              <div className="flex-grow">
                <Skeleton width="40%" height={20} highlightColor={MaterialGray1} count={1} />
                <Skeleton width="50%" height={20} highlightColor={MaterialGray1} count={1} />
                <Skeleton width="60%" height={20} highlightColor={MaterialGray1} count={1} />
              </div>
            </div>

            {/* Card 3 */}
            <div className="bg-white shadow-md rounded-lg px-6 py-6 flex items-center justify-between flex-grow w-full sm:w-80">
              <div className="flex-grow">
                <Skeleton width="40%" height={20} highlightColor={MaterialGray1} count={1} />
                <Skeleton width="50%" height={20} highlightColor={MaterialGray1} count={1} />
                <Skeleton width="60%" height={20} highlightColor={MaterialGray1} count={1} />
              </div>
            </div>
          </>
        )
      case 'Success':
        return (
          <>
            {/* Card 1 */}
            <div className="bg-white shadow-md rounded-lg px-6 py-6 flex items-center justify-between flex-grow w-full sm:w-80">
              <div>
                <h2 className="text-gray-500 text-sm font-semibold">ORDER BARU</h2>
                <p className="text-base font-bold font-semibold py-2">{dashboardState.data.today_order_data.today_count}</p>
                <p className="text-sm text-gray-400">{dashboardState.data.today_order_data.percentage}% <span>Since yesterday</span></p>
              </div>
              <div style={{ backgroundColor: '#66bb69' }} className="rounded-full p-2 px-3 py-3">
                <RiBillFill style={{ fontSize: '20px' }} className="text-white" />
              </div>
            </div>

            {/* Card 2 */}
            <div className="bg-white shadow-md rounded-lg px-6 py-6 flex items-center justify-between flex-grow w-full sm:w-80">
              <div>
                <h2 className="text-gray-500 text-sm font-semibold">MITRA BARU</h2>
                <p className="text-base font-bold font-semibold py-2">{dashboardState.data.today_mitra_data.today_count}</p>
                <p className="text-sm text-gray-400">{dashboardState.data.today_mitra_data.percentage}% <span>Since yesterday</span></p>
              </div>
              <div style={{ backgroundColor: '#66bb69' }} className="rounded-full p-2 px-3 py-3">
                <FaUsers style={{ fontSize: '20px' }} className="text-white" />
              </div>
            </div>

            {/* Card 3 */}
            <div className="bg-white shadow-md rounded-lg px-6 py-6 flex items-center justify-between flex-grow w-full sm:w-80">
              <div>
                <h2 className="text-gray-500 text-sm font-semibold">TRANSAKSI BARU</h2>
                <p className="text-base font-bold font-semibold py-2">{dashboardState.data.today_transaction_data.today_count}</p>
                <p className="text-sm text-gray-400">{dashboardState.data.today_transaction_data.percentage}% <span>Since yesterday</span></p>
              </div>
              <div style={{ backgroundColor: '#66bb69' }} className="rounded-full p-2 px-3 py-3">
                <GrTransaction style={{ fontSize: '20px' }} className="text-white" />
              </div>
            </div>
          </>
        )
      case 'Failed':
        return (
          <>
            {/* Card 1 */}
            <div className="bg-white shadow-md rounded-lg px-6 py-6 flex items-center justify-between flex-grow w-full sm:w-80">
              <div className="flex-grow flex flex-col items-center justify-center">
                {buildErrorView()}
              </div>
            </div>

            {/* Card 2 */}
            <div className="bg-white shadow-md rounded-lg px-6 py-6 flex items-center justify-between flex-grow w-full sm:w-80">
              <div className="flex-grow flex flex-col items-center justify-center">
                {buildErrorView()}
              </div>
            </div>

            {/* Card 3 */}
            <div className="bg-white shadow-md rounded-lg px-6 py-6 flex items-center justify-between flex-grow w-full sm:w-80">
              <div className="flex-grow flex flex-col items-center justify-center">
                {buildErrorView()}
              </div>
            </div>
          </>
        )
    }
  }

  const buildInsightCharts = () => {
    switch (dashboardState.type) {
      case 'Idle':
      case 'Loading':
        return (
          <>
            <div className="w-full">
              <div className="bg-white text-white shadow-lg rounded-lg overflow-hidden">
                <div className="p-4">
                  <div className="relative w-full">
                    <Skeleton width="40%" />
                    <Skeleton width="40%" />
                  </div>
                </div>
                <div className="p-4">
                  <div className="relative w-full h-40 md:h-64">
                    <div className="absolute inset-0">
                      <Skeleton height="100%" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Card 2 */}
            <div className="w-full">
              <div className="bg-white text-white shadow-lg rounded-lg overflow-hidden">
                <div className="p-4">
                  <div className="relative w-full">
                    <Skeleton width="40%" />
                    <Skeleton width="40%" />
                  </div>
                </div>
                <div className="p-4">
                  <div className="relative w-full h-40 md:h-64">
                    <div className="absolute inset-0">
                      <Skeleton height="100%" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      case 'Success':
        const labelMonthArray = []
        const dataMonthArray = []

        const labelWeekArray = []
        const dataWeekArray = []
        const overview_month = dashboardState.data.overview_month
        for(let i = 0; i < overview_month.length; i++){
          labelMonthArray.push(overview_month[i].bulan)
          dataMonthArray.push(overview_month[0].total_revenue)
        }
        const overview_week = dashboardState.data.overview_week
        for(let i = 0; i < overview_week.length; i++){
          labelWeekArray.push(overview_week[i].month_week)
          dataWeekArray.push(overview_week[i].total_transaction)
        }
        const payload_month = {
          labels: labelMonthArray,
          datasets: [
            {
              label: "Revenue",
              data: dataMonthArray
            }
          ]
        }

        const payload_week = {
          labels: labelWeekArray,
          datasets: [
            {
              label: "Revenue",
              data: dataWeekArray
            }
          ]
        }

        const labelsArray = []
        const dataArray = []

        const total_orders = dashboardState.data.total_orders_by_month
        for(let i = 0; i < overview_week.length; i++){
          labelsArray.push(total_orders[i].bulan)
          dataArray.push(total_orders[i].order_count)
        }

        const payload_bar_chart = {
          labels: labelsArray,
          datasets: [
            {
              label: "Order",
              data: dataArray,
              maxBarThickness: 5
            }
          ]
        }
        return (
          <>
            <div className="w-full">
              <div className="bg-[#191b4d] text-white shadow-lg rounded-lg overflow-hidden">
                <div className="p-7">
                  <div className="flex justify-between items-center">
                    <div>
                      <h6 className="text-xs uppercase font-semibold">Pendapatan</h6>
                      <h2 className="text-lg font-bold">Review Pendapatan</h2>
                    </div>
                    <div>
                      <nav className="flex space-x-2">
                        <button onClick={() => setIsMonth(true)} className={`px-3 rounded-lg ${isMonth ? 'bg-[#5e71e4] text-white' : 'bg-white text-[#5e71e4]'} text-sm`}>
                          {isMobile ? 'M' : 'Month'}
                        </button>
                        <button onClick={() => setIsMonth(false)} className={`py-2 px-3 rounded-lg ${isMonth == false ? 'bg-[#5e71e4] text-white' : 'bg-white text-[#5e71e4]'} text-sm`}>
                          {isMobile ? 'W' : 'Week'}
                        </button>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="p-4">
                  <div className="relative w-full h-40 md:h-64">
                    <div className="absolute inset-0">
                      <Line
                        data={isMonth ? payload_month : payload_week}
                        options={chartLineOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Card 2 */}
            <div className="w-full">
              <div className="bg-white text-black shadow-lg rounded-lg overflow-hidden">
                <div className="p-7">
                  <div className="flex justify-between items-center">
                    <div>
                      <h6 className="text-xs uppercase font-semibold">Performa</h6>
                      <h2 className="text-lg font-bold">Total Order Bulanan</h2>
                    </div>
                  </div>
                </div>
                <div className="p-4">
                  <div className="relative w-full h-40 md:h-64">
                    <div className="absolute inset-0">
                      <Bar
                        data={payload_bar_chart}
                        options={chartBarOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      case 'Failed':
        return (
          <>
            <div className="w-full">
              <div className="bg-white text-white shadow-lg rounded-lg overflow-hidden">
                <div className="p-9">
                  <div className="relative flex flex-col w-full h-40 md:h-64">
                    <div className="absolute inset-0 flex flex-col items-center justify-center">
                      {buildErrorView()}
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="w-full">
              <div className="bg-white text-white shadow-lg rounded-lg overflow-hidden">
                <div className="p-9">
                  <div className="relative flex flex-col w-full h-40 md:h-64">
                    <div className="absolute inset-0 flex flex-col items-center justify-center">
                      {buildErrorView()}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>
        )
    }
  }

  const buildErrorView = () => {
    return (
      <>
        <img
          style={{ width: '50px', height: '50px' }}
          src={ErrorIcon}
          alt="Error Icon"
        />
        <span className="text-sm text-center text-black mt-3">Gagal memuat data</span>
      </>
    )
  }

  return (
    <div>
      <div className="relative flex flex-col">
        {/* Cards Row */}
        <div className="flex flex-wrap gap-4 justify-center mb-4">
          {buildInsightCards()}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-4">
          {/* Card 1 */}
          {buildInsightCharts()}
        </div>
      </div>
    </div>
  )
}