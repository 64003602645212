import React from 'react';
import AnimatedIcon from '../views/AnimatedCheck';
import { MaterialGray1, MaterialGray2, MaterialGray3, MaterialGray4, MaterialRed1, MaterialYellow1 } from './Util';
import Spinner from './Loading';
import '../../assets/css/slideUpDown.css';
import { Toast } from './toast';

export type ShowToastType = 'SlideUp' | 'SlideDown' | 'Hide'

type AlertType = 'warning' | 'success' | 'failed' | 'custom';

interface ModalAlertProps {
  onClose: () => void;
  type: AlertType;
  icon?: React.ReactNode; // Optional custom icon
  title: string;
  subtitle?: React.ReactNode;
  inputs?: Array<{ placeholder: string; type: string; name: string; value: string; errorInputState: boolean; errorBorderColor: string; errorText: string; errorTextColor: string; onChange: (value: string) => void }>;
  onConfirm?: () => void;
  onCancel?: () => void;
  onConfirmEnabled?: boolean | true;
  onCancelEnabled?: boolean | true;
  
  enableConfirmTextColor? : string;
  enableConfirmBgColor? : string;
  disableConfirmTextColor? : string;
  disableConfirmBgColor? : string;

  enableCancelTextColor? : string;
  enableCancelBgColor? : string;
  disableCancelTextColor? : string;
  disableCancelBgColor? : string;

  showLoading?: boolean | false;
  showToast?: ShowToastType;
  showToastMessage?: string;
  showToastType?: 'Success' | 'Warning' | 'Failed';
}

const iconMap = {
  warning: (
    <AnimatedIcon type="warning" width="4em" height="4em" color={MaterialYellow1} infinite duration={1.5} loopDelay={2} />
  ),
  success: (
    <AnimatedIcon type="check" width="3em" height="3em" infinite duration={1.5} loopDelay={2} />
  ),
  failed: (
    <AnimatedIcon type="close" width="3em" height="3em" color={MaterialRed1} infinite duration={1.5} loopDelay={2} />
  ),
};

const ModalAlert: React.FC<ModalAlertProps> = ({
  onClose,
  type,
  icon,
  title,
  subtitle,
  inputs,
  onConfirm,
  onCancel,
  onConfirmEnabled,
  onCancelEnabled,
  enableConfirmTextColor,
  enableConfirmBgColor,
  disableConfirmTextColor,
  disableConfirmBgColor,

  enableCancelBgColor,
  enableCancelTextColor,
  disableCancelBgColor,
  disableCancelTextColor,
  showLoading,
  showToast,
  showToastMessage,
  showToastType
}) => {

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // Close modal only if the click was on the overlay, not the modal content
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div
        className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
        onClick={handleOverlayClick}
      >
        <div className="bg-white border rounded-lg shadow-lg max-w-sm relative p-6 z-60">
          {/* Close Button */}
          <div className="flex justify-end">
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={onClose}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>

          {/* Modal Content */}
          <div className="pt-0 text-center">
            {type === 'custom' ? icon : iconMap[type]}
            <h3 className="text-xl font-semibold text-gray-700 mt-5 mb-2">
              {title}
            </h3>
            {subtitle && subtitle}
            {inputs && inputs.map((input, index) => (
              <div key={index}>
                <input
                  className={`w-full px-4 py-4 rounded-lg font-medium bg-gray-100 border-[1px] border-[${input.errorInputState ? input.errorBorderColor : 'white'}] placeholder-gray-500 text-sm focus:outline-none ${input.errorInputState ? '' : 'focus:border-gray-400'} focus:bg-white`}
                  placeholder={input.placeholder}
                  type={input.type}
                  name={input.name}
                  onChange={(e) => input.onChange(e.target.value)}
                />
                {input.errorInputState ?
                  <p style={{ fontSize: '9.5px', marginLeft: '2px', color: input.errorTextColor }} className="text-left text-xs mt-1 mb-2">
                    {input.errorText}
                  </p> :
                  <div className="mt-2 mb-2"></div>
                }
              </div>
            ))}

            <div className="flex justify-center space-x-4 mt-4">
              {type === 'warning' && (
                <>
                  {showLoading ? (
                    <div className="mt-2.5 mb-2"><Spinner size="sm" /></div>
                  ) : (
                    <>
                      <button
                        onClick={onConfirm}
                        style={{
                          backgroundColor: onConfirmEnabled
                            ? enableConfirmBgColor != null
                              ? enableConfirmBgColor
                              : '#64b5f6'
                            : disableConfirmBgColor != null
                            ? disableConfirmBgColor
                            : '#fafafa',
                          color: onConfirmEnabled
                            ? enableConfirmTextColor != null
                              ? enableConfirmTextColor
                              : 'white'
                            : disableConfirmTextColor != null
                            ? disableConfirmTextColor
                            : '#9E9E9E'
                        }}
                        className={`focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-base inline-flex items-center px-3 py-2.5`}
                        disabled={!onConfirmEnabled}
                      >
                        Ya
                      </button>
                      <button
                        onClick={onCancel}
                        style={{
                          backgroundColor: onCancelEnabled
                            ? enableCancelBgColor != null
                              ? enableCancelBgColor
                              : 'white'
                            : disableCancelBgColor != null
                            ? disableCancelBgColor
                            : '#fafafa',
                          color: onCancelEnabled
                            ? enableCancelTextColor != null
                              ? enableCancelTextColor
                              : 'black'
                            : disableCancelTextColor != null
                            ? disableCancelTextColor
                            : '#9E9E9E'
                        }}
                        className="hover:bg-gray-100 focus:ring-4 focus:ring-cyan-200 border border-gray-200 font-medium rounded-lg text-base inline-flex items-center px-3 py-2.5"
                        disabled={!onCancelEnabled}
                      >
                        Tidak
                      </button>
                    </>
                  )}
                </>
              )}
              {type !== 'warning' && (
                <button
                  onClick={onClose}
                  className={`text-white ${type === 'success'
                    ? 'bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300'
                    : 'bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300'
                    } font-medium rounded-lg text-base inline-flex items-center px-3 py-2.5`}
                >
                  OK
                </button>
              )}
            </div>
            {/* Toast Component */}
            <div className={`${(showToast == 'SlideUp' || showToast == 'SlideDown') ? '' : 'hidden'} absolute top-0 left-0 right-0 flex justify-center mx-auto`}>
              {Toast({ showToast, showToastMessage, showToastType })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default ModalAlert;




// // Warning Type
// <ModalAlert
//   type="warning"
//   title="Delete User"
//   subtitle="Are you sure you want to delete this user?"
//   onConfirm={() => console.log('User deleted')}
//   onCancel={() => console.log('User deletion cancelled')}
//   onClose={() => setIsOpen(false)}
// />

// // Success Type
// <ModalAlert
//   type="success"
//   title="Success"
//   subtitle="The operation was successful."
//   onClose={() => setIsOpen(false)}
// />

// // Failed Type
// <ModalAlert
//   type="failed"
//   title="Failed"
//   subtitle="The operation failed. Please try again."
//   onClose={() => setIsOpen(false)}
// />

// // Custom type (with custom icon)
// <ModalAlert
//   type="custom"
//   icon={<CustomIcon />}
//   title="Custom Alert"
//   subtitle="This is a custom alert with a custom icon."
//   onClose={() => setIsOpen(false)}
// />

// // With Input Fields
// <ModalAlert
//   type="custom"
//   icon={<CustomIcon />}
//   title="Custom Input"
//   subtitle="Please enter your details."
//   inputs={[
//     { placeholder: 'Name', onChange: (value) => console.log('Name:', value) },
//     { placeholder: 'Email', onChange: (value) => console.log('Email:', value) },
//   ]}
//   onClose={() => setIsOpen(false)}
//   onConfirm={() => console.log('Confirmed')}
// />
