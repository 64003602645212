import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import { AdminLayout } from './components/layouts/AdminLayout';
import menus from './routes/routes';
import { AuthLayout } from './components/layouts/AuthLayout';
import { useAuthStore } from './stores/auth/auth.store';
import { LoginRequest } from './interfaces/auth/auth-interface';

const App: React.FC = () => {

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/auth"/>}/>
      <Route path="/admin" element={<AdminLayout/>}>
        {
          menus.map((menu , i) => (
            <Route path={menu.link} element={React.createElement(menu.element)}/>
          ))
        }
      </Route>
      <Route path="/auth" element={<AuthLayout/>}/>
    </Routes>
  );
}

export default App;
