import Skeleton from "react-loading-skeleton"
import { useLayananStore } from "../../../stores/layanan/layanan.store"
import { MaterialBlue3, MaterialBlue4, MaterialGray1, MaterialRed1, validateEmpty, validateErrorBorder } from "../../utilities/Util"
import { useLocation, useNavigate } from "react-router-dom"
import GenericTable, { HeaderConfig } from "../../views/GenericTable"
import { CategoryService, CategoryServiceCreate, CategoryServiceUpdate } from "../../../interfaces/category-service/category-service-interface"
import { IoClose, IoEye } from "react-icons/io5"
import { FaEdit } from "react-icons/fa"
import { FaTrash } from "react-icons/fa6"
import { useEffect, useState } from "react"
import { buildErrorView, buildRetryErrorButton } from "../../views/CustomView"
import IcBroom from '../../../assets/images/ic_broom.svg';
import { StatusState } from "../../utilities/ViewState"
import Spinner from "../../utilities/Loading"
import { useCategoryStore } from "../../../stores/category/category.store"
import ModalAlert, { ShowToastType } from "../../utilities/ModalAlert"

export const LayananDetailPage = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const [showModalCreateCategory , setShowModalCreateCategory] = useState<boolean>(false)
    const [showModalRemove , setShowModalRemove] = useState<boolean>(false)

    const [status, setStatus] = useState<StatusState>({ type: 'Idle' })
    const [isUpdate , setUpdate] = useState<boolean>(false)
    const [isSubmit, setSubmit] = useState<boolean>(false)
    const [showAlert , setShowAlert] = useState<boolean>(false)
    const [toast , setToast] = useState<ShowToastType>('Hide')

    const [inputValues , setInputValues] = useState({
        id : -1,
        layanan_id : -1,
        category_service : ''
    })

    const [removeValues , setRemoveValues] = useState({
        category_id : -1,
        category_service : '',
        remove_password : ''
    })

    const onChangeHandler = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValues({ ...inputValues , [e.target.name] : e.target.value})
    }

    const [currentPage, setCurrentPage] = useState<number>(1)
    const limit = 5

    const fetchLayananDetail = useLayananStore((state) => state.fetchLayananDetail)
    const layananDetailState = useLayananStore((state) => state.layananDetailState)

    // Create State
    const createCategoryService = useCategoryStore((state) => state.createCategoryService)
    const categoryCreateState = useCategoryStore((state) => state.categoryCreateState)

    // Update State
    const updateCategoryService = useCategoryStore((state) => state.updateCategoryService)
    const categoryUpdateState = useCategoryStore((state) => state.categoryUpdateState)

    // Delete state
    const removeCategoryService = useCategoryStore((state) => state.removeCategoryService)
    const categoryRemoveState = useCategoryStore((state) => state.categoryRemoveState)

    const resetState = useCategoryStore((state) => state.resetState)

    useEffect(() => {
        fetchLayananDetail(location.state.layanan_id, currentPage, limit)
    }, [])

    useEffect(() => {
        fetchLayananDetail(location.state.layanan_id, currentPage, limit)
    }, [currentPage])

    useEffect(() => {
        switch(categoryCreateState.type){
            case 'Loading':
                setStatus({
                    type : 'Loading'
                })
                break
            case 'Success':
                setStatus({
                    type : 'Success',
                    message : categoryCreateState.data.server_message
                })

                setShowModalCreateCategory(false)
                if(!showAlert)
                    setShowAlert(true)
                
                setSubmit(false)
                setInputValues({
                    id : -1,
                    layanan_id : -1,
                    category_service : ''
                })
                resetState()
                fetchLayananDetail(location.state.layanan_id, currentPage, limit)
                break
            case 'Failed':
                setStatus({
                    type : 'Failed',
                    message : categoryCreateState.message
                })
                setShowAlert(true)
                break
        }
    } , [categoryCreateState])

    useEffect(() => {
        switch(categoryUpdateState.type){
            case 'Loading':
                setStatus({
                    type : 'Loading'
                })
                break
            case 'Success':
                setStatus({
                    type : 'Success',
                    message : categoryUpdateState.data.server_message
                })

                setShowModalCreateCategory(false)
                if(!showAlert)
                    setShowAlert(true)
                
                setSubmit(false)
                setInputValues({
                    id : -1,
                    layanan_id : -1,
                    category_service : ''
                })
                resetState()
                fetchLayananDetail(location.state.layanan_id, currentPage, limit)
                break
            case 'Failed':
                setStatus({
                    type : 'Failed',
                    message : categoryUpdateState.message
                })
                setShowAlert(true)
                break
        }
    } , [categoryUpdateState])

    useEffect(() => {
        switch(categoryRemoveState.type){
            case 'Loading':
                setStatus({
                    type : 'Loading'
                })
                break
            case 'Success':
                setStatus({
                    type : 'Success',
                    message : categoryRemoveState.data.server_message
                })

                setShowModalRemove(false)
                if(!showAlert)
                    setShowAlert(true)
                
                setSubmit(false)
                setInputValues({
                    id : -1,
                    layanan_id : -1,
                    category_service : ''
                })
                setRemoveValues({
                    category_id : -1,
                    category_service : '',
                    remove_password : ''
                })
                if(layananDetailState.type == 'Success'){
                    if(layananDetailState.data.rows_data.docs.length == 1){
                        if(currentPage > 1){
                            setCurrentPage(currentPage - 1)
                        }
                    }
                }
                resetState()
                fetchLayananDetail(location.state.layanan_id, currentPage, limit)
                break
            case 'Failed':
                setStatus({
                    type : 'Failed',
                    message : categoryRemoveState.message
                })
                setShowAlert(true)
                break
        }
    } , [categoryRemoveState])

    const tableHeaders: HeaderConfig[] = [
        {
            label: 'Judul Kelompok Layanan',
            label_data: 'category_service',
            label_data_type: 'text'
        }
    ]

    const tableActions = [
        {
            label: 'Lihat',
            onClick: (data: CategoryService, index: number) => {
                navigate('/admin/layanan_list' , {
                    state : {
                        layanan_id : data.layanan_id,
                        parent_id : data.id
                    }
                })
            },
            bgColor: MaterialBlue3,
            hoverBgColor: MaterialBlue4,
            textColor: 'white',
            icon: <IoEye />
        },
        {
            label: 'Ubah',
            onClick: (data: CategoryService, index: number) => {
                setUpdate(true)
                setInputValues({
                    id : data.id,
                    layanan_id : data.layanan_id,
                    category_service : data.category_service
                })
                setShowModalCreateCategory(true)

            },
            bgColor: MaterialBlue3,
            hoverBgColor: MaterialBlue4,
            textColor: 'white',
            icon: <FaEdit />
        },
        {
            label: 'Hapus',
            onClick: (data: CategoryService, index: number) => {
                setInputValues({
                    id : -1,
                    layanan_id : -1,
                    category_service : data.category_service,
                })
                setRemoveValues({
                    category_id : data.id,
                    category_service : '',
                    remove_password : ''
                })
                setShowModalRemove(true)
            },
            borderWidth: '1px',
            borderColor: MaterialRed1,
            bgColor: 'white',
            hoverBgColor: MaterialRed1,
            textColor: MaterialRed1,
            textHoverColor: 'white',
            icon: <FaTrash />
        }
    ]

    const removeCategory = () => {
        setSubmit(true)
        if(removeValues.category_id != null && validateEmpty(removeValues.category_service) &&
            removeValues.category_service == inputValues.category_service && validateEmpty(removeValues.remove_password)){
            removeCategoryService(removeValues.category_id , removeValues.remove_password)
        }
    }

    const updateCategory = () => {
        setSubmit(true)
        if(validateEmpty(inputValues.category_service) && inputValues.id != -1){
            const req : CategoryServiceUpdate = {
                layanan_id : location.state.layanan_id,
                category_service : inputValues.category_service
            }
            updateCategoryService(inputValues.id , req)
        }
    }

    const createCategory = () => {
        setSubmit(true)
        if(validateEmpty(inputValues.category_service)){
            const req : CategoryServiceCreate = {
                layanan_id : location.state.layanan_id,
                category_service : inputValues.category_service
            }
            createCategoryService(req)
        }
    }

    const buildShowToastType = (status : string) => {
        switch(status){
            case 'Idle':
            case 'Loading':
                return 'Failed';
            case 'Success':
                return 'Success';
            case 'Failed':
                return 'Failed';
        }
    }

    const buildLayananDetail = () => {
        switch (layananDetailState.type) {
            case 'Idle':
            case 'Loading':
                return (
                    <>
                        <div className="mt-10 flex flex-col md:flex-row justify-between items-start md:items-center p-4 bg-white shadow rounded-md">
                            <h2 className="text-gray-900 text-sm mb-2 md:mb-0">Daftar Layanan</h2>
                            <button
                                onClick={() => {

                                }}
                                className="bg-blue-500 text-sm text-white px-4 py-1 rounded hover:bg-blue-600 z-50"
                            >
                                + Tambah Paket
                            </button>
                        </div>
                        {/* Table Section */}
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-2">
                            <div className="px-5 py-5 bg-white dark:bg-gray-900">
                                <Skeleton width="24.3%" height={30} highlightColor={MaterialGray1} />
                            </div>
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></th>
                                        <th scope="col" className="px-6 py-3"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></th>
                                        <th scope="col" className="px-6 py-3"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <nav className="px-4 py-4 flex flex-col-reverse md:flex-row items-center justify-end pt-4" aria-label="Table navigation">
                                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                                    <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                </ul>
                            </nav>
                        </div>
                    </>
                )
            case 'Success':
                return (
                    <>
                        {layananDetailState.data.rows_data.docs.length > 0 ?
                            <>
                                {/* Daftar Layanan and Tambah Layanan Section */}
                                <div className="mt-10 flex flex-col md:flex-row justify-between items-start md:items-center p-4 bg-white shadow rounded-md">
                                    <h2 className="text-gray-900 text-sm mb-2 md:mb-0">Daftar Layanan</h2>
                                    <button
                                        onClick={() => {
                                            setShowModalCreateCategory(true)
                                        }}
                                        className="bg-blue-500 text-sm text-white px-4 py-1 rounded hover:bg-blue-600 z-50"
                                    >
                                        + Tambah Kategori
                                    </button>
                                </div>
                                {/* Table Section */}
                                <GenericTable
                                    headers={tableHeaders}
                                    data={layananDetailState.data.rows_data.docs}
                                    actions={tableActions}
                                    usePagination={true}
                                    pagination={{
                                        currentPage: currentPage,
                                        countTotal: layananDetailState.data.count_total,
                                        pageSize: limit,
                                        onPageChange: (page: number) => {
                                            setCurrentPage(page)
                                        }
                                    }} />
                            </> : <>
                                <div className="mt-10 flex flex-col md:flex-row justify-between items-start md:items-center p-4 bg-white shadow rounded-md">
                                    <h2 className="text-gray-900 text-sm mb-2 md:mb-0">Daftar Layanan</h2>
                                    <button
                                        onClick={() => {
                                            setShowModalCreateCategory(true)
                                        }}
                                        className="bg-blue-500 text-sm text-white px-4 py-1 rounded hover:bg-blue-600 z-50"
                                    >
                                        + Tambah Kategori
                                    </button>
                                </div>
                                <div className="w-full mt-2">
                                    <div className="bg-white text-white shadow-lg rounded-lg overflow-hidden">
                                        <div className="p-9">
                                            <div className="relative flex flex-col w-full min-h-[50vh] md:min-h-[52.7vh]">
                                                <div className="absolute inset-0 flex flex-col items-center justify-center">
                                                    {buildErrorView(IcBroom , 'Data layanan tidak ada')}
                                                    {buildRetryErrorButton(() => {
                                                        fetchLayananDetail(location.state.layanan_id, currentPage, limit)
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                    </>
                )
            case 'Failed':
                return (
                    <>
                        {
                            layananDetailState.code == 404 ?
                                <>
                                    <div className="mt-10 flex flex-col md:flex-row justify-between items-start md:items-center px-4 py-5 bg-white shadow rounded-md">
                                        <h2 className="text-gray-900 text-sm mb-2 md:mb-0">Daftar Layanan</h2>
                                    </div>
                                    <div className="w-full mt-2">
                                        <div className="bg-white text-white shadow-lg rounded-lg overflow-hidden">
                                            <div className="p-9">
                                                <div className="relative flex flex-col w-full min-h-[50vh] md:min-h-[52.7vh]">
                                                    <div className="absolute inset-0 flex flex-col items-center justify-center">
                                                        {buildErrorView(IcBroom , 'Data layanan tidak ada')}
                                                        {buildRetryErrorButton(() => {
                                                            fetchLayananDetail(location.state.layanan_id, currentPage, limit)
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className="mt-10 flex flex-col md:flex-row justify-between items-start md:items-center px-4 py-5 bg-white shadow rounded-md">
                                        <h2 className="text-gray-900 text-sm mb-2 md:mb-0">Daftar Layanan</h2>
                                    </div>
                                    <div className="w-full mt-2">
                                        <div className="bg-white text-white shadow-lg rounded-lg overflow-hidden">
                                            <div className="p-9">
                                                <div className="relative flex flex-col w-full min-h-[50vh] md:min-h-[52.7vh]">
                                                    <div className="absolute inset-0 flex flex-col items-center justify-center">
                                                        {buildErrorView()}
                                                        {buildRetryErrorButton(() => {
                                                            fetchLayananDetail(location.state.layanan_id, currentPage, limit)
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </>
                )
        }
    }

    const buildCreateUpdateButton = () => {
        switch (status.type) {
            case 'Loading':
                return (
                    <>
                        <div className="relative mt-3" style={{ height: '2.5rem' }}>
                            <Spinner size="md" color="#000000" cover="parent" />
                        </div>
                    </>
                )
            case 'Success':
                return (
                    <>
                        <button type="submit" className="w-full mt-4 bg-green-500 text-white text-sm font-semibold py-2 rounded hover:bg-green-600">{isUpdate ? 'Perbarui' : 'Simpan'}</button>
                    </>
                )
            case 'Failed':
            case 'Idle':
            default:
                return (
                    <>
                        <button type="submit" className="w-full mt-4 bg-green-500 text-white text-sm font-semibold py-2 rounded hover:bg-green-600">{isUpdate ? 'Perbarui' : 'Simpan'}</button>
                    </>
                )
        }
    }

    const buildAlertDialog = () => {
        switch (status.type) {
            case 'Success':
                return (
                    <>
                        {
                            showAlert ?
                                <ModalAlert
                                    type="success"
                                    title="Status"
                                    subtitle={status.message ? status.message : "Berhasil Membuat Layanan"}
                                    onClose={() => {
                                        resetState()
                                        setShowAlert(false)
                                    }}
                                />
                                : null
                        }
                    </>
                )
            case 'Failed':
                return (
                    <>
                        {
                            showAlert ?
                                <ModalAlert
                                    type="failed"
                                    title="Perhatian"
                                    subtitle={status.message ? status.message : "Unknown Error"}
                                    onClose={() => {
                                        resetState()
                                        setShowAlert(false)
                                    }}
                                />
                                : null
                        }
                    </>
                )
            default:
                return (<></>)
        }
    }

    return (
        <div className="relative flex flex-col">
            {buildLayananDetail()}
            {showModalCreateCategory && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-[25%] max-h-[90vh] overflow-y-auto p-6 relative">
                        <button
                            onClick={() => {
                                if (status.type != 'Loading') {
                                    setShowModalCreateCategory(false)
                                }
                            }}
                            className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                        >
                            <IoClose size={24} />
                        </button>
                        <h4 className="text-lg font-semibold mb-4">{!isUpdate ? 'Buat Kategori Baru' : 'Ubah Kategori'}</h4>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (isUpdate) {
                                    updateCategory()
                                } else {
                                    createCategory();
                                }
                            }}
                        >
                            <div className="mb-4">
                                <label
                                    className="block text-sm font-medium text-gray-700"
                                    htmlFor="input-title"
                                >
                                    Nama Kategori
                                </label>
                                <input
                                    className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                        isSubmit && !inputValues.category_service
                                    )}`}
                                    id="input-title"
                                    type="text"
                                    name="category_service"
                                    onChange={onChangeHandler}
                                    value={inputValues.category_service}
                                />
                                {isSubmit && !inputValues.category_service && (
                                    <p className="text-red-500 text-xs mt-1">Nama Kategori must be filled</p>
                                )}
                            </div>
                            {buildCreateUpdateButton()}
                        </form>
                    </div>
                </div>
            )}
            {showModalRemove && (
                <ModalAlert
                    type="warning"
                    title="Perhatian"
                    subtitle={
                        <p className="text-black text-base font-normal mb-4">
                            Yakin ingin menghapus kategori ini?<br/>Ketik nama kategori <p className="text-black text-base font-bold">{inputValues.category_service}</p> dan kata sandi akun
                        </p>
                    }
                    showToast={toast}
                    showToastMessage={status.type == 'Success' ? status.message ?? '' : status.type == 'Failed' ? status.message ?? '' : ''}
                    showToastType={buildShowToastType(categoryRemoveState.type)}
                    inputs={[
                        { 
                            placeholder: 'Nama kategori', 
                            type: "text", 
                            name : 'category_service', 
                            errorInputState : isSubmit && (!validateEmpty(removeValues.category_service) || removeValues.category_service != inputValues.category_service),
                            errorBorderColor : MaterialRed1,
                            errorText : !validateEmpty(removeValues.category_service) ? 'Harap isi nama kategori' : removeValues.category_service != inputValues.category_service ? 'Nama kategori tidak sesuai' : '' ,
                            errorTextColor : MaterialRed1,
                            value: removeValues.category_service,
                            onChange: (value) => {
                            setRemoveValues((prevVals) => ({
                                ...prevVals,
                                category_service : value
                            }))
                            } 
                        },
                        {   
                            placeholder: 'Kata sandi', 
                            type: "password", 
                            name : 'remove_password', 
                            errorInputState : isSubmit && !validateEmpty(removeValues.remove_password),
                            errorBorderColor : MaterialRed1,
                            errorText : "Harap isi kata sandi",
                            errorTextColor : MaterialRed1,
                            value: removeValues.remove_password,
                            onChange: (value) => {
                            setRemoveValues((prevVals) => ({
                                ...prevVals,
                                remove_password : value
                            }))
                            } 
                        },
                    ]}
                    onClose={() => {
                        if(categoryRemoveState.type != 'Loading'){
                            setSubmit(false)
                            setInputValues((prevVals) => ({
                                ...prevVals,
                                id : -1,
                                layanan_id : -1,
                                category_service : ''
                            }))
                            setRemoveValues((prevVals) => ({
                                ...prevVals,
                                id : -1,
                                category_id : -1,
                                category_service : '',
                                password : ''
                            }))
                            setShowModalRemove(false)
                        }
                    }}
                    onConfirmEnabled={categoryRemoveState.type != 'Loading'}
                    onCancelEnabled={categoryRemoveState.type != 'Loading'}
                    enableConfirmBgColor={MaterialRed1}
                    onConfirm={() => {
                        if(categoryRemoveState.type != 'Loading'){
                            setSubmit(true)
                            if((validateEmpty(removeValues.category_service) && removeValues.category_service == inputValues.category_service) &&
                                validateEmpty(removeValues.remove_password)){
                                removeCategory()
                            }
                        }
                    }}
                    onCancel={() => {
                        if(categoryRemoveState.type != 'Loading'){
                            setSubmit(false)
                            setInputValues((prevVals) => ({
                                ...prevVals,
                                id : -1,
                                layanan_id : -1,
                                category_service : ''
                            }))
                            setRemoveValues((prevVals) => ({
                                ...prevVals,
                                id : -1,
                                category_id : -1,
                                category_service : '',
                                remove_password : ''
                            }))
                            setShowModalRemove(false)
                        }
                    }}
                    showLoading={categoryRemoveState.type == "Loading"}
                />
            )}
            {buildAlertDialog()}
        </div>
    )
}