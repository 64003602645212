import { create, StateCreator } from "zustand";
import { ViewState } from "../../components/utilities/ViewState";
import { BaseResponse } from "../../interfaces/base/base-interface";
import { SubServiceCreateUpdate, SubServiceUpdate } from "../../interfaces/sub_service/sub-service-interface";
import axiosInstance from "../../configApi";
import { AxiosError } from "axios";
import { devtools } from "zustand/middleware";

export interface SubServiceState {
    subServiceCreateUpdateState: ViewState<BaseResponse>,
    subServiceRemoveState : ViewState<BaseResponse>,

    createSubService: (request: SubServiceCreateUpdate) => Promise<void>;
    updateSubService: (id : number , request : SubServiceUpdate) => Promise<void>;
    removeSubService: (id : number , password : string) => Promise<void>;
    resetState: () => void;
}

const storeSubServiceApi: StateCreator<SubServiceState> = (set) => ({
    subServiceCreateUpdateState: { type: 'Idle' },
    subServiceRemoveState : { type : 'Idle' },

    createSubService: async (request: SubServiceCreateUpdate) => {
        try {
            set({
                subServiceCreateUpdateState: {
                    type: 'Loading'
                }
            })
            const { data } = await axiosInstance.post<BaseResponse>('/api/sub_service/create', request)
            set({
                subServiceCreateUpdateState: {
                    type: 'Success',
                    data: data
                }
            })
        } catch (error) {
            console.error(error)
            if (error instanceof AxiosError) {
                set({
                    subServiceCreateUpdateState: {
                        type: 'Failed',
                        message: error.response && error.response.data.server_message
                            ? error.response.data.server_message
                            : 'Gagal membuat sub service',  // Fallback to 'Not Found' if server_message is undefined
                        code: error.response ? error.response.status : 404,
                    },
                });
            } else {
                set({
                    subServiceCreateUpdateState: {
                        type: 'Failed',
                        message: 'Gagal membuat sub service',
                        code: 404
                    }
                })
            }
        }
    },
    updateSubService : async(id : number , request : SubServiceUpdate) => {
        try {
            set({
                subServiceCreateUpdateState: {
                    type: 'Loading'
                }
            })
            const { data } = await axiosInstance.put<BaseResponse>(`/api/sub_service/update/${id}`, request)
            set({
                subServiceCreateUpdateState: {
                    type: 'Success',
                    data: data
                }
            })
        } catch (error) {
            console.error(error)
            if (error instanceof AxiosError) {
                set({
                    subServiceCreateUpdateState: {
                        type: 'Failed',
                        message: error.response && error.response.data.server_message
                            ? error.response.data.server_message
                            : 'Gagal membuat sub service',  // Fallback to 'Not Found' if server_message is undefined
                        code: error.response ? error.response.status : 404,
                    },
                });
            } else {
                set({
                    subServiceCreateUpdateState: {
                        type: 'Failed',
                        message: 'Gagal membuat sub service',
                        code: 404
                    }
                })
            }
        }
    },
    removeSubService : async(id : number , password : string) => {
        try {
            set({
                subServiceRemoveState: {
                    type: 'Loading'
                }
            })
            const { data } = await axiosInstance.delete<BaseResponse>(`/api/sub_service/remove/${id}/${password}`)
            set({
                subServiceRemoveState: {
                    type: 'Success',
                    data: data
                }
            })
        } catch (error) {
            console.error(error)
            if (error instanceof AxiosError) {
                set({
                    subServiceRemoveState: {
                        type: 'Failed',
                        message: error.response && error.response.data.server_message
                            ? error.response.data.server_message
                            : 'Gagal menghapus sub service',  // Fallback to 'Not Found' if server_message is undefined
                        code: error.response ? error.response.status : 404,
                    },
                });
            } else {
                set({
                    subServiceRemoveState: {
                        type: 'Failed',
                        message: 'Gagal menghapus sub service',
                        code: 404
                    }
                })
            }
        }
    },
    resetState: () => {
        set({
            subServiceCreateUpdateState: { type: 'Idle' },
            subServiceRemoveState : { type : 'Idle' },
        })
    }
})

export const useSubServiceStore = create<SubServiceState>()(
    devtools(
        storeSubServiceApi
    )
)