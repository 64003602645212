import { useLocation, useNavigate } from "react-router-dom"
import { useServiceStore } from "../../../stores/services/service.store"
import { Service, ServiceCreate } from "../../../interfaces/service/service-interface"
import { convertBase64ToFile, GRAY_4, MaterialBlue3, MaterialBlue4, MaterialGray1, MaterialRed1, MaterialRed4, validateEmpty, validateErrorBorder } from "../../utilities/Util"
import { IoClose, IoEye } from "react-icons/io5"
import IcImage from '../../../assets/images/ic_image.svg'
import { FaTrash } from "react-icons/fa6"
import Skeleton from "react-loading-skeleton"
import GenericTable, { HeaderConfig } from "../../views/GenericTable"
import React, { useEffect, useRef, useState } from "react"
import { buildErrorView, buildRetryErrorButton } from "../../views/CustomView"
import SelectComponent from "../../views/SelectComponent"
import { IoIosCloseCircle } from "react-icons/io"
import { BASE_URL } from "../../../configApi"
import Spinner from "../../utilities/Loading"
import { StatusState } from "../../utilities/ViewState"
import ModalAlert from "../../utilities/ModalAlert"
import { ShowToastType } from "../../utilities/toast"

export const LayananListServices = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [status, setStatus] = useState<StatusState>({ type: 'Idle' })
    const [isSubmit, setSubmit] = useState<boolean>(false)
    const [isUpdate, setUpdate] = useState<boolean>(false)

    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showModalRemove, setShowModalRemove] = useState<boolean>(false)

    const [toast, setToast] = useState<ShowToastType>('Hide')

    const options_type = [
        {
            value: 'durasi', label: 'Durasi'
        },
        {
            value: 'luas ruangan', label: 'Luas Ruangan'
        },
        {
            value: 'single', label: 'Single'
        }
    ]
    const options_category = [
        {
            value: 'Cleaning', label: 'Cleaning'
        },
        {
            value: 'Disinfectant', label: 'Disinfectant'
        },
        {
            value: 'Fogging', label: 'Fogging'
        },
        {
            value: 'Borongan', label: 'Borongan'
        },
        {
            value: 'Lainnya', label: 'Lainnya'
        }
    ]

    const [inputValues, setInputValues] = useState({
        service_id : 0,
        service_name: '',
        service_type: '',
        service_category: '',
        service_description: '',
        service_image: ''
    })
    const [removeValues, setRemoveValues] = useState({
        remove_service_name: '',
        remove_password: ''
    })

    const [imgProper, setImgProper] = useState(false);
    const fileRef = useRef<HTMLInputElement>(null);

    const [currentPage, setCurrentPage] = useState<number>(1)
    const [limit, setLimit] = useState<number>(5)

    const serviceState = useServiceStore((state) => state.serviceState)
    const serviceRemoveState = useServiceStore((state) => state.serviceRemoveState)
    const fetchServices = useServiceStore((state) => state.fetchServices)
    const removeService = useServiceStore((state) => state.removeService)

    const resetState = useServiceStore((state) => state.resetState)

    const [serviceImage, setServiceImage] = useState<string | null>(null);
    const [serviceFile, setServiceFile] = useState<File | null>(null);

    const serviceCreateState = useServiceStore((state) => state.serviceCreateState)
    const createService = useServiceStore((state) => state.createService)

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValues({
            ...inputValues,
            [e.target.name]: e.target.value
        })
    }

    const onChangeImageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const img = new Image();
            img.onload = () => {
                if (img.width === 450 && img.height === 450) {
                    setImgProper(true);
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setServiceImage(e.target?.result as string);
                        const convertedFile = convertBase64ToFile(e.target?.result as string, file.name);

                        // Store the File object in the state
                        setServiceFile(convertedFile);
                    };
                    reader.readAsDataURL(file);
                } else {
                    setImgProper(false);
                    setServiceImage(null);
                }
            };
            img.src = URL.createObjectURL(file);
            e.target.value = ''
        }
    };

    const buildErrorIconMessage = () => {
        if (isSubmit && serviceImage === null && !isUpdate) {
            return (
                <>
                    <p className="text-red-500 text-xs mt-2">
                        Harap pilh icon service 450px x 450px
                    </p>
                </>
            )
        }
    }

    const buildCreateUpdateButton = () => {
        switch (status.type) {
            case 'Loading':
                return (
                    <>
                        <div className="relative mt-3" style={{ height: '2.5rem' }}>
                            <Spinner size="md" color="#000000" cover="parent" />
                        </div>
                    </>
                )
            case 'Success':
                return (
                    <>
                        <button type="submit" className="w-full mt-4 bg-green-500 text-white text-sm font-semibold py-2 rounded hover:bg-green-600">{isUpdate ? 'Perbarui' : 'Simpan'}</button>
                    </>
                )
            case 'Failed':
            case 'Idle':
            default:
                return (
                    <>
                        <button type="submit" className="w-full mt-4 bg-green-500 text-white text-sm font-semibold py-2 rounded hover:bg-green-600">{isUpdate ? 'Perbarui' : 'Simpan'}</button>
                    </>
                )
        }
    }

    const buildAlertDialog = () => {
        switch (status.type) {
            case 'Success':
                return (
                    <>
                        {
                            showAlert ?
                                <ModalAlert
                                    type="success"
                                    title="Status"
                                    subtitle={status.message ? status.message : "Berhasil Membuat Layanan"}
                                    onClose={() => {
                                        resetState()
                                        setShowAlert(false)
                                    }}
                                />
                                : null
                        }
                    </>
                )
            case 'Failed':
                return (
                    <>
                        {
                            showAlert ?
                                <ModalAlert
                                    type="failed"
                                    title="Perhatian"
                                    subtitle={status.message ? status.message : "Unknown Error"}
                                    onClose={() => {
                                        resetState()
                                        setShowAlert(false)
                                    }}
                                />
                                : null
                        }
                    </>
                )
            default:
                return (<></>)
        }
    }

    const create = () => {
        setSubmit(true)
        if (inputValues.service_name != '' &&
            inputValues.service_type != '' &&
            inputValues.service_category != '' &&
            inputValues.service_description != '' &&
            serviceImage != null &&
            serviceFile != null
        ) {
            const payload: ServiceCreate = {
                parent_id: location.state.parent_id,
                service_name: inputValues.service_name,
                service_type: inputValues.service_type,
                service_category: inputValues.service_category,
                service_description: inputValues.service_description
            }
            createService(payload, serviceFile!)
        }
    }

    const update = () => {
        setSubmit(true)
    }

    useEffect(() => {
        if (location.state != null)
            fetchServices(location.state.parent_id, currentPage, limit)
    }, [])

    useEffect(() => {
        if (location.state != null)
            fetchServices(location.state.parent_id, currentPage, limit)
    }, [currentPage])

    useEffect(() => {
        if (toast == 'SlideDown') {
            setTimeout(() => {
                setToast('SlideUp')
                setTimeout(() => {
                    setToast('Hide')
                }, 200)
            }, 1500)
        }
    }, [toast])

    useEffect(() => {
        switch (serviceCreateState.type) {
            case 'Loading':
                setStatus({
                    type: 'Loading'
                })
                break
            case 'Success':
                setStatus({
                    type: 'Success',
                    message: serviceCreateState.data.server_message
                })
                setShowModal(false)

                if (!showAlert)
                    setShowAlert(true)

                setSubmit(false)
                setInputValues({
                    service_id : 0,
                    service_name: '',
                    service_type: '',
                    service_category: '',
                    service_description: '',
                    service_image: ''
                })
                setServiceImage(null)
                setServiceFile(null)
                //fetchServices(location.state.parent_id, currentPage, limit)
                break
            case 'Failed':
                setStatus({
                    type: 'Failed',
                    message: serviceCreateState.message
                })
                setShowAlert(true)
                break
        }
    }, [serviceCreateState])

    useEffect(() => {
        switch(serviceRemoveState.type){
            case 'Loading':
                setStatus({
                    type : 'Loading'
                })
                break
            case 'Success':
                setStatus({
                    type : 'Success',
                    message : serviceRemoveState.data.server_message
                })
                fetchServices(location.state.parent_id , currentPage , limit)
                setShowModalRemove(false)
                setInputValues((prevVals) => ({
                    ...prevVals,
                    service_id : 0,
                    service_name : '',
                    service_type : '',
                    service_category : '',
                    service_description : '',
                    service_image : ''
                }))
                setRemoveValues((prevVals) => ({
                    ...prevVals,
                    remove_service_name : '',
                    remove_password : ''
                }))
                setSubmit(false)
                if(!showAlert)
                    setShowAlert(false)

                break
            case 'Failed':
                setStatus({
                    type : 'Failed',
                    message : serviceRemoveState.message
                })
                setToast('SlideDown')
                break
        }
    } , [serviceRemoveState])

    const tableHeaders: HeaderConfig[] = [
        { label: 'Nama Service', label_data: 'service_name', label_data_type: 'text' },
        {
            label: 'Icon', label_data: 'service_image_thumbnail', label_data_type: 'image', imgStyle: {
                width: '25px',
                height: '25px',
                objectFit: 'cover'
            }
        }
    ]

    const tableActions = [
        {
            label: 'Lihat',
            onClick: (data: Service, index: number) => {
                navigate('/admin/service_detail' , {
                    state : {
                        service_id : data.id    
                    }
                })
            },
            bgColor: MaterialBlue3,
            hoverBgColor: MaterialBlue4,
            textColor: 'white',
            icon: <IoEye />
        },
        {
            label: 'Hapus',
            onClick: (data: Service, index: number) => {
                setInputValues((prevVals) => ({
                    ...prevVals,
                    service_id : data.id,
                    service_name : data.service_name
                }))
                setShowModalRemove(true)
            },
            borderWidth: '1px',
            borderColor: MaterialRed1,
            bgColor: 'white',
            hoverBgColor: MaterialRed1,
            textColor: MaterialRed1,
            textHoverColor: 'white',
            icon: <FaTrash />
        }
    ]

    const buildServiceList = () => {
        switch (serviceState.type) {
            case 'Idle':
            case 'Loading':
                return (
                    <>
                        {/* Daftar Layanan and Tambah Layanan Section */}
                        <div className="mt-10 flex flex-col md:flex-row justify-between items-start md:items-center p-4 bg-white shadow rounded-md">
                            <h2 className="text-gray-900 text-sm mb-2 md:mb-0">Daftar Service</h2>
                            <button
                                onClick={() => {
                                }}
                                className="bg-blue-500 text-sm text-white px-4 py-1 rounded hover:bg-blue-600 z-50"
                            >
                                + Tambah Service
                            </button>
                        </div>
                        {/* Table Section */}
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-2">
                            <div className="px-5 py-5 bg-white dark:bg-gray-900">
                                <Skeleton width="24.3%" height={30} highlightColor={MaterialGray1} />
                            </div>
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></th>
                                        <th scope="col" className="px-6 py-3"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></th>
                                        <th scope="col" className="px-6 py-3"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <nav className="px-4 py-4 flex flex-col-reverse md:flex-row items-center justify-end pt-4" aria-label="Table navigation">
                                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                                    <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                </ul>
                            </nav>
                        </div>
                    </>
                )
            case 'Success':
                return (
                    <>
                        {/* Daftar Service and Tambah Service Section */}
                        <div className="mt-10 flex flex-col md:flex-row justify-between items-start md:items-center p-4 bg-white shadow rounded-md">
                            <h2 className="text-gray-900 text-sm mb-2 md:mb-0">Daftar Service</h2>
                            <button
                                onClick={() => {
                                    setShowModal(true)
                                }}
                                className="bg-blue-500 text-sm text-white px-4 py-1 rounded hover:bg-blue-600 z-50"
                            >
                                + Tambah Service
                            </button>
                        </div>
                        {/* Table Section */}
                        <GenericTable
                            headers={tableHeaders}
                            data={serviceState.data.rows_data.docs}
                            actions={tableActions}
                            usePagination={true}
                            pagination={{
                                currentPage: currentPage,
                                countTotal: serviceState.data.count_total,
                                pageSize: 5,
                                onPageChange: (page: number) => {
                                    setCurrentPage(page)
                                },
                            }}
                        />
                    </>
                )
            case 'Failed':
                return (
                    <>
                        {/* Daftar Layanan and Tambah Layanan Section */}
                        <div className="mt-10 flex flex-col md:flex-row justify-between items-start md:items-center px-4 py-5 bg-white shadow rounded-md">
                            <h2 className="text-gray-900 text-sm mb-2 md:mb-0">Daftar Service</h2>
                        </div>
                        <div className="w-full mt-2">
                            <div className="bg-white text-white shadow-lg rounded-lg overflow-hidden">
                                <div className="p-9">
                                    <div className="relative flex flex-col w-full min-h-[50vh] md:min-h-[52.7vh]">
                                        <div className="absolute inset-0 flex flex-col items-center justify-center">
                                            {buildErrorView()}
                                            {buildRetryErrorButton(() => {
                                                fetchServices(location.state.parent_id, currentPage, limit)
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                )
        }
    }

    const buildShowToastType = (status: string) => {
        switch (status) {
            case 'Idle':
            case 'Loading':
                return 'Failed';
            case 'Success':
                return 'Success';
            case 'Failed':
                return 'Failed';
        }
    }

    return (
        <div className="relative flex flex-col">
            {buildServiceList()}

            {showModalRemove && (
                <div>
                    <ModalAlert
                        type="warning"
                        title="Perhatian"
                        subtitle={
                            <p className="text-black text-base font-normal mb-4">
                                Yakin ingin menghapus service ini?<br />Ketik nama service <p className="text-black text-base font-bold">{inputValues.service_name}</p> dan kata sandi akun
                            </p>
                        }
                        showToast={toast}
                        showToastMessage={status.type == 'Success' ? status.message ?? '' : status.type == 'Failed' ? status.message ?? '' : ''}
                        showToastType={buildShowToastType(serviceRemoveState.type)}
                        inputs={[
                            {
                                placeholder: 'Nama service',
                                type: 'text',
                                name: 'remove_service_name',
                                errorInputState: isSubmit && (!validateEmpty(removeValues.remove_service_name) || removeValues.remove_service_name != inputValues.service_name),
                                errorBorderColor: MaterialRed1,
                                errorText: !validateEmpty(removeValues.remove_service_name) ? 'Harap isi nama service' : removeValues.remove_service_name != inputValues.service_name ? 'Nama service tidak sesuai' : '',
                                errorTextColor: MaterialRed1,
                                value: removeValues.remove_service_name,
                                onChange: (value) => {
                                    setRemoveValues((prevVals) => ({
                                        ...prevVals,
                                        remove_service_name: value
                                    }))
                                }
                            },
                            {
                                placeholder: 'Kata sandi',
                                type: "password",
                                name: 'remove_password',
                                errorInputState: isSubmit && !validateEmpty(removeValues.remove_password),
                                errorBorderColor: MaterialRed1,
                                errorText: "Harap isi nama layanan",
                                errorTextColor: MaterialRed1,
                                value: removeValues.remove_password,
                                onChange: (value) => {
                                    setRemoveValues((prevVals) => ({
                                        ...prevVals,
                                        remove_password: value
                                    }))
                                }
                            }
                        ]}
                        onClose={() => {
                            if(serviceRemoveState.type != 'Loading'){
                                setSubmit(false)
                                setInputValues((prevVals) => ({
                                    ...prevVals,
                                    service_id : 0,
                                    service_name : ''
                                }))
                                setRemoveValues((prevVals) => ({
                                    ...prevVals,
                                    remove_service_name : '',
                                    remove_password : ''
                                }))
                                setShowModalRemove(false)
                            }
                        }}
                        onConfirmEnabled={serviceRemoveState.type != 'Loading'}
                        onCancelEnabled={serviceRemoveState.type != 'Loading'}
                        enableConfirmBgColor={MaterialRed1}
                        onConfirm={() => {
                            if(serviceRemoveState.type != 'Loading'){
                                setSubmit(true)
                                if((validateEmpty(removeValues.remove_service_name) && removeValues.remove_service_name == inputValues.service_name) &&
                                    validateEmpty(removeValues.remove_password)){
                                    removeService(inputValues.service_id , removeValues.remove_password)
                                }
                            }
                        }}
                        showLoading={serviceRemoveState.type == 'Loading'}/>
                </div>
            )}

            {/* Create Modal Area */}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-[25%] max-h-[90vh] overflow-y-auto p-6 relative">
                        <button
                            onClick={() => {
                                setShowModal(false)
                            }}
                            className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                        >
                            <IoClose size={24} />
                        </button>
                        <h4 className="text-lg font-semibold mb-4">{isUpdate ? 'Perbarui Layanan' : 'Buat Layanan Baru'}</h4>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (!isUpdate) {
                                    create()
                                } else {
                                    update()
                                }
                            }}
                        >
                            <div className="mb-4">
                                <label
                                    className="block text-sm font-medium text-gray-700"
                                    htmlFor="input-title"
                                >
                                    Nama Service
                                </label>
                                <input
                                    className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                        isSubmit && !inputValues.service_name
                                    )}`}
                                    id="input-title"
                                    type="text"
                                    name="service_name"
                                    onChange={onChangeHandler}
                                    value={inputValues.service_name}
                                />
                                {isSubmit && !inputValues.service_name && (
                                    <p className="text-red-500 text-xs mt-1">Service Name must be filled</p>
                                )}
                            </div>
                            <div className="mb-4">
                                <SelectComponent
                                    label="Tipe Service"
                                    options={options_type}
                                    valueKey="value"
                                    labelKey="label"
                                    onChange={(value) => {
                                        setInputValues((prevVals) => ({
                                            ...prevVals,
                                            service_type: value
                                        }))
                                    }}
                                    isError={isSubmit && inputValues.service_type == ''}
                                />
                                {
                                    isSubmit && inputValues.service_type == '' ?
                                        <p className="text-red-500 text-xs mt-2">
                                            Please choose tipe service
                                        </p> : <></>
                                }
                            </div>
                            <div className="mb-4">
                                <SelectComponent
                                    label="Kategori Service"
                                    options={options_category}
                                    valueKey="value"
                                    labelKey="label"
                                    onChange={(value) => {
                                        setInputValues((prevVals) => ({
                                            ...prevVals,
                                            service_category: value
                                        }))
                                    }}
                                    isError={isSubmit && inputValues.service_category == ''}
                                />
                                {
                                    isSubmit && inputValues.service_category == '' ?
                                        <p className="text-red-500 text-xs mt-2">
                                            Please choose service category
                                        </p> : <></>
                                }
                            </div>
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="input-service-description"
                            >
                                Deskripsi Service
                            </label>
                            <textarea
                                className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white rounded-md ${validateErrorBorder(
                                    isSubmit && !inputValues.service_description
                                )}`}
                                id="input-service-description"
                                name="service_description"
                                onChange={onChangeHandler}
                                value={inputValues.service_description}
                            />
                            {isSubmit && !inputValues.service_description && (
                                <p className="text-red-500 text-xs mt-1">Deskripsi Service must be filled</p>
                            )}
                            <div>
                                <label className="block mt-2 text-sm font-medium text-gray-700">Gambar Service</label>
                                <div
                                    style={{
                                        borderWidth: '1px',
                                        borderColor: isSubmit && !serviceImage ? MaterialRed4 : GRAY_4
                                    }}
                                    className={`relative mt-3 flex flex-col justify-center items-center w-full h-64 bg-gray-100 rounded-md`}
                                >
                                    <input
                                        type="file"
                                        name="image_picker"
                                        onChange={onChangeImageHandler}
                                        ref={fileRef}
                                        hidden
                                    />
                                    {(serviceImage && imgProper) || isUpdate ? (
                                        <div className="relative w-full h-full">
                                            <img
                                                src={isUpdate ? serviceImage != null ? serviceImage : `${BASE_URL}/api/images/${inputValues.service_image}` : serviceImage!}
                                                className="w-full h-full object-contain rounded-md"
                                            />
                                            {
                                                serviceImage && imgProper && (
                                                    <div
                                                        className="absolute top-0 right-0 transform translate-x-2 -translate-y-2 flex items-center justify-center cursor-pointer"
                                                        onClick={() => {
                                                            setServiceImage(null);
                                                            setImgProper(false);
                                                        }}
                                                    >
                                                        <IoIosCloseCircle className='w-8 h-8 text-red-600' />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => fileRef.current?.click()}
                                            className="flex flex-col justify-center items-center text-center cursor-pointer"
                                        >
                                            <img
                                                src={IcImage}
                                                style={{ width: '50px', height: '50px', marginBottom: '8px' }}
                                                alt="Choose Image Icon"
                                            />
                                            <div className="text-gray-500">
                                                Choose image<br />450 x 450
                                            </div>
                                        </div>

                                    )}
                                </div>
                                {buildErrorIconMessage()}
                                {buildCreateUpdateButton()}
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {buildAlertDialog()}
        </div>
    )
}