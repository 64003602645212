export const formatRupiah = (rupiah : string, prefix : string) => {
    var number_string = rupiah.replace(/[^,\d]/g, '').toString(),
        split = number_string.split(','),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi)
    let separator = ""
    if (ribuan) {
        separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.')
    }
    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '')
}

interface MonthMapping {
    [key: number]: {
        month: string;
    };
}

// Create the month mapping object with the defined type
const monthMappingLetters: MonthMapping = {
    1: { month: 'Januari' },
    2: { month: 'Februari' },
    3: { month: 'Maret' },
    4: { month: 'April' },
    5: { month: 'Mei' },
    6: { month: 'Juni' },
    7: { month: 'Juli' },
    8: { month: 'Agustus' },
    9: { month: 'September' },
    10: { month: 'Oktober' },
    11: { month: 'November' },
    12: { month: 'Desember' }
};

export const validateErrorBorder = (condition: boolean): string => {
    return condition ? 'border-red-500' : 'border-gray-300';
};

export const validateEmpty = (data : string) => {
    return data !== '' && data != null && data !== undefined
}

export const validateYMDFormat = (dateString : string) => {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    return dateString.match(regEx) != null;
}

export const convertBase64ToFile = (base64String: string, filename: string): File => {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1] || '';
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};

export const timeFormat = /^(0[9]|1[0-6]):[0-5]\d$/

export const MaterialGray1 = '#cfd8dc';
export const MaterialGray2 = '#fafafa';
export const MaterialGray3 = '#37474F';
export const MaterialGray4 = '#9E9E9E';
export const MaterialBlue1 = '#6cc4ec';
export const MaterialBlue2 = '#64b5f6'
export const MaterialBlue3 = '#5E72E4';
export const MaterialBlue4 = '#384489'
export const MaterialRed1 = '#e53935';
export const MaterialRed2 = '#ef5350'
export const MaterialRed3 = '#F5365C'
export const MaterialRed4 = '#F87171'
export const MaterialRed5 = '#F56565'
export const MaterialBlackTransparent1 = '#212121'
export const MaterialGreen1 = '#5EC27E'
export const MaterialGreen2 = '#2DCE89'
export const MaterialYellow1 = '#F0AB35'
export const MaterialYellow2 = '#FFD834'
export const MaterialYellow3 = '#ffca28'
export const RED_ERROR_MESSAGE = '#d32f2f'
export const RED_COLOR_UNTAR = '#970126'
export const GRAY_1 = '#989898'
export const GRAY_2 = '#5A6268'
export const GRAY_3 = '#F0F0F0'
export const GRAY_4 = '#D1D5DB'

export let erorrMsgStyle = {
    fontSize: '11px',
    color: RED_ERROR_MESSAGE
}