import React from 'react';
import { FaSpinner } from 'react-icons/fa';

interface SpinnerProps {
  size?: keyof SizeProps;
  color?: keyof ColorProps | string;  // Allow string for custom colors
  cover?: "fullScreen" | "parent" | "none";
  backgroundColor?: string;  // Allow custom background color
  backgroundOpacity?: number;  // Allow custom background opacity
}

interface SizeProps {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

interface ColorProps {
  slate: string;
  blue: string;
  red: string;
  green: string;
  white: string;
}

const sizesClasses: SizeProps = {
  xs: "w-4 h-4",
  sm: "w-5 h-5",
  md: "w-6 h-6",
  lg: "w-8 h-8",
  xl: "w-10 h-10",
};

const colorClasses: ColorProps = {
  slate: "text-slate-500",
  blue: "text-blue-500",
  red: "text-red-500",
  green: "text-emerald-500",
  white: "text-white",
};

const Spinner: React.FC<SpinnerProps> = ({ 
  size = "md", 
  color = "slate", 
  cover = "none", 
  backgroundColor = "white", 
  backgroundOpacity = 1 
}) => {
  const sizeClass = sizesClasses[size];
  const colorClass = typeof color === 'string' && colorClasses[color as keyof ColorProps] 
    ? colorClasses[color as keyof ColorProps] 
    : '';  // Use template color if available

  // If the color is a custom color (not a predefined one), use inline style
  const customColorStyle = typeof color === 'string' && !colorClass
    ? { color: color }
    : {};

  const coverClass = () => {
    switch (cover) {
      case "fullScreen":
        return "fixed inset-0 flex items-center justify-center z-50 pointer-events-none";
      case "parent":
        return "absolute inset-0 flex items-center justify-center pointer-events-none";
      case "none":
      default:
        return "flex items-center justify-center";
    }
  };

  const coverStyle = {
    backgroundColor: backgroundColor,
    opacity: backgroundOpacity,
  };

  return (
    <div className={coverClass()} style={cover !== "none" ? coverStyle : {}}>
      <div className="flex items-center space-x-2" style={customColorStyle}>
        <FaSpinner className={`${sizeClass} ${colorClass} animate-spin`} />
        <span className={`${colorClass} text-lg`} style={customColorStyle}>Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
