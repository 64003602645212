import { create , StateCreator } from 'zustand';
import { ViewState } from '../../components/utilities/ViewState';
import { BasePaginationResponse, BaseResponse } from '../../interfaces/base/base-interface';
import { Service, ServiceCreate, ServiceDetailResponse, ServiceUpdate } from '../../interfaces/service/service-interface';
import axiosInstance from '../../configApi';
import { AxiosError } from 'axios';
import { devtools } from 'zustand/middleware';

export interface ServiceState {
    serviceState : ViewState<BasePaginationResponse<Service[]>>,
    serviceCreateState : ViewState<BaseResponse>,
    serviceUpdateState : ViewState<BaseResponse>,
    serviceRemoveState : ViewState<BaseResponse>,

    serviceDetailState : ViewState<ServiceDetailResponse>,

    fetchServices : (parent_id : number , page : number , limit : number) => Promise<void>;
    createService : (request : ServiceCreate , file : File) => Promise<void>;
    updateService : (request : ServiceUpdate) => Promise<void>;
    updateServiceImage : (request : FormData) => Promise<void>;
    removeService : (service_id : number , password : string) => Promise<void>;

    getServiceDetail : (service_id : number) => Promise<void>;

    resetState : () => void;
}

const storeServiceApi : StateCreator<ServiceState> = (set) => ({
    serviceState : { type : 'Idle' },
    serviceCreateState : { type : 'Idle' },
    serviceUpdateState : { type : 'Idle' },
    serviceRemoveState : { type : 'Idle' },

    serviceDetailState : { type : 'Idle' },

    resetState : () => {
        set({
            serviceCreateState : { type : 'Idle' },
            serviceUpdateState : { type : 'Idle' }
        })
    },

    fetchServices : async(parent_id : number , page : number , limit : number) => {
        try {
            set({
                serviceState : {
                    type : 'Loading'
                }
            })
            const { data } = await axiosInstance.get<BasePaginationResponse<Service[]>>(`/api/services/index/${parent_id}?page=${page}&limit=${limit}`)
            setTimeout(() => {
                set({
                    serviceState : {
                        type : 'Success',
                        data : data
                    }
                })
            } , 300)
        } catch (error){
            setTimeout(() => {
                if(error instanceof AxiosError){
                    set({
                        serviceState: {
                            type: 'Failed',
                            message: error.response && error.response.data.server_message
                                ? error.response.data.server_message
                                : 'Gagal memuat layanan',  // Fallback to 'Not Found' if server_message is undefined
                            code: error.response ? error.response.status : 404,
                        },
                    });
                } else {
                    set({
                        serviceState : {
                            type : 'Failed',
                            message : 'Unknown Error',
                            code : 404
                        }
                    })
                }
            } , 350)
        }
    },
    createService : async(request : ServiceCreate , file : File) => {
        try {
            set({
                serviceCreateState : {
                    type : 'Loading'
                }
            })
            const formData = new FormData()
            console.log(`REQ DATA : ${JSON.stringify(request)}`)
            formData.append("json_data" , JSON.stringify(request))
            if(file != null){
                console.log('file tidak null')
            } else {
                console.log('file null')
            }
            formData.append("file" , file)
            console.log(`Form Data : ${JSON.stringify(formData , null , 2)}`)
            const { data } = await axiosInstance.post<BaseResponse>(`/api/services/create` , formData)
            setTimeout(() => {
                set({
                    serviceCreateState : {
                        type : 'Success',
                        data : data
                    }
                })
            })
        } catch (error){
            console.error(error)
            if(error instanceof AxiosError){
                set({
                    serviceCreateState: {
                        type: 'Failed',
                        message: error.response && error.response.data.server_message
                            ? error.response.data.server_message
                            : 'Gagal membuat service',  // Fallback to 'Not Found' if server_message is undefined
                        code: error.response ? error.response.status : 404,
                    },
                });
            } else {
                set({
                    serviceCreateState : {
                        type : 'Failed',
                        message : 'Gagal membuat service',
                        code : 404
                    }
                })
            }
        }
    },
    updateService : async(request : ServiceUpdate) => {
        try {
            set({
                serviceUpdateState : {
                    type : 'Loading'
                }
            })
            const { data } = await axiosInstance.put('/api/services/update' , request)
            set({
                serviceUpdateState : {
                    type : 'Success',
                    data : data
                }
            })
        } catch (error){
            console.error(error)
            if(error instanceof AxiosError){
                set({
                    serviceCreateState: {
                        type: 'Failed',
                        message: error.response && error.response.data.server_message
                            ? error.response.data.server_message
                            : 'Gagal memperbarui service',  // Fallback to 'Not Found' if server_message is undefined
                        code: error.response ? error.response.status : 404,
                    },
                });
            } else {
                set({
                    serviceCreateState : {
                        type : 'Failed',
                        message : 'Gagal memperbarui service',
                        code : 404
                    }
                })
            }
        }
    },
    updateServiceImage : async(request : FormData) => {
        try {
            set({
                serviceUpdateState : {
                    type : 'Loading'
                }
            })
            const { data } = await axiosInstance.put('/api/services/update/image' , request)
            set({
                serviceUpdateState : {
                    type : 'Success',
                    data : data
                }
            })
        } catch (error){
            console.error(error)
            if(error instanceof AxiosError){
                set({
                    serviceCreateState: {
                        type: 'Failed',
                        message: error.response && error.response.data.server_message
                            ? error.response.data.server_message
                            : 'Gagal memperbarui service',  // Fallback to 'Not Found' if server_message is undefined
                        code: error.response ? error.response.status : 404,
                    },
                });
            } else {
                set({
                    serviceCreateState : {
                        type : 'Failed',
                        message : 'Gagal memperbarui service',
                        code : 404
                    }
                })
            }
        }
    },
    removeService : async(service_id : number , password : string) => {
        try {
            set({
                serviceRemoveState : {
                    type : 'Loading'
                }
            })
            const { data } = await axiosInstance.delete(`/api/services/remove/${service_id}/${password}`)
            setTimeout(() => {
                set({
                    serviceRemoveState : {
                        type : 'Success',
                        data : data
                    }
                })
            } , 350)
        } catch (error) {
            setTimeout(() => {
                if(error instanceof AxiosError){
                    set({
                        serviceRemoveState: {
                            type: 'Failed',
                            message: error.response && error.response.data.server_message
                                ? error.response.data.server_message
                                : 'Gagal menghapus service',  // Fallback to 'Not Found' if server_message is undefined
                            code: error.response ? error.response.status : 404,
                        },
                    });
                } else {
                    set({
                        serviceRemoveState : {
                            type : 'Failed',
                            message : 'Unknown Error',
                            code : 404
                        }
                    })
                }
            } , 350)
        }
    },

    getServiceDetail : async(service_id : number) => {
        try {
            set({
                serviceDetailState : {
                    type : 'Loading'
                }
            })
            const { data } = await axiosInstance.get<ServiceDetailResponse>(`/api/services/detail/${service_id}`)
            setTimeout(() => {
                set({
                    serviceDetailState : {
                        type : 'Success',
                        data : data
                    }
                })
            } , 300)
        } catch (error){
            setTimeout(() => {
                if(error instanceof AxiosError){
                    set({
                        serviceDetailState: {
                            type: 'Failed',
                            message: error.response && error.response.data.server_message
                                ? error.response.data.server_message
                                : 'Gagal memuat detail service',  // Fallback to 'Not Found' if server_message is undefined
                            code: error.response ? error.response.status : 404,
                        },
                    });
                } else {
                    set({
                        serviceDetailState : {
                            type : 'Failed',
                            message : 'Unknown Error',
                            code : 404
                        }
                    })
                }
            } , 350)
        }
    }
})

export const useServiceStore = create<ServiceState>()(
    devtools(
        storeServiceApi
    )
)