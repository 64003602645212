import ErrorIcon from '../../assets/images/error_icon.svg';

export const buildErrorView = (errorIcon? : string , errorText? : string | 'Gagal memuat data') => {
    return (
        <>
            <img
                style={{ width: '50px', height: '50px' }}
                src={errorIcon || ErrorIcon}
                alt="Error Icon"
            />
            <span className="text-sm text-center text-black mt-3">{errorText}</span>
        </>
    )
}

export const buildRetryErrorButton = (onRetry : () => void) => {
    return (
        <button
            type="button"
            onClick={onRetry}
            className={`mt-4 py-2.5 px-5 mb-2 text-sm font-medium text-[#e53935] focus:outline-none bg-white rounded-lg border border-[#e53935] hover:bg-[#e53935] hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700`}
        >
            Muat Ulang
        </button>
    )
}