import { create, StateCreator } from "zustand";
import { ViewState } from "../../components/utilities/ViewState";
import { BasePaginationResponse, BaseResponse } from "../../interfaces/base/base-interface";
import { Layanan, LayananCreate, LayananUpdate, LayananUpdateResponse } from "../../interfaces/layanan/layanan-interface";
import axiosInstance from "../../configApi";
import { AxiosError } from "axios";
import { devtools } from "zustand/middleware";
import { CategoryService } from "../../interfaces/category-service/category-service-interface";

export interface LayananState {
    layananState : ViewState<BasePaginationResponse<Layanan[]>>;
    layananCreateState : ViewState<BaseResponse>;
    layananUpdateState : ViewState<LayananUpdateResponse>;
    layananRemoveState : ViewState<BaseResponse>;
    
    layananDetailState : ViewState<BasePaginationResponse<CategoryService[]>>;

    fetchLayanan : (page : number , limit : number) => Promise<void>;
    fetchLayananDetail : (layanan_id : number , page : number , limit : number) => Promise<void>;

    createLayanan : (request : LayananCreate , file : File) => Promise<void>;

    updateLayanan : (layanan_id : number , request : LayananUpdate , file? : File | null) => Promise<void>;
    updateLayananLocal : (index : number , layanan : Layanan) => void;

    removeLayanan : (layanan_id : number , password : string) => Promise<void>;

    resetState : () => void;
}

const storeLayananApi : StateCreator<LayananState> = (set) => ({
    layananState : { type : 'Idle' },
    layananDetailState : { type : 'Idle' },
    layananCreateState : { type : 'Idle' },
    layananUpdateState : { type : 'Idle' },
    layananRemoveState : { type : 'Idle' },

    resetState : () => {
        set({
            layananCreateState : { type : 'Idle' }
        })
        set({
            layananUpdateState : { type : 'Idle' }
        })
        set({
            layananRemoveState : { type : 'Idle' }
        })
    },

    fetchLayanan : async(page : number , limit : number) => {
        try {
            set({
                layananState : {
                    type : 'Loading'
                }
            })
            const { data } = await axiosInstance.get<BasePaginationResponse<Layanan[]>>(`/api/layanan_service/index?page=${page}&limit=${limit}`)
            setTimeout(() => {
                set({
                    layananState : {
                        type : 'Success',
                        data : data
                    }
                })
            }, 350);
        } catch (error){
            setTimeout(() => {
                if(error instanceof AxiosError){
                    set({
                        layananRemoveState: {
                            type: 'Failed',
                            message: error.response && error.response.data.server_message
                                ? error.response.data.server_message
                                : 'Gagal memuat layanan',  // Fallback to 'Not Found' if server_message is undefined
                            code: error.response ? error.response.status : 404,
                        },
                    });
                } else {
                    set({
                        layananState : {
                            type : 'Failed',
                            message : 'Unknown Error',
                            code : 404
                        }
                    })
                }
            } , 350)
        }
    },

    fetchLayananDetail : async(layanan_id : number , page : number , limit : number) => {
        try {
            set({
                layananDetailState : {
                    type : 'Loading'
                }
            })
            console.log(`layanan id : ${layanan_id}`)
            const { data } = await axiosInstance.get<BasePaginationResponse<CategoryService[]>>(`/api/layanan_service/detail/${layanan_id}?page=${page}&limit=${limit}`)
            setTimeout(() => {
                set({
                    layananDetailState : {
                        type : 'Success',
                        data : data
                    }
                })
            } , 350)
        } catch(error){
            setTimeout(() => {
                if(error instanceof AxiosError){
                    set({
                        layananDetailState : {
                            type : 'Failed',
                            message: error.response && error.response.data.server_message
                                ? error.response.data.server_message
                                : 'Gagal memuat detail layanan',  // Fallback to 'Not Found' if server_message is undefined
                            code: error.response ? error.response.status : 404,
                        }
                    })
                } else {
                    set({
                        layananDetailState : {
                            type : 'Failed',
                            message : 'Unknown Error',
                            code : 404
                        }
                    })
                }
            } , 350)
        }
    },


    createLayanan : async(request : LayananCreate , file : File) => {
        try {
            set({
                layananCreateState : {
                    type : 'Loading'
                }
            })
            const formData = new FormData()
            formData.append("json_data" , JSON.stringify(request))
            formData.append("file" , file)
            formData.forEach((value, key) => {
                console.log(`${key}: ${value}`);
            });
            const { data } = await axiosInstance.post<BaseResponse>('/api/layanan_service/create' , formData)
            setTimeout(() => {
                set({
                    layananCreateState : {
                        type : 'Success',
                        data : data
                    }
                })
            } , 350)
        } catch (error){
            if(error instanceof AxiosError){
                set({
                    layananCreateState: {
                        type: 'Failed',
                        message: error.response && error.response.data.server_message
                            ? error.response.data.server_message
                            : 'Gagal membuat layanan',  // Fallback to 'Not Found' if server_message is undefined
                        code: error.response ? error.response.status : 404,
                    },
                });
            } else {
                set({
                    layananCreateState : {
                        type : 'Failed',
                        message : 'Gagal membuat layanan',
                        code : 404
                    }
                })
            }
        }
    },
    updateLayanan : async(layanan_id : number , request : LayananUpdate , file? : File | null) => {
        try {
            set({
                layananUpdateState : {
                    type : 'Loading'
                }
            })
            const payload = JSON.stringify(request)
            if(file != null){
                const formData = new FormData()
                formData.append("json_data" , payload)
                formData.append("file" , file)
                const { data } = await axiosInstance.put<LayananUpdateResponse>(`/api/layanan_service/update/image/${layanan_id}` , formData)
                set({
                    layananUpdateState : {
                        type : 'Success',
                        data : data
                    }
                })
            } else {
                const { data } = await axiosInstance.put<LayananUpdateResponse>(`/api/layanan_service/update/${layanan_id}` , payload)
                set({
                    layananUpdateState : {
                        type : 'Success',
                        data : data
                    }
                })
            }
        } catch (error){
            if(error instanceof AxiosError){
                set({
                    layananRemoveState: {
                        type: 'Failed',
                        message: error.response && error.response.data.server_message
                            ? error.response.data.server_message
                            : 'Gagal memperbaharui layanan',  // Fallback to 'Not Found' if server_message is undefined
                        code: error.response ? error.response.status : 404,
                    },
                });
            } else {
                set({
                    layananUpdateState : {
                        type : 'Failed',
                        message : 'Unknown Error',
                        code : 404
                    }
                })
            }
        }
    },

    updateLayananLocal : (index : number , layanan : Layanan) => set((state) => {
        if (state.layananState.type == 'Success') {
            const updatedItems = [...(state.layananState.data.rows_data.docs || [])];
            if (index >= 0 && index < updatedItems.length) {
                updatedItems[index] = layanan;
            }
            return {
                layananState: {
                    ...state.layananState,
                    type : 'Success',
                    data: {
                        ...state.layananState.data,
                        rows_data : {
                            ...state.layananState.data.rows_data,
                            docs : updatedItems
                        }
                    },
                },
            };
        }
        return state;
    }),

    removeLayanan : async(layanan_id : number , password : string) => {
        try {
            set({
                layananRemoveState : {
                    type : 'Loading'
                }
            })
            const { data } = await axiosInstance.delete<BaseResponse>(`/api/layanan_service/remove/${layanan_id}/${password}`)
            setTimeout(() => {
                set({
                    layananRemoveState : {
                        type : 'Success',
                        data : data
                    }
                })
            } , 350)
        } catch (error) {
            setTimeout(() => {
                if (error instanceof AxiosError) {
                    set({
                        layananRemoveState: {
                            type: 'Failed',
                            message: error.response && error.response.data.server_message
                                ? error.response.data.server_message
                                : 'Gagal menghapus layanan',  // Fallback to 'Not Found' if server_message is undefined
                            code: error.response ? error.response.status : 404,
                        },
                    });
                } else {
                    set({
                        layananRemoveState: {
                            type: 'Failed',
                            message: 'Unknown Error',
                            code: 404,
                        },
                    });
                }
                
            } , 350)
        }
    }
})

export const useLayananStore = create<LayananState>()(
    devtools(
        storeLayananApi
    )
)