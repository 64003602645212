import React, { useEffect, useRef, useState } from 'react';
import { IoClose, IoEye } from 'react-icons/io5';
import { IoIosCloseCircle } from "react-icons/io";
import { FaEdit, FaTrash } from "react-icons/fa";
import IcImage from '../../../assets/images/ic_image.svg'
import { useLayananStore } from '../../../stores/layanan/layanan.store';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { convertBase64ToFile, MaterialBlue3, MaterialBlue4, MaterialGray1, MaterialGray2, MaterialRed1, validateEmpty, validateErrorBorder } from '../../utilities/Util';
import { buildErrorView, buildRetryErrorButton } from '../../views/CustomView';
import { BASE_URL } from '../../../configApi';
import Spinner from '../../utilities/Loading';
import { Layanan, LayananCreate, LayananUpdate } from '../../../interfaces/layanan/layanan-interface';
import ModalAlert, { ShowToastType } from '../../utilities/ModalAlert';
import { StatusState } from '../../utilities/ViewState';
import GenericTable, { HeaderConfig } from '../../views/GenericTable';
import '../../../assets/css/slideUpDown.css';
import { useNavigate } from 'react-router-dom';

export const LayananPage = () => {
    const navigate = useNavigate()

    const fetchLayanan = useLayananStore((state) => state.fetchLayanan)
    const layananState = useLayananStore((state) => state.layananState)

    const createLayanan = useLayananStore((state) => state.createLayanan)
    const resetState = useLayananStore((state) => state.resetState)
    const layananCreateState = useLayananStore((state) => state.layananCreateState)

    const updateLayanan = useLayananStore((state) => state.updateLayanan)
    const updateLayananLocal = useLayananStore((state) => state.updateLayananLocal)

    const layananUpdateState = useLayananStore((state) => state.layananUpdateState)

    const removeLayanan = useLayananStore((state) => state.removeLayanan)
    const layananRemoveState = useLayananStore((state) => state.layananRemoveState)

    const [isUpdate, setIsUpdate] = useState<boolean>(false)
    const [status, setStatus] = useState<StatusState>({ type: 'Idle' })

    const [currentPage, setCurrentPage] = useState<number>(1)
    const limit = 5

    const [showModalLayanan, setShowModalLayanan] = useState<boolean>(false);
    const [showModalRemove, setShowModalRemove] = useState<boolean>(false)

    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [toast , setToast] = useState<ShowToastType>('Hide')

    const [isSubmit, setSubmit] = useState<boolean>(false);
    const [inputValues, setInputValues] = useState({
        layanan_index: -1,
        layanan_id: 0,
        layanan_title: '',
        is_active: '',
        layanan_description: '',
        layanan_image: ''
    });

    const [removeValues , setRemoveValues] = useState({
        remove_layanan_title : '',
        remove_password : ''
    })

    const [layananImage, setLayananImage] = useState<string | null>(null);
    const [layananFile, setLayananFile] = useState<File | null>(null);

    const [imgProper, setImgProper] = useState(false);
    const fileRef = useRef<HTMLInputElement>(null);

    //Layanan List On Change Handler Below Here

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });
    };

    const onChangeImageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const img = new Image();
            img.onload = () => {
                if (img.width === 71 && img.height === 71) {
                    setImgProper(true);
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setLayananImage(e.target?.result as string);
                        const convertedFile = convertBase64ToFile(e.target?.result as string, file.name);

                        // Store the File object in the state
                        setLayananFile(convertedFile);
                    };
                    reader.readAsDataURL(file);
                } else {
                    setImgProper(false);
                    setLayananImage(null);
                }
            };
            img.src = URL.createObjectURL(file);
            e.target.value = ''
        }
    };

    //Layanan List CRUD Below Here

    const createLayananService = () => {
        setSubmit(true)
        if (validateEmpty(inputValues.layanan_title) &&
            validateEmpty(inputValues.layanan_description) &&
            validateEmpty(inputValues.is_active) && layananImage != null) {
            const request: LayananCreate = {
                layanan_title: inputValues.layanan_title,
                layanan_description: inputValues.layanan_description,
                is_active: inputValues.is_active
            }
            createLayanan(request, layananFile!)
        }
    };

    const updateLayananService = () => {
        setSubmit(true)
        if (validateEmpty(inputValues.layanan_title) &&
            validateEmpty(inputValues.layanan_description) &&
            validateEmpty(inputValues.is_active)) {
            const request: LayananUpdate = {
                layanan_title: inputValues.layanan_title,
                layanan_description: inputValues.layanan_description,
                is_active: inputValues.is_active
            }
            updateLayanan(inputValues.layanan_id, request, layananFile)
        }
    }

    useEffect(() => {
        if (!showModalLayanan) {
            setSubmit(false)
        }
    }, [showModalLayanan])

    useEffect(() => {
        switch (layananCreateState.type) {
            case 'Loading':
                setStatus({
                    type: 'Loading'
                })

                break
            case 'Success':
                setStatus({
                    type: 'Success',
                    message: layananCreateState.data.server_message
                })

                setShowModalLayanan(false)

                if (!showAlert)
                    setShowAlert(true)

                setSubmit(false)
                setInputValues({
                    layanan_index: -1,
                    layanan_id: 0,
                    layanan_title: '',
                    layanan_description: '',
                    is_active: '',
                    layanan_image: ''
                })
                setLayananImage(null)
                setLayananFile(null)
                break
            case 'Failed':
                setStatus({
                    type: 'Failed',
                    message: layananCreateState.message
                })

                setShowAlert(true)
                break
        }
    }, [layananCreateState])

    useEffect(() => {
        switch (layananUpdateState.type) {
            case 'Loading':
                setStatus({
                    type: 'Loading'
                })

                break
            case 'Success':
                setStatus({
                    type: 'Success',
                    message: layananUpdateState.data.server_message
                })

                setShowModalLayanan(false)

                if (!showAlert)
                    setShowAlert(true)


                setSubmit(false)
                updateLayananLocal(inputValues.layanan_index, layananUpdateState.data.updated_data)
                setInputValues({
                    layanan_index: -1,
                    layanan_id: 0,
                    layanan_title: '',
                    layanan_description: '',
                    is_active: '',
                    layanan_image: ''
                })
                setLayananImage(null)
                setLayananFile(null)
                break
            case 'Failed':
                setStatus({
                    type: 'Failed',
                    message: layananUpdateState.message
                })

                setShowAlert(true)
                break
        }
    }, [layananUpdateState])

    useEffect(() => {
        switch (layananRemoveState.type) {
            case 'Loading':
                setStatus({
                    type: 'Loading'
                })
                break
            case 'Success':
                setStatus({
                    type: 'Success',
                    message: layananRemoveState.data.server_message
                })
                fetchLayanan(currentPage , limit)
                setShowModalRemove(false)
                setInputValues((prevVals) => ({
                    ...prevVals,
                    layanan_id : 0,
                    layanan_title : ''
                }))
                setRemoveValues((prevVals) => ({
                    ...prevVals,
                    remove_layanan_title : '',
                    remove_password : ''
                }))
                setSubmit(false)

                if (!showAlert)
                    setShowAlert(true)

                break
            case 'Failed':
                setStatus({
                    type : 'Failed',
                    message : layananRemoveState.message
                })
                console.log(`Msg : ${layananRemoveState.message}`)
                setToast('SlideDown')
                break
        }
    }, [layananRemoveState])

    useEffect(() => {
        if(toast == 'SlideDown'){
            setTimeout(() => {
                setToast('SlideUp')
                    setTimeout(() => {
                        setToast('Hide')
                    } , 200)
            } , 1500)
        }
    } , [toast])

    const tableHeaders: HeaderConfig[] = [
        { label: 'Nama Layanan', label_data: 'layanan_title', label_data_type: 'text' },
        {
            label: 'Icon', label_data: 'layanan_image', label_data_type: 'image', imgStyle: {
                width: '25px',
                height: '25px',
                objectFit: 'cover'
            }
        }
    ]

    const tableActions = [
        {
            label: 'Lihat',
            onClick: (data : Layanan , index : number) => {
                navigate('/admin/layanan_detail' , {
                    state : {
                        layanan_id : data.id
                    }
                })
            },
            bgColor: MaterialBlue3,
            hoverBgColor: MaterialBlue4,
            textColor: 'white',
            icon: <IoEye />
        },
        {
            label: 'Ubah',
            onClick: (data : Layanan , index : number) => {
                console.log(`passed data : ${JSON.stringify(data , null , 2)}`)
                setIsUpdate(true)
                console.log(`data : ${JSON.stringify({
                    layanan_index: index,
                    layanan_id: data.id,
                    layanan_title: data.layanan_title,
                    layanan_description: data.layanan_description,
                    is_active: data.is_active,
                    layanan_image: data.layanan_image
                }, null, 2)}`)
                setInputValues({
                    layanan_index: index,
                    layanan_id: data.id,
                    layanan_title: data.layanan_title,
                    layanan_description: data.layanan_description,
                    is_active: data.is_active,
                    layanan_image: data.layanan_image
                })
                setShowModalLayanan(true)
            },
            bgColor: MaterialBlue3,
            hoverBgColor: MaterialBlue4,
            textColor: 'white',
            icon: <FaEdit />
        },
        {
            label: 'Hapus',
            onClick: (data : Layanan , index : number) => {
                setInputValues((prevVals) => ({
                    ...prevVals,
                    layanan_id : data.id,
                    layanan_title : data.layanan_title
                }))
                setShowModalRemove(true)
            },
            borderWidth: '1px',
            borderColor: MaterialRed1,
            bgColor: 'white',
            hoverBgColor: MaterialRed1,
            textColor: MaterialRed1,
            textHoverColor: 'white',
            icon: <FaTrash />
        }
    ]

    const buildShowToastType = (status : string) => {
        switch(status){
            case 'Idle':
            case 'Loading':
                return 'Failed';
            case 'Success':
                return 'Success';
            case 'Failed':
                return 'Failed';
        }
    }

    const buildErrorIconMessage = () => {
        if (isSubmit && layananImage === null && !isUpdate) {
            return (
                <>
                    <p className="text-red-500 text-xs mt-2">
                        Harap pillih icon layanan 71px x 71px
                    </p>
                </>
            )
        }
    }

    const buildCreateUpdateButton = () => {
        switch (status.type) {
            case 'Loading':
                return (
                    <>
                        <div className="relative mt-3" style={{ height: '2.5rem' }}>
                            <Spinner size="md" color="#000000" cover="parent" />
                        </div>
                    </>
                )
            case 'Success':
                return (
                    <>
                        <button type="submit" className="w-full mt-4 bg-green-500 text-white text-sm font-semibold py-2 rounded hover:bg-green-600">{isUpdate ? 'Perbarui' : 'Simpan'}</button>
                    </>
                )
            case 'Failed':
            case 'Idle':
            default:
                return (
                    <>
                        <button type="submit" className="w-full mt-4 bg-green-500 text-white text-sm font-semibold py-2 rounded hover:bg-green-600">{isUpdate ? 'Perbarui' : 'Simpan'}</button>
                    </>
                )
        }
    }

    const buildAlertDialog = () => {
        switch (status.type) {
            case 'Success':
                return (
                    <>
                        {
                            showAlert ?
                                <ModalAlert
                                    type="success"
                                    title="Status"
                                    subtitle={status.message ? status.message : "Berhasil Membuat Layanan"}
                                    onClose={() => {
                                        resetState()
                                        setShowAlert(false)
                                    }}
                                />
                                : null
                        }
                    </>
                )
            case 'Failed':
                return (
                    <>
                        {
                            showAlert ?
                                <ModalAlert
                                    type="failed"
                                    title="Perhatian"
                                    subtitle={status.message ? status.message : "Unknown Error"}
                                    onClose={() => {
                                        resetState()
                                        setShowAlert(false)
                                    }}
                                />
                                : null
                        }
                    </>
                )
            default:
                return (<></>)
        }
    }

    // Layanan List Functions Below Here

    useEffect(() => {
        fetchLayanan(currentPage, limit)
    }, [])

    useEffect(() => {
        fetchLayanan(currentPage, limit)
    }, [currentPage])

    const buildLayananList = () => {
        console.log(`layanan type : ${layananState.type}`)
        switch (layananState.type) {
            case 'Idle':
            case 'Loading':
                return (
                    <>
                        {/* Daftar Layanan and Tambah Layanan Section */}
                        <div className="mt-10 flex flex-col md:flex-row justify-between items-start md:items-center p-4 bg-white shadow rounded-md">
                            <h2 className="text-gray-900 text-sm mb-2 md:mb-0">Daftar Layanan</h2>
                            <button
                                onClick={() => {
                                    setInputValues({
                                        layanan_index: -1,
                                        layanan_id: 0,
                                        layanan_title: '',
                                        is_active: '',
                                        layanan_description: '',
                                        layanan_image: ''
                                    })
                                    setIsUpdate(false)
                                    setShowModalLayanan(true)
                                }}
                                className="bg-blue-500 text-sm text-white px-4 py-1 rounded hover:bg-blue-600 z-50"
                            >
                                + Tambah Layanan
                            </button>
                        </div>
                        {/* Table Section */}
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-2">
                            <div className="px-5 py-5 bg-white dark:bg-gray-900">
                                <Skeleton width="24.3%" height={30} highlightColor={MaterialGray1} />
                            </div>
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></th>
                                        <th scope="col" className="px-6 py-3"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></th>
                                        <th scope="col" className="px-6 py-3"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                        </th>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                        <td className="px-6 py-4"><Skeleton width="100%" height={30} highlightColor={MaterialGray1} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <nav className="px-4 py-4 flex flex-col-reverse md:flex-row items-center justify-end pt-4" aria-label="Table navigation">
                                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                                    <Skeleton width="100%" height={30} highlightColor={MaterialGray1} />
                                </ul>
                            </nav>
                        </div>
                    </>
                )
            case 'Success':
                return (
                    <>
                        {/* Daftar Layanan and Tambah Layanan Section */}
                        <div className="mt-10 flex flex-col md:flex-row justify-between items-start md:items-center p-4 bg-white shadow rounded-md">
                            <h2 className="text-gray-900 text-sm mb-2 md:mb-0">Daftar Layanan</h2>
                            <button
                                onClick={() => {
                                    setInputValues({
                                        layanan_index: -1,
                                        layanan_id: 0,
                                        layanan_title: '',
                                        is_active: '',
                                        layanan_description: '',
                                        layanan_image: ''
                                    })
                                    setIsUpdate(false)
                                    setShowModalLayanan(true)
                                }}
                                className="bg-blue-500 text-sm text-white px-4 py-1 rounded hover:bg-blue-600 z-50"
                            >
                                + Tambah Layanan
                            </button>
                        </div>
                        {/* Table Section */}
                        <GenericTable
                            headers={tableHeaders}
                            data={layananState.data.rows_data.docs}
                            actions={tableActions}
                            usePagination={true}
                            pagination={{
                                currentPage: currentPage,
                                countTotal: layananState.data.count_total,
                                pageSize: 5,
                                onPageChange: (page: number) => {
                                    setCurrentPage(page)
                                },
                            }}
                        />
                    </>
                )
            case 'Failed':
                return (
                    <>
                        {/* Daftar Layanan and Tambah Layanan Section */}
                        <div className="mt-10 flex flex-col md:flex-row justify-between items-start md:items-center px-4 py-5 bg-white shadow rounded-md">
                            <h2 className="text-gray-900 text-sm mb-2 md:mb-0">Daftar Layanan</h2>
                        </div>
                        <div className="w-full mt-2">
                            <div className="bg-white text-white shadow-lg rounded-lg overflow-hidden">
                                <div className="p-9">
                                    <div className="relative flex flex-col w-full min-h-[50vh] md:min-h-[52.7vh]">
                                        <div className="absolute inset-0 flex flex-col items-center justify-center">
                                            {buildErrorView()}
                                            {buildRetryErrorButton(() => {
                                                fetchLayanan(currentPage, limit)
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                )
        }
    }

    return (
        <div className="relative flex flex-col">

            {buildLayananList()}

            {/* Modal Section */}
            {showModalLayanan && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-[25%] max-h-[90vh] overflow-y-auto p-6 relative">
                        <button
                            onClick={() => {
                                if (status.type != 'Loading') {
                                    setShowModalLayanan(false)
                                }
                            }}
                            className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                        >
                            <IoClose size={24} />
                        </button>
                        <h4 className="text-lg font-semibold mb-4">{isUpdate ? 'Perbarui Layanan' : 'Buat Layanan Baru'}</h4>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (isUpdate) {
                                    updateLayananService()
                                } else {
                                    createLayananService();
                                }
                            }}
                        >
                            <div className="mb-4">
                                <label
                                    className="block text-sm font-medium text-gray-700"
                                    htmlFor="input-title"
                                >
                                    Nama Layanan
                                </label>
                                <input
                                    className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                        isSubmit && !inputValues.layanan_title
                                    )}`}
                                    id="input-title"
                                    type="text"
                                    name="layanan_title"
                                    onChange={onChangeHandler}
                                    value={inputValues.layanan_title}
                                />
                                {isSubmit && !inputValues.layanan_title && (
                                    <p className="text-red-500 text-xs mt-1">Layanan Name must be filled</p>
                                )}
                            </div>

                            <div className="mb-2">
                                <label className="block text-sm font-medium text-gray-700">
                                    Aktifkan Layanan?
                                </label>
                                <div
                                    className={`flex space-x-4 mt-2 ${validateErrorBorder(
                                        isSubmit && inputValues.is_active === ''
                                    )}`}
                                >
                                    <div className="flex items-center">
                                        <input
                                            id="active"
                                            type="radio"
                                            className="form-radio h-6 w-6 text-blue-600 cursor-pointer"
                                            checked={inputValues.is_active === '1'}
                                            onChange={() => setInputValues({ ...inputValues, is_active: '1' })}
                                        />
                                        <label htmlFor="active" className="ml-2 text-sm text-gray-700">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            id="no_active"
                                            type="radio"
                                            className="form-radio h-6 w-6 text-blue-600 cursor-pointer"
                                            checked={inputValues.is_active === '0'}
                                            onChange={() => setInputValues({ ...inputValues, is_active: '0' })}
                                        />
                                        <label htmlFor="no_active" className="ml-2 text-sm text-gray-700">
                                            No
                                        </label>
                                    </div>
                                </div>
                                {isSubmit && inputValues.is_active === '' && (
                                    <p className="text-red-500 text-xs mt-1">Aktifkan Layanan must be selected</p>
                                )}
                            </div>

                            <div>
                                <label
                                    className="block text-sm font-medium text-gray-700"
                                    htmlFor="input-layanan-description"
                                >
                                    Deskripsi Layanan
                                </label>
                                <textarea
                                    className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white rounded-md ${validateErrorBorder(
                                        isSubmit && !inputValues.layanan_description
                                    )}`}
                                    id="input-layanan-description"
                                    name="layanan_description"
                                    onChange={onChangeHandler}
                                    value={inputValues.layanan_description}
                                />
                                {isSubmit && !inputValues.layanan_description && (
                                    <p className="text-red-500 text-xs mt-1">Deskripsi Layanan must be filled</p>
                                )}
                            </div>

                            <div>
                                <label className="block mt-2 text-sm font-medium text-gray-700">Icon Layanan</label>
                                <div
                                    className={`relative mt-3 flex flex-col justify-center items-center w-full h-64 bg-gray-100 rounded-md ${validateErrorBorder(
                                        !layananImage
                                    )}`}
                                >
                                    <input
                                        type="file"
                                        name="image_picker"
                                        onChange={onChangeImageHandler}
                                        ref={fileRef}
                                        hidden
                                    />
                                    {(layananImage && imgProper) || isUpdate ? (
                                        <div className="relative w-full h-full">
                                            <img
                                                src={isUpdate ? layananImage != null ? layananImage : `${BASE_URL}/api/images/${inputValues.layanan_image}` : layananImage!}
                                                className="w-full h-full object-contain rounded-md"
                                            />
                                            {
                                                layananImage && imgProper && (
                                                    <div
                                                        className="absolute top-0 right-0 transform translate-x-2 -translate-y-2 flex items-center justify-center cursor-pointer"
                                                        onClick={() => {
                                                            setLayananImage(null);
                                                            setImgProper(false);
                                                        }}
                                                    >
                                                        <IoIosCloseCircle className='w-8 h-8 text-red-600' />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => fileRef.current?.click()}
                                            className="flex flex-col justify-center items-center text-center cursor-pointer"
                                        >
                                            <img
                                                src={IcImage}
                                                style={{ width: '50px', height: '50px', marginBottom: '8px' }}
                                                alt="Choose Image Icon"
                                            />
                                            <div className="text-gray-500">
                                                Choose image<br />71 x 71
                                            </div>
                                        </div>

                                    )}
                                </div>
                                {buildErrorIconMessage()}
                            </div>
                            {isUpdate && (
                                <>
                                    <button onClick={(event) => {
                                        event.preventDefault()
                                        fileRef.current?.click()
                                    }} className={`w-full mt-4 border-2 border-[${MaterialBlue3}] bg-white text-[${MaterialBlue3}] text-xs font-semibold py-2 rounded hover:bg-[${MaterialBlue3}] hover:text-white`}>Ganti gambar</button>
                                </>
                            )}
                            {buildCreateUpdateButton()}
                        </form>
                    </div>
                </div>
            )}
            {buildAlertDialog()}
            {showModalRemove && (
                <ModalAlert
                    type="warning"
                    title="Perhatian"
                    subtitle={
                        <p className="text-black text-base font-normal mb-4">
                            Yakin ingin menghapus layanan ini?<br/>Ketik nama layanan <p className="text-black text-base font-bold">{inputValues.layanan_title}</p> dan kata sandi akun
                        </p>
                    }
                    showToast={toast}
                    showToastMessage={status.type == 'Success' ? status.message ?? '' : status.type == 'Failed' ? status.message ?? '' : ''}
                    showToastType={buildShowToastType(layananRemoveState.type)}
                    inputs={[
                        { 
                            placeholder: 'Nama layanan', 
                            type: "text", 
                            name : 'remove_layanan_title', 
                            errorInputState : isSubmit && (!validateEmpty(removeValues.remove_layanan_title) || removeValues.remove_layanan_title != inputValues.layanan_title),
                            errorBorderColor : MaterialRed1,
                            errorText : !validateEmpty(removeValues.remove_layanan_title) ? 'Harap isi nama layanan' : removeValues.remove_layanan_title != inputValues.layanan_title ? 'Nama layanan tidak sesuai' : '' ,
                            errorTextColor : MaterialRed1,
                            value: removeValues.remove_layanan_title,
                            onChange: (value) => {
                            setRemoveValues((prevVals) => ({
                                ...prevVals,
                                remove_layanan_title : value
                            }))
                            } 
                        },
                        {   
                            placeholder: 'Kata sandi', 
                            type: "password", 
                            name : 'remove_password', 
                            errorInputState : isSubmit && !validateEmpty(removeValues.remove_password),
                            errorBorderColor : MaterialRed1,
                            errorText : "Harap isi nama layanan",
                            errorTextColor : MaterialRed1,
                            value: removeValues.remove_password,
                            onChange: (value) => {
                            setRemoveValues((prevVals) => ({
                                ...prevVals,
                                remove_password : value
                            }))
                            } 
                        },
                    ]}
                    onClose={() => {
                        if(layananRemoveState.type != 'Loading'){
                            setSubmit(false)
                            setInputValues((prevVals) => ({
                                ...prevVals,
                                layanan_id : -1,
                                layanan_title : ''
                            }))
                            setRemoveValues((prevVals) => ({
                                ...prevVals,
                                remove_layanan_title : '',
                                remove_password : ''
                            }))
                            setShowModalRemove(false)
                        }
                    }}
                    onConfirmEnabled={layananRemoveState.type != 'Loading'}
                    onCancelEnabled={layananRemoveState.type != 'Loading'}
                    enableConfirmBgColor={MaterialRed1}
                    onConfirm={() => {
                        if(layananRemoveState.type != 'Loading'){
                            setSubmit(true)
                            if((validateEmpty(removeValues.remove_layanan_title) && removeValues.remove_layanan_title == inputValues.layanan_title) &&
                                validateEmpty(removeValues.remove_password)){
                                removeLayanan(inputValues.layanan_id , removeValues.remove_password)
                            }
                        }
                    }}
                    onCancel={() => {
                        if(layananRemoveState.type != 'Loading'){
                            setSubmit(false)
                            setInputValues((prevVals) => ({
                                ...prevVals,
                                layanan_id : -1,
                                layanan_title : ''
                            }))
                            setRemoveValues((prevVals) => ({
                                ...prevVals,
                                remove_layanan_title : '',
                                remove_password : ''
                            }))
                            setShowModalRemove(false)
                        }
                    }}
                    showLoading={layananRemoveState.type == "Loading"}
                />
            )}
        </div>
    );
};
