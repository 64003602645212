import { create, StateCreator } from "zustand";
import { ViewState } from "../../components/utilities/ViewState";
import { BaseResponse } from "../../interfaces/base/base-interface";
import { SubServiceAdditional, SubServiceAdditionalCreate, SubServiceAdditionalUpdate } from "../../interfaces/service/sub-service-additional-interface";
import axiosInstance from "../../configApi";
import { AxiosError } from "axios";
import { devtools } from "zustand/middleware";

export interface SubServiceAdditionalState {
    subServiceAdditionalCreate: ViewState<BaseResponse<SubServiceAdditional>>,
    subServiceAdditionalUpdate: ViewState<BaseResponse>,
    subServiceAdditionalRemove: ViewState<BaseResponse>,

    createSubAdd: (request: SubServiceAdditionalCreate) => Promise<void>;
    updateSubAdd: (id: number, request: SubServiceAdditionalUpdate) => Promise<void>;
    removeSubAdd: (id: number, password: string) => Promise<void>;

    resetSubAddState: () => void;
}

const storeSubServiceAdditionalApi: StateCreator<SubServiceAdditionalState> = (set) => ({
    subServiceAdditionalCreate: { type: 'Idle' },
    subServiceAdditionalUpdate: { type: 'Idle' },
    subServiceAdditionalRemove: { type: 'Idle' },

    createSubAdd: async (request: SubServiceAdditionalCreate) => {
        console.log('trigger this')
        try {
            set({
                subServiceAdditionalCreate: {
                    type: 'Loading'
                }
            })
            const { data } = await axiosInstance.post<BaseResponse<SubServiceAdditional>>('/api/sub_service_additional/create', request)
            set({
                subServiceAdditionalCreate: {
                    type: 'Success',
                    data: data
                }
            })
        } catch (error) {
            console.error(error)
            if (error instanceof AxiosError) {
                set({
                    subServiceAdditionalCreate: {
                        type: 'Failed',
                        message: error.response && error.response.data.server_message
                            ? error.response.data.server_message
                            : 'Gagal membuat layanan tambahan',  // Fallback to 'Not Found' if server_message is undefined
                        code: error.response ? error.response.status : 404,
                    },
                });
            } else {
                set({
                    subServiceAdditionalCreate: {
                        type: 'Failed',
                        message: 'Gagal membuat layanan tambahan',
                        code: 404
                    }
                })
            }
        }
    },
    updateSubAdd: async (id: number, request: SubServiceAdditionalUpdate) => {
        try {
            set({
                subServiceAdditionalUpdate: {
                    type: 'Loading'
                }
            })
            const { data } = await axiosInstance.put<BaseResponse>(`/api/sub_service_additional/update/${id}`, request)
            set({
                subServiceAdditionalUpdate: {
                    type: 'Success',
                    data: data
                }
            })
        } catch (error) {
            console.error(error)
            if (error instanceof AxiosError) {
                set({
                    subServiceAdditionalUpdate: {
                        type: 'Failed',
                        message: error.response && error.response.data.server_message
                            ? error.response.data.server_message
                            : 'Gagal memperbarui layanan tambahan',  // Fallback to 'Not Found' if server_message is undefined
                        code: error.response ? error.response.status : 404,
                    },
                });
            } else {
                set({
                    subServiceAdditionalUpdate: {
                        type: 'Failed',
                        message: 'Gagal memperbarui layanan tambahan',
                        code: 404
                    }
                })
            }
        }
    },
    removeSubAdd: async (id: number, password: string) => {
        try {
            set({
                subServiceAdditionalRemove: {
                    type: 'Loading'
                }
            })
            const { data } = await axiosInstance.delete<BaseResponse>(`/api/sub_service_additional/remove/${id}/${password}`)
            set({
                subServiceAdditionalRemove: {
                    type: 'Success',
                    data: data
                }
            })
        } catch (error) {
            console.error(error)
            if (error instanceof AxiosError) {
                set({
                    subServiceAdditionalRemove: {
                        type: 'Failed',
                        message: error.response && error.response.data.server_message
                            ? error.response.data.server_message
                            : 'Gagal menghapus layanan tambahan',  // Fallback to 'Not Found' if server_message is undefined
                        code: error.response ? error.response.status : 404,
                    },
                });
            } else {
                set({
                    subServiceAdditionalRemove: {
                        type: 'Failed',
                        message: 'Gagal menghapus layanan tambahan',
                        code: 404
                    }
                })
            }
        }
    },
    resetSubAddState: () => {
        set({
            subServiceAdditionalCreate: { type: 'Idle' },
            subServiceAdditionalUpdate: { type: 'Idle' },
            subServiceAdditionalRemove: { type: 'Idle' }
        })
    }
})

export const useSubServiceAdditionalStore = create<SubServiceAdditionalState>()(
    devtools(
        storeSubServiceAdditionalApi
    )
)