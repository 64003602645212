import React from 'react';
import Pagination from '../pagination/Pagination';
import { BASE_URL } from '../../configApi';

type LabelDataType = 'text' | 'image'

export type HeaderConfig = {
  label: string;
  label_data: string;
  label_data_type: LabelDataType;
  imgStyle? : {};
  fontColor?: string;
  fontStyle?: React.CSSProperties['fontStyle'];
  fontWeight?: React.CSSProperties['fontWeight'];
};

type ButtonConfig<T> = {
  label: string;
  onClick: (data : T , index : number) => void;
  borderWidth? : string;
  borderColor? : string;
  bgColor: string;
  hoverBgColor: string;
  textColor: string;
  textHoverColor?: string;
  icon?: React.ReactNode;
};

type PaginationProps = {
  currentPage: number;
  countTotal: number;
  pageSize: number;
  onPageChange: (page: number) => void;
};

type DataConfig<T> = T[];

type GenericTableProps<T> = {
  headers: HeaderConfig[];
  data: DataConfig<T>;
  actions?: ButtonConfig<T>[];
  usePagination : boolean;
  pagination?: PaginationProps;
};

type CustomImageProps = {
  src: string;
  alt?: string;
  style: {}
};

const CustomImage: React.FC<CustomImageProps> = ({ src, alt = 'Image' , style }) => {
  return <img src={src} alt={alt} style={style} />;
};

const GenericTable: React.FC<GenericTableProps<any>> = ({ headers, data, actions, usePagination, pagination }) => {

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-2">
      <div className="px-5 py-5 bg-white dark:bg-gray-900">
        <label htmlFor="table-search" className="sr-only">Search</label>
        <div className="relative mt-1">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" aria-hidden="true">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            className="block ps-8 px-5 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-full md:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search nama layanan"
            disabled
          />
        </div>
      </div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {headers.map((header , index) => (
              <th
                key={index}
                className="px-6 py-3"
                style={{
                  color : header.fontColor,
                  fontStyle : header.fontStyle,
                  fontWeight : header.fontWeight
                }}
              >
                {header.label}
              </th>
            ))}
            {actions && <th className="px-6 py-3">Action</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((row , rowIndex) => (
            <tr
              key={rowIndex}
              className="text-black bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              {headers.map((header , colIndex) => (
                <td
                  key={colIndex}
                  className="px-6 py-4"
                >
                  {
                    header.label_data_type == 'text' ?
                    row[header.label_data] : 
                    <CustomImage style={header.imgStyle!} src={`${BASE_URL}${row[header.label_data] == null ? '' : row[header.label_data].startsWith("/") ? `/api/images${row[header.label_data]}` : `/api/images/${row[header.label_data]}`}`}/>
                  }
                </td>
              ))}
              {actions && (
                <td className="px-6 py-4">
                  <div className="inline-flex flex-col md:flex-row gap-3">
                    {actions.map((action , actionIndex) => (
                      <button
                        key={actionIndex}
                        onClick={() => {
                          action.onClick(row , rowIndex)
                        }}
                        className={`text-sm font-semibold py-2 px-4 rounded-xl inline-flex items-center gap-1`}
                        style={{
                          backgroundColor : action.bgColor,
                          color : action.textColor,
                          borderWidth : action.borderWidth,
                          borderColor : action.borderColor
                        }}
                        onMouseEnter={(e) => {
                          (e.currentTarget as HTMLElement).style.backgroundColor = action.hoverBgColor;
                          if(action.textHoverColor != null){
                            (e.currentTarget as HTMLElement).style.color = action.textHoverColor!
                          }
                        }}
                        onMouseLeave={(e) => {
                          (e.currentTarget as HTMLElement).style.backgroundColor = action.bgColor;
                          (e.currentTarget as HTMLElement).style.color = action.textColor;
                        }}
                      >
                        {action.icon && <span>{action.icon}</span>}
                        {action.label}
                      </button>
                    ))}
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {
        usePagination ? 
        <Pagination
        currentPage={pagination?.currentPage!}
        totalCount={pagination?.countTotal!}
        pageSize={pagination?.pageSize!}
        onPageChange={page => {
          console.log(`Page : ${page}`)
          pagination?.onPageChange(page)
        }} /> : null
      }
    </div>
  );
};

export default GenericTable;
