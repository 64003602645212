import { create , StateCreator } from "zustand";
import { ViewState } from "../../components/utilities/ViewState";
import { BaseResponse } from "../../interfaces/base/base-interface";
import { CategoryServiceCreate, CategoryServiceUpdate } from "../../interfaces/category-service/category-service-interface";
import axiosInstance from "../../configApi";
import { AxiosError } from "axios";
import { devtools } from "zustand/middleware";

export interface CategoryState {
    categoryCreateState : ViewState<BaseResponse>;
    categoryUpdateState : ViewState<BaseResponse>;
    categoryRemoveState : ViewState<BaseResponse>;

    createCategoryService : (request : CategoryServiceCreate) => Promise<void>;
    updateCategoryService : (id : number , request : CategoryServiceUpdate) => Promise<void>;
    removeCategoryService : (id : number , password : string) => Promise<void>;

    resetState : () => void;
}

const storeParentApi : StateCreator<CategoryState> = (set) => ({
    categoryCreateState : { type : 'Idle' },
    categoryUpdateState : { type : 'Idle' },
    categoryRemoveState : { type : 'Idle' },


    createCategoryService : async(request : CategoryServiceCreate) => {
        try {
            set({
                categoryCreateState : {
                    type : 'Loading'
                }
            })
            const { data } = await axiosInstance.post<BaseResponse>('/api/category_service/create' , JSON.stringify(request))
            setTimeout(() => {
                set({
                    categoryCreateState : {
                        type : 'Success',
                        data : data
                    }
                })
            } , 350)
        } catch (error){
            setTimeout(() => {
                if(error instanceof AxiosError){
                    set({
                        categoryCreateState : {
                            type : 'Failed',
                            message: error.response && error.response.data.server_message
                                ? error.response.data.server_message
                                : 'Gagal membuat kategori',  // Fallback to 'Not Found' if server_message is undefined
                            code: error.response ? error.response.status : 404,
                        }
                    })
                } else {
                    set({
                        categoryCreateState : {
                            type : 'Failed',
                            message : 'Unknown Error',
                            code : 404
                        }
                    })
                }
            } , 350)
        }
    },
    updateCategoryService : async(id : number , request : CategoryServiceUpdate) => {
        try {
            set({
                categoryUpdateState : {
                    type : 'Loading'
                }
            })
            const { data } = await axiosInstance.put<BaseResponse>(`/api/category_service/update/${id}` , JSON.stringify(request))
            setTimeout(() => {
                set({
                    categoryUpdateState : {
                        type : 'Success',
                        data : data
                    }
                })
            } , 300)
        } catch(error){
            setTimeout(() => {
                if(error instanceof AxiosError){
                    set({
                        categoryCreateState : {
                            type : 'Failed',
                            message: error.response && error.response.data.server_message
                                ? error.response.data.server_message
                                : 'Gagal memperbarui kategori',  // Fallback to 'Not Found' if server_message is undefined
                            code: error.response ? error.response.status : 404,
                        }
                    })
                } else {
                    set({
                        categoryCreateState : {
                            type : 'Failed',
                            message : 'Unknown Error',
                            code : 404
                        }
                    })
                }
            } , 300)
        }
    },
    removeCategoryService : async(id : number , password : string) => {
        try {
            set({
                categoryRemoveState : {
                    type : 'Loading',
                }
            })
            const { data } = await axiosInstance.delete(`/api/category_service/delete/${id}/${password}`)
            setTimeout(() => {
                set({
                    categoryRemoveState : {
                        type : 'Success',
                        data : data
                    }
                })
            } , 300)
        } catch (error){
            setTimeout(() => {
                if(error instanceof AxiosError){
                    set({
                        categoryRemoveState : {
                            type : 'Failed',
                            message: error.response && error.response.data.server_message
                                ? error.response.data.server_message
                                : 'Gagal menghapus kategori',  // Fallback to 'Not Found' if server_message is undefined
                            code: error.response ? error.response.status : 404,
                        }
                    })
                } else {
                    set({
                        categoryRemoveState : {
                            type : 'Failed',
                            message : 'Unknown Error',
                            code : 404
                        }
                    })
                }
            } , 300)
        }
    },
    resetState : () => {
        set({
            categoryCreateState : { type : 'Idle' }
        })
        set({
            categoryUpdateState : { type : 'Idle' }
        })
        set({
            categoryRemoveState : { type : 'Idle' }
        })
    }
})

export const useCategoryStore = create<CategoryState>()(
    devtools(storeParentApi)
)