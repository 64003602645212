import { create, StateCreator } from "zustand";
import { LoginRequest, LoginResponse } from "../../interfaces/auth/auth-interface";
import { ViewState } from "../../components/utilities/ViewState";
import { devtools, persist } from "zustand/middleware";
import axiosInstance from "../../configApi";
import { AxiosError } from "axios";

export interface AuthState {
    loginState : ViewState<LoginResponse>;
    isLoggedIn : boolean;
    token : string;

    loginAdmin : (loginRequest : LoginRequest) => Promise<void>;
}

const storeAuthApi : StateCreator<AuthState> = (set) => ({
    loginState : { type : 'Idle' },
    isLoggedIn : false,
    token : '',

    loginAdmin : async (loginRequest : LoginRequest) => {
        try {
            set({
                loginState : {
                    type : 'Loading'
                }
            })
            const { data } = await axiosInstance.post<LoginResponse>('/api/admin/login' , {
                email : loginRequest.email,
                password : loginRequest.password
            })
            set({
                loginState : {
                    type : 'Success',
                    data : data
                },
                isLoggedIn : true,
                token : data.token
            })
        } catch (error){
            console.error(error)
            if(error instanceof AxiosError){
                const status = error.response?.status
                if(status == 400){
                    set({
                        loginState : {
                            type : 'Failed',
                            message : 'Bad Request from user',
                            code : 400
                        }
                    })
                } else {
                    set({
                        loginState : {
                            type : 'Failed',
                            message : error.response ? error.response.data.server_message : 'Unknown Error',
                            code : error.response ? error.response.status : 404
                        }
                    })
                }
            } else {
                set({
                    loginState : {
                        type : 'Failed',
                        message : 'Unknown Error',
                        code : 404
                    }
                })
            }
        }
    }
})

export const useAuthStore = create<AuthState>()(
    devtools(
        persist(
            storeAuthApi , { name : 'auth-storage'}
        )
    )
)
// export const useAuthStore = create<AuthState>()(
//     devtools(
//         storeAuthApi
//     )
// )