
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
    ChartData,
    LineElement,
    PointElement,
} from 'chart.js';

// Register the necessary components from Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

export const chartBarOptions: ChartOptions<'bar'> = {
    scales: {
        y: {
            ticks: {
                color: 'black',
                callback: function (value) {
                    if (typeof value === 'number' && value % 10 === 0) {
                        return value; // Customize y-axis labels
                    }
                },
            },
        },
        x: {
            ticks: {
                color: 'black'
            }
        }
    },
    plugins: {
        legend: {
            display: false, // This hides the legend
        },
        tooltip: {
            callbacks: {
                label: function (item) {
                    const datasetLabel = item.dataset.label || '';
                    const yLabel = item.raw as number;
                    let content = '';
                    if (datasetLabel) {
                        content += `${datasetLabel}: `;
                    }
                    content += yLabel;
                    return content;
                },
            },
        },
    },
};

export const chartBarData: ChartData<'bar'> = {
    labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            label: 'Order',
            data: [25, 20, 30, 22, 17, 29],
            backgroundColor: 'black',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            maxBarThickness: 50, // Adjust the max bar thickness here
        },
    ],
};

export const chartLineOptions: ChartOptions<'line'> = {
    scales: {
        y: {
            ticks: {
                color: 'white',
                callback: function (value) {
                    if (typeof value === 'number' && value % 10 === 0) {
                        return value; // Customize y-axis labels
                    }
                },
            },
        },
        x: {
            ticks: {
                color: 'white',
                padding: 10, // Add padding to the x-axis labels
            },
            grid: {
                display: false, // Hide x-axis grid lines if needed
            },
        },
    },
    plugins: {
        legend: {
            display: false, // This hides the legend
        },
        tooltip: {
            callbacks: {
                label: function (item) {
                    const datasetLabel = item.dataset.label || '';
                    const yLabel = item.raw as number;
                    let content = '';
                    if (datasetLabel) {
                        content += `${datasetLabel}: `;
                    }
                    content += yLabel;
                    return content;
                },
            },
        },
    },
};

export const chartLineData: ChartData<'line'> = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: 'rgba(75,192,192,1)',
            tension: 0.1,
        },
    ],
};