import { create, StateCreator } from "zustand";
import { ViewState } from "../../components/utilities/ViewState";
import { DashboardResponse } from "../../interfaces/dashboard/dashboard-interface";
import { AxiosError } from "axios";
import axiosInstance from "../../configApi";
import { devtools } from "zustand/middleware";

export interface DashboardState {
    dashboardState : ViewState<DashboardResponse>;

    fetchDashboard : () => Promise<void>;
}

const storeDashboardApi : StateCreator<DashboardState> = (set) => ({
    dashboardState : { type : 'Idle' },

    fetchDashboard : async() => {
        try {
            set({
                dashboardState : {
                    type : 'Loading'
                }
            })
            const { data } = await axiosInstance.get<DashboardResponse>('/api/admin/dashboard')
            setTimeout(() => {
                set({
                    dashboardState : {
                        type : 'Success',
                        data : data
                    }
                })
            } , 350)
        } catch (error){
            if(error instanceof AxiosError){
                set({
                    dashboardState : {
                        type : 'Failed',
                        message : error.response ? error.response.data.server_message : 'Unknown Error',
                        code : error.response ? error.response.status : 404
                    }
                })
            } else {
                set({
                    dashboardState : {
                        type : 'Failed',
                        message : 'Unknown Error',
                        code : 404
                    }
                })
            }
        }
    }
})

export const useDashboardStore = create<DashboardState>()(
    devtools(
        storeDashboardApi
    )
)