import React from 'react';
import { GRAY_4, MaterialRed4, MaterialRed5, validateErrorBorder } from '../utilities/Util';

interface SelectComponentProps<T> {
	label: string;
	options: T[];
	valueKey: keyof T;
	labelKey: keyof T;
	onChange: (value: T[keyof T]) => void;
	isError: boolean;
	initValue?: T[keyof T];
}

const SelectComponent = <T extends {}>({
	label,
	options,
	valueKey,
	labelKey,
	onChange,
	isError,
	initValue
}: SelectComponentProps<T>) => {
	return (
		<div>
			<label
				htmlFor="select"
				className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
			>
				{label}
			</label>
			<select
				id="select"
				className={`bg-gray-100 border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white appearance-none`}
				style={{
					WebkitAppearance: "none",
					MozAppearance: "none",
					appearance: "none",
					borderWidth: '1px',
					borderColor: isError ? MaterialRed5 : 'GRAY_4'
				}}
				onChange={(e) => onChange(e.target.value as unknown as T[keyof T])}
				value={initValue !== undefined ? (initValue as string | number) : ''}
			>
				<option value="" disabled>
					{`Choose a ${label.toLowerCase()}`}
				</option>
				{options.map((option, index) => (
					<option key={index} value={option[valueKey] as string | number}>
						{option[labelKey] as React.ReactNode}
					</option>
				))}
			</select>
		</div>
	);
};

export default SelectComponent;
