import { MdOutlineDashboard, MdCleaningServices, MdPolicy, MdReportProblem } from 'react-icons/md';
import { FaUserGroup, FaNewspaper, FaBook } from 'react-icons/fa6';
import { RiBillFill, RiCalendarScheduleFill } from 'react-icons/ri';
import { GrTransaction } from 'react-icons/gr';
import { IoImages } from 'react-icons/io5';
import { TbHelpSquareFilled } from 'react-icons/tb';
import { DashboardPage } from '../components/pages/DashboardPage';
import { MitraCandidatePage } from '../components/pages/MitraCandidatePage';
import { LayananPage } from '../components/pages/Layanan/LayananPage';
import { OrderPage } from '../components/pages/OrderPage';
import { TransactionPage } from '../components/pages/TransactionPage';
import { MitraPage } from '../components/pages/MitraPage';
import { SchedulePage } from '../components/pages/SchedulePage';
import { BannerPage } from '../components/pages/BannerPage';
import { NewsPage } from '../components/pages/NewsPage';
import { TocPage } from '../components/pages/TocPage';
import { HelpPage } from '../components/pages/HelpPage';
import { GuidePage } from '../components/pages/GuidePage';
import { ReportPage } from '../components/pages/ReportPage';
import { LayananDetailPage } from '../components/pages/Layanan/LayananDetailPage';
import { LayananListServices } from '../components/pages/Layanan/LayananListServices';
import { ServiceDetailPage } from '../components/pages/Layanan/ServiceDetailPage';

// Define the interface for a menu item
interface MenuItem {
  name: string;
  color: string;
  link: string;
  icon: React.ComponentType;
  margin: boolean;
}

export default [
  {
    name: "Dashboard",
    color: "#42a5f5",
    link: "",
    icon: MdOutlineDashboard,
    margin: false,
    element: DashboardPage,
    isSidebar: true,
    isAdmin: true
  },
  {
    name: "Calon Mitra",
    color: "#ffca26",
    link: "calon_mitra",
    icon: FaUserGroup,
    element: MitraCandidatePage,
    margin: false,
    isSidebar: true,
    isAdmin: true
  },
  {
    name: "Daftar Mitra",
    color: "#66bb69",
    link: "daftar_mitra",
    icon: FaUserGroup,
    element: MitraPage,
    margin: false,
    isSidebar: true,
    isAdmin: true
  },
  {
    name: "Layanan",
    color: "#66bb69",
    link: "layanan",
    icon: MdCleaningServices,
    element: LayananPage,
    margin: false,
    isSidebar: true,
    isAdmin: true
  },
  {
    name : 'Service Detail',
    color : '',
    link : 'service_detail',
    icon : '',
    element : ServiceDetailPage,
    margin : false,
    isSidebar : false,
    isAdmin : true
  },
  {
    name : "Layanan Detail",
    color : '',
    link : 'layanan_detail',
    icon : '',
    element : LayananDetailPage,
    margin : false,
    isSidebar : false,
    isAdmin : true
  },
  {
    name : "Layanan List",
    color : '',
    link : 'layanan_list',
    icon : '',
    element : LayananListServices,
    margin : false,
    isSidebar : false,
    isAdmin : true
  },
  {
    name: "Halaman Order",
    color: "#66bb69",
    link: "halaman_order",
    icon: RiBillFill,
    element: OrderPage,
    margin: false,
    isSidebar: true,
    isAdmin: true
  },
  {
    name: "Transaksi",
    color: "#43a047",
    link: "transaksi",
    icon: GrTransaction,
    element: TransactionPage,
    margin: false,
    isSidebar: true,
    isAdmin: true
  },
  {
    name: "Daftar Jadwal",
    color: "#66bb69",
    link: "daftar_jadwal",
    icon: RiCalendarScheduleFill,
    element: SchedulePage,
    margin: false,
    isSidebar: true,
    isAdmin: true
  },
  {
    name: "Banner",
    color: "#27c6da",
    link: "banner",
    icon: IoImages,
    element: BannerPage,
    margin: false,
    isSidebar: true,
    isAdmin: true
  },
  {
    name: "Berita",
    color: "#27c6da",
    link: "berita",
    icon: FaNewspaper,
    element: NewsPage,
    margin: false,
    isSidebar: true,
    isAdmin: true
  },
  {
    name: "Terms Condition",
    color: "#27c6da",
    link: "terms_condition",
    icon: MdPolicy,
    element: TocPage,
    margin: false,
    isSidebar: true,
    isAdmin: true
  },
  {
    name: "Bantuan",
    color: "#66bb69",
    link: "bantuan",
    icon: TbHelpSquareFilled,
    element: HelpPage,
    margin: false,
    isSidebar: true,
    isAdmin: true
  },
  {
    name: "Panduan",
    color: "#66bb69",
    link: "panduan",
    icon: FaBook,
    element: GuidePage,
    margin: false,
    isSidebar: true,
    isAdmin: true
  },
  {
    name: "Keluhan Pengguna",
    color: "#f44336",
    link: "keluhan_pengguna",
    icon: MdReportProblem,
    element: ReportPage,
    margin: false,
    isSidebar: true,
    isAdmin: true
  }
]