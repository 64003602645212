import { useEffect, useReducer, useRef, useState } from "react";
import { useServiceStore } from "../../../stores/services/service.store";
import { useLocation } from "react-router-dom";
import "../../../assets/css/utilities.css"
import SelectComponent from "../../views/SelectComponent";
import { convertBase64ToFile, erorrMsgStyle, formatRupiah, MaterialBlue1, MaterialBlue2, MaterialBlue3, MaterialBlue4, MaterialGray1, MaterialGray2, MaterialGray3, MaterialGray4, MaterialGreen1, MaterialGreen2, MaterialRed1, MaterialRed2, MaterialRed3, MaterialRed4, MaterialRed5, RED_ERROR_MESSAGE, validateEmpty, validateErrorBorder } from "../../utilities/Util";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../../configApi";
import Swal from 'sweetalert2';
import withReactContent from "sweetalert2-react-content";
import { IoClose } from "react-icons/io5";
import { StatusState } from "../../utilities/ViewState";
import { useSubServiceStore } from "../../../stores/sub_service/sub_service.store";
import { SubService, SubServiceCreateUpdate, SubServiceUpdate } from "../../../interfaces/sub_service/sub-service-interface";
import ModalAlert from "../../utilities/ModalAlert";
import { ShowToastType } from "../../utilities/toast";
import { SubServiceAdditional, SubServiceAdditionalCreate, SubServiceAdditionalUpdate } from "../../../interfaces/service/sub-service-additional-interface";
import { useSubServiceAdditionalStore } from "../../../stores/sub_service_additional/sub_service_additional.store";
import Spinner from "../../utilities/Loading";

const MySwal = withReactContent(Swal)

export const ServiceDetailPage = () => {
    const location = useLocation()

    const [toast, setToast] = useState<ShowToastType>('Hide')

    // Service Area
    const serviceDetailState = useServiceStore((store) => store.serviceDetailState)
    const serviceUpdateState = useServiceStore((store) => store.serviceUpdateState)
    const resetState = useServiceStore((store) => store.resetState)

    const getServiceDetail = useServiceStore((store) => store.getServiceDetail)

    // Sub Service Area
    const subServiceCreateUpdateState = useSubServiceStore((store) => store.subServiceCreateUpdateState)
    const subServiceRemoveState = useSubServiceStore((store) => store.subServiceRemoveState)
    const createSubService = useSubServiceStore((store) => store.createSubService)
    const updateSubService = useSubServiceStore((store) => store.updateSubService)
    const removeSubService = useSubServiceStore((store) => store.removeSubService)
    const resetSubServiceState = useSubServiceStore((store) => store.resetState)

    const [subServiceIndex, setSubServiceIndex] = useState<number | null>()

    const [showModalRemove, setShowModalRemove] = useState<boolean>(false)
    const [subServiceCreateIndex, setSubServiceCreateIndex] = useState<number | null>()

    // Sub Service Additional Area
    const [subServiceAddIndex, setSubServiceAddIndex] = useState<number | null>()
    const [subServiceAddRemoveIndex, setSubServiceAddRemoveIndex] = useState<number | null>()

    const subServiceAdditionalCreate = useSubServiceAdditionalStore((store) => store.subServiceAdditionalCreate)
    const subServiceAdditionalUpdate = useSubServiceAdditionalStore((store) => store.subServiceAdditionalUpdate)
    const subServiceAdditionalRemove = useSubServiceAdditionalStore((store) => store.subServiceAdditionalRemove)

    const createSubAdd = useSubServiceAdditionalStore((store) => store.createSubAdd)
    const updateSubAdd = useSubServiceAdditionalStore((store) => store.updateSubAdd)
    const removeSubAdd = useSubServiceAdditionalStore((store) => store.removeSubAdd)
    const resetSubAddState = useSubServiceAdditionalStore((store) => store.resetSubAddState)

    const [yesHover, setYesHover] = useState<boolean>(false)
    const [noHover, setNoHover] = useState<boolean>(false)

    const updateService = useServiceStore((store) => store.updateService)
    const updateServiceImage = useServiceStore((store) => store.updateServiceImage)

    const [showModalRemoveSubAdd, setShowModalRemoveSubAdd] = useState(false)
    const handleOpenCloseModalRemove = () => setShowModalRemoveSubAdd(!showModalRemoveSubAdd)

    const fileRef = useRef<HTMLInputElement>(null)
    const fileRefChange = useRef<HTMLInputElement>(null)

    // Submit Area
    const [isSubmit, setSubmit] = useState(false)
    const [isSubmitCreateUpdate, setSubmitCreateUpdate] = useState(false)
    const [isSubmitAdd, setSubmitAdd] = useState(false)
    const [isCreateAddHover, setCreateAddHover] = useState(false)

    // Loading Area
    const [isLoading, setLoading] = useState(false)
    const [isCreateUpdateLoading, setCreateUpdateLoading] = useState(false)
    const [isLoadingSubAdd, setLoadingSubAdd] = useState<boolean>(false)

    // Modal Area
    const [showModal, setShowModal] = useState(false)
    const [showModalCreateUpdate, setShowModalCreateUpdate] = useState(false)
    const [showModalSubAdd, setShowModalSubAdd] = useState(false)
    const [showModalCreateSubAdd, setShowModalCreateSubAdd] = useState(false)

    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [status, setStatus] = useState<StatusState>({ type: 'Idle' })

    const handleOpenCloseModalCreate = () => {
        if (!isUpdating) {
            setShowModalCreateUpdate(!showModalCreateUpdate)
        }
    }

    // Modal Handler Area
    const handleOpenCloseModal = () => setShowModal(!showModal)
    const handleOpenCloseAddModal = () => setShowModalSubAdd(!showModalSubAdd)
    const handleOpenCloseCreateAddModal = () => setShowModalCreateSubAdd(!showModalCreateSubAdd)

    const [serviceImage, setServiceImage] = useState<string | null>(null);
    const [serviceFile, setServiceFile] = useState<File | null>(null);
    const [isUpdating, setUpdating] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [lastServiceType, setLastServiceType] = useState<string>('')
    const [serviceType, setServiceType] = useState("")

    const [addHover, setAddHover] = useState<boolean>(false)

    const [imgProper, setImgProper] = useState(false);

    const [inputValues, setInputValues] = useReducer(
        (state: InputValues, newState: Partial<InputValues>) => ({ ...state, ...newState }),
        {
            service_id: 0,
            service_name: '',
            service_type: '',
            service_category: '',
            service_description: '',
            service_image_thumbnail: '',
            selectedSubService: []
        }
    );

    const [inputValuesSubAdditional, setInputValuesSubAdditional] = useReducer(
        (state: InputValuesSubAdditional, newState: Partial<InputValuesSubAdditional>) => ({ ...state, ...newState }),
        {
            id: null,
            sub_service_id: -1,
            title: '',
            amount: '',
            discount: 0.0,
            additional_type: '',
            createdAt: '',
            updatedAt: '',
            sub_add_index: -1,
            remove_sub_service_add_id: -1,
            remove_sub_service_add_password_confirm: '',
        }
    );

    const [removeValues, setRemoveValues] = useState({
        remove_sub_price_service_title: '',
        remove_password: ''
    })

    const [removeValuesSubAdd, setRemoveValuesSubAdd] = useState({
        remove_sub_additional_title: '',
        remove_password: ''
    })

    const [inputValuesSubService, setInputValuesSubService] = useReducer(
        (state: InputValuesSubService, newState: Partial<InputValuesSubService>) => ({ ...state, ...newState }),
        {
            id: null,
            service_id: 0,
            sub_price_service_title: '',
            sub_price_service: '',
            sub_service_description: '',
            company_percentage: 0.0,
            minutes_sub_services: 0,
            criteria: '',
            is_recommended: '',
            sub_service_additionals: [],
            sub_service_additionals_create: []
        }
    );

    const options_type = [
        {
            value: 'Durasi', label: 'Durasi'
        },
        {
            value: 'Luas Ruangan', label: 'Luas Ruangan'
        },
        {
            value: 'Single', label: 'Single'
        }
    ]
    const options_category = [
        {
            value: 'Cleaning', label: 'Cleaning'
        },
        {
            value: 'Disinfectant', label: 'Disinfectant'
        },
        {
            value: 'Fogging', label: 'Fogging'
        },
        {
            value: 'Borongan', label: 'Borongan'
        },
        {
            value: 'Lainnya', label: 'Lainnya'
        }
    ]

    const options = [
        { value: 'choice', label: 'Choice' },
        { value: 'cashback', label: 'Cashback' },
        { value: 'discount', label: 'Discount' },
        { value: 'free', label: 'Free' },
    ]
    useEffect(() => {
        if (!showModalRemoveSubAdd) {
            setInputValuesSubAdditional({
                remove_sub_service_add_id: -1,
                remove_sub_service_add_password_confirm: '',
            });
        }
    }, [showModalRemoveSubAdd]);

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputValues({ [name]: value });
    };

    const onChangeCreateHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        if (name === 'company_percentage') {
            console.log(`Val : ${value}`)
            if (parseFloat(value) < 0) {
                setInputValuesSubService({ ['company_percentage']: 0.0 })
            } else {
                setInputValuesSubService({ ['company_percentage']: parseFloat(value) })
            }
        } else if (name === 'sub_price_service') {
            console.log(`Val : ${value}`)
            let sanitizedValue = value.replace(/[^0-9]/g, '');

            // Prevent leading zero
            if (sanitizedValue.length > 1 && sanitizedValue[0] === '0') {
                sanitizedValue = sanitizedValue.substring(1);
            }
            setInputValuesSubService({ [name]: sanitizedValue })
        } else {
            setInputValuesSubService({ [name]: value })
        }
    }
    const onChangeSubAdditionalHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        if (name === 'amount') {
            let sanitizedValue = value.replace(/[^0-9]/g, '');

            // Prevent leading zero
            if (sanitizedValue.length > 1 && sanitizedValue[0] === '0') {
                sanitizedValue = sanitizedValue.substring(1);
            }
            setInputValuesSubAdditional({ ['amount']: sanitizedValue })
        } else if (name === 'discount') {
            if (parseFloat(value) < 0 || parseFloat(value) === 0.0) {
                console.log(`Val : ${(parseFloat(value) === 0.0)}`)
                setInputValuesSubAdditional({ ['discount']: 0 })
            } else {
                setInputValuesSubAdditional({ ['discount']: parseFloat(value) })
            }
        } else {
            setInputValuesSubAdditional({ [name]: value })
        }
    }

    const onChangeImageHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const img = new Image();
            img.onload = () => {
                if (img.width === 450 && img.height === 450) {
                    setImgProper(true);
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setServiceImage(e.target?.result as string)
                        const convertedFile = convertBase64ToFile(e.target?.result as string, file.name);

                        // Store the File object in the state
                        setServiceFile(convertedFile)
                    };
                    reader.readAsDataURL(file);
                } else {
                    setImgProper(false);
                    setServiceImage(null);
                }
            };
            img.src = URL.createObjectURL(file);
            event.target.value = ''
        }
    };

    const buildShowToastType = (status: string) => {
        switch (status) {
            case 'Idle':
            case 'Loading':
                return 'Failed';
            case 'Success':
                return 'Success';
            case 'Failed':
                return 'Failed';
        }
    }

    useEffect(() => {
        getServiceDetail(location.state.service_id)
    }, [])

    useEffect(() => {
        if (serviceDetailState.type == 'Success') {
            setInputValues({
                service_id: serviceDetailState.data.id,
                service_name: serviceDetailState.data.service_name,
                service_type: serviceDetailState.data.service_type,
                service_category: serviceDetailState.data.service_category,
                service_description: serviceDetailState.data.service_description,
                service_image_thumbnail: serviceDetailState.data.service_image_thumbnail,
                selectedSubService: serviceDetailState.data.sub_services
            })
        }
    }, [serviceDetailState])

    useEffect(() => {
        console.log(`Val Input : ${JSON.stringify(inputValues, null, 2)}`)
    }, [inputValues])

    useEffect(() => {
        switch (serviceUpdateState.type) {
            case 'Idle':
                setUpdating(false)
                break
            case 'Loading':
                setUpdating(true)
                break
            case 'Success':
                setUpdating(false)
                setServiceFile(null)
                setServiceImage(null)
                getServiceDetail(location.state.service_id)
                MySwal.fire(
                    'Status',
                    'Berhasil memperbarui service',
                    'success'
                )
                resetState()
                break
            case 'Failed':
                setUpdating(false)
                MySwal.fire(
                    'Perhatian',
                    serviceUpdateState.message ?? '',
                    'error'
                )
                break
        }
    }, [serviceUpdateState])

    useEffect(() => {
        switch (subServiceCreateUpdateState.type) {
            case 'Idle':
                setCreateUpdateLoading(false)
                break
            case 'Loading':
                setCreateUpdateLoading(true)
                break
            case 'Success':
                setCreateUpdateLoading(false)
                setShowModalCreateUpdate(false)
                setSubmitCreateUpdate(false)
                setInputValuesSubService({
                    sub_price_service_title: '',
                    sub_price_service: '',
                    sub_service_description: '',
                    company_percentage: 0.0,
                    minutes_sub_services: 0,
                    criteria: '',
                    is_recommended: '',
                    sub_service_additionals: [],
                    sub_service_additionals_create: []
                })
                resetSubServiceState()
                getServiceDetail(location.state.service_id)
                MySwal.fire(
                    'Status',
                    'Berhasil memperbarui sub service',
                    'success'
                )
                break
            case 'Failed':
                setCreateUpdateLoading(false)
                MySwal.fire(
                    'Perhatian',
                    subServiceCreateUpdateState.message ?? '',
                    'error'
                )
                break
        }
    }, [subServiceCreateUpdateState])

    useEffect(() => {
        switch (subServiceRemoveState.type) {
            case 'Success':
                setSubmit(false)
                setShowModalRemove(false)
                setInputValuesSubService({
                    id: 0,
                    service_id: 0,
                    sub_price_service_title: '',
                    sub_price_service: '',
                    sub_service_description: '',
                    company_percentage: 0.0,
                    minutes_sub_services: 0,
                    criteria: '',
                    is_recommended: '',
                })
                setRemoveValues({
                    remove_sub_price_service_title: '',
                    remove_password: ''
                })
                getServiceDetail(location.state.service_id)
                MySwal.fire(
                    'Status',
                    'Berhasil menghapus sub service',
                    'success'
                )
                break
            case 'Failed':
                setStatus({
                    type: 'Failed',
                    message: subServiceRemoveState.message
                })
                setToast('SlideDown')
                break
        }
    }, [subServiceRemoveState])

    function validateBorderImg() {
        if (isSubmit) {
            if (serviceImage == null || (serviceImage != null && !imgProper)) {
                return `1.5px dashed ${RED_ERROR_MESSAGE}`
            }
        } else {
            if (serviceImage != null && !imgProper) {
                return `1.5px dashed ${RED_ERROR_MESSAGE}`
            }
        }
    }

    function validateTextImg() {
        if (isSubmit) {
            if (serviceImage == null) {
                if (!validateEmpty(inputValues.service_image_thumbnail)) {
                    return (
                        <p style={
                            erorrMsgStyle
                        }>Gambar Paket must be filled</p>
                    )
                }
            } else if (serviceImage != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be 450x X 450px</p>
                )
            } else {
                return (<></>)
            }
        } else {
            if (serviceImage != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be 450px X 450px</p>
                )
            } else {
                return (<></>)
            }
        }
    }

    function updateServiceData() {
        setSubmit(true)
        const {
            service_name,
            service_description,
            service_type,
            service_category
        } = inputValues
        if (validateEmpty(location.state.service_id) &&
            validateEmpty(service_name) &&
            validateEmpty(service_description) &&
            validateEmpty(service_type) &&
            validateEmpty(service_category)) {
            console.log(`S Type : ${service_type} L Type : ${lastServiceType}`)
            if (service_type !== lastServiceType) {
                MySwal.fire({
                    title: 'Perhatian',
                    text: 'Jika Tipe Service berubah, maka seluruh Unit Layanan akan terhapus, Yakin ingin mengubahnya ?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    confirmButtonColor: MaterialGray1,
                    cancelButtonColor: RED_ERROR_MESSAGE,
                    cancelButtonText: 'Tidak'
                }).then((result) => {
                    if (result.isConfirmed) {
                        const payloadUpdate = {
                            id: location.state.service_id,
                            service_name: service_name,
                            service_type: service_type,
                            service_category: service_category,
                            service_description: service_description
                        }
                        console.log(`payload update : ${JSON.stringify(payloadUpdate, null, 2)}`)
                        if (serviceImage != null && serviceFile != null) {
                            const formData = new FormData()
                            formData.append('json_data', JSON.stringify(payloadUpdate))
                            formData.append('file', serviceFile!)
                            updateServiceImage(formData)
                        } else {
                            updateService(payloadUpdate)
                        }
                    }
                })
            } else {
                const payloadUpdate = {
                    id: location.state.service_id,
                    service_name: service_name,
                    service_type: service_type,
                    service_category: service_category,
                    service_description: service_description
                }
                if (serviceImage != null && serviceFile != null) {
                    const formData = new FormData()
                    formData.append('json_data', JSON.stringify(payloadUpdate))
                    formData.append('file', serviceFile)
                    updateServiceImage(formData)
                } else {
                    updateService(payloadUpdate)
                }
            }
        }
    }

    const createSubServiceItem = () => {
        setSubmitCreateUpdate(true)
        const {
            sub_price_service_title,
            sub_price_service,
            sub_service_description,
            company_percentage,
            minutes_sub_services,
            criteria,
            is_recommended
        } = inputValuesSubService
        if (validateEmpty(location.state.service_id) &&
            validateEmpty(sub_price_service_title) &&
            validateEmpty(sub_price_service) &&
            validateEmpty(sub_service_description) &&
            validateEmpty(company_percentage.toString()) &&
            validateEmpty(is_recommended)) {
            if (serviceDetailState.type === 'Success' && serviceDetailState.data.service_type === 'Durasi') {
                if (validateEmpty(minutes_sub_services.toString()) && minutes_sub_services > 0) {
                    const payload: SubServiceCreateUpdate = {
                        service_id: location.state.service_id,
                        sub_price_service_title: sub_price_service_title,
                        sub_price_service: parseInt(sub_price_service),
                        sub_service_description: sub_service_description,
                        company_percentage: company_percentage,
                        minutes_sub_services: minutes_sub_services,
                        criteria: criteria,
                        is_recommended: is_recommended,
                        sub_service_additionals: inputValuesSubService.sub_service_additionals_create
                    }
                    createSubService(payload)
                }
            } else {
                const payload: SubServiceCreateUpdate = {
                    service_id: location.state.service_id,
                    sub_price_service_title: sub_price_service_title,
                    sub_price_service: parseInt(sub_price_service),
                    sub_service_description: sub_service_description,
                    company_percentage: company_percentage,
                    minutes_sub_services: minutes_sub_services,
                    criteria: criteria,
                    is_recommended: is_recommended,
                    sub_service_additionals: inputValuesSubService.sub_service_additionals_create
                }
                createSubService(payload)
            }
        }
    }

    const updateSubServiceItem = () => {
        setSubmitCreateUpdate(true)
        const {
            sub_price_service_title,
            sub_price_service,
            sub_service_description,
            company_percentage,
            minutes_sub_services,
            criteria,
            is_recommended
        } = inputValuesSubService
        console.log(`percentage : ${company_percentage}`)
        if (validateEmpty(location.state.service_id) &&
            validateEmpty(sub_price_service_title) &&
            validateEmpty(sub_price_service) &&
            validateEmpty(sub_service_description) &&
            !Number.isNaN(company_percentage) &&
            validateEmpty(company_percentage.toString()) &&
            inputValuesSubService.id != null &&
            validateEmpty(is_recommended)) {
            if (serviceDetailState.type === 'Success' && serviceDetailState.data.service_type === 'Durasi') {
                if (validateEmpty(minutes_sub_services.toString()) && minutes_sub_services > 0) {
                    const payload: SubServiceUpdate = {
                        service_id: location.state.service_id,
                        sub_price_service_title: sub_price_service_title,
                        sub_price_service: sub_price_service.startsWith("Rp. ") ? parseInt(sub_price_service.split("Rp. ")[1].split(".").join("")) : parseInt(sub_price_service),
                        sub_service_description: sub_service_description,
                        company_percentage: company_percentage,
                        minutes_sub_services: minutes_sub_services,
                        criteria: criteria,
                        is_recommended: is_recommended
                    }
                    console.log(`update data : ${JSON.stringify(payload, null, 2)}`)
                    updateSubService(inputValuesSubService.id!, payload)
                }
            } else {
                const payload: SubServiceUpdate = {
                    service_id: location.state.service_id,
                    sub_price_service_title: sub_price_service_title,
                    sub_price_service: sub_price_service.startsWith("Rp. ") ? parseInt(sub_price_service.split("Rp. ")[1].split(".").join("")) : parseInt(sub_price_service),
                    sub_service_description: sub_service_description,
                    company_percentage: company_percentage,
                    minutes_sub_services: minutes_sub_services,
                    criteria: criteria,
                    is_recommended: is_recommended
                }
                console.log(`update data 1: ${JSON.stringify(payload, null, 2)}`)
                updateSubService(inputValuesSubService.id!, payload)
            }
        }
    }

    const createSubAddItemNotExist = () => {
        setSubmitAdd(true)
        const {
            title,
            amount,
            discount,
            additional_type
        } = inputValuesSubAdditional
        if (validateEmpty(title) &&
            validateEmpty(amount) &&
            validateEmpty(discount.toString()) &&
            validateEmpty(additional_type)) {
            const payload: SubServiceAdditionalCreate = {
                sub_service_id: 0,
                title: title,
                amount: additional_type.toLowerCase() === 'discount' ? discount : parseFloat(amount),
                additional_type: additional_type
            }
            setInputValuesSubService({
                sub_service_additionals_create: [...inputValuesSubService.sub_service_additionals_create, payload]
            })
            setShowModalSubAdd(false)
            setSubmitAdd(false)
            setInputValuesSubAdditional({
                id: -1,
                sub_service_id: -1,
                title: '',
                amount: '',
                discount: 0.0,
                additional_type: '',
                createdAt: '',
                updatedAt: '',
                sub_add_index: -1,
                remove_sub_service_add_id: -1,
                remove_sub_service_add_password_confirm: ''
            })
        }
    }

    const updateSubAddItemNotExist = () => {
        setSubmitAdd(true)
        const {
            title,
            amount,
            discount,
            additional_type
        } = inputValuesSubAdditional
        if (subServiceCreateIndex != null &&
            validateEmpty(title) &&
            validateEmpty(amount) &&
            validateEmpty(discount.toString()) &&
            validateEmpty(additional_type)) {
            const payload: SubServiceAdditionalCreate = {
                sub_service_id: 0,
                title: title,
                amount: additional_type.toLowerCase() === 'discount' ? discount : parseFloat(amount),
                additional_type: additional_type
            }
            const newPayload = inputValuesSubService.sub_service_additionals_create
            newPayload[subServiceCreateIndex!] = payload
            setInputValuesSubService({
                sub_service_additionals_create: newPayload
            })
            setShowModalSubAdd(false)
            setSubmitAdd(false)
            setInputValuesSubAdditional({
                id: -1,
                sub_service_id: -1,
                title: '',
                amount: '',
                discount: 0.0,
                additional_type: '',
                createdAt: '',
                updatedAt: '',
                sub_add_index: -1,
                remove_sub_service_add_id: -1,
                remove_sub_service_add_password_confirm: ''
            })
        }
    }

    useEffect(() => {
        switch (subServiceAdditionalCreate.type) {
            case 'Idle':
                setLoadingSubAdd(false)
                break
            case 'Loading':
                setLoadingSubAdd(true)
                break
            case 'Success':
                setLoadingSubAdd(false)
                setShowModalSubAdd(false)
                setSubmitAdd(false)
                if (subServiceIndex != null) {
                    let payload = inputValues.selectedSubService[subServiceIndex].sub_service_additionals
                    payload.push(subServiceAdditionalCreate.data.data)

                    if (payload.length > 0) {
                        payload.sort((a, b) => b.id - a.id)
                    }

                    const subService = inputValues.selectedSubService[subServiceIndex]
                    subService.sub_service_additionals = payload

                    const subServiceList = inputValues.selectedSubService
                    subServiceList[subServiceIndex] = subService

                    setInputValues({
                        selectedSubService: subServiceList
                    })
                }
                setInputValuesSubAdditional({
                    id: -1,
                    sub_service_id: -1,
                    title: '',
                    amount: '',
                    discount: 0.0,
                    additional_type: '',
                    createdAt: '',
                    updatedAt: '',
                    sub_add_index: -1,
                    remove_sub_service_add_id: -1,
                    remove_sub_service_add_password_confirm: ''
                })
                resetSubAddState()
                MySwal.fire(
                    'Status',
                    'Berhasil menambahkan layanan tambahan',
                    'success'
                )
                break
            case 'Failed':

                break
        }
    }, [subServiceAdditionalCreate])

    useEffect(() => {
        switch (subServiceAdditionalUpdate.type) {
            case 'Idle':
                setLoadingSubAdd(false)
                break
            case 'Loading':
                setLoadingSubAdd(true)
                break
            case 'Success':
                setLoadingSubAdd(false)
                const {
                    id,
                    sub_service_id,
                    title,
                    amount,
                    discount,
                    additional_type
                } = inputValuesSubAdditional
                if (id != null) {
                    const newPayload = inputValuesSubService.sub_service_additionals
                    newPayload[subServiceAddIndex!] = {
                        id: id!,
                        sub_service_id: sub_service_id,
                        title: title,
                        amount: additional_type.toLowerCase() === 'discount' ? discount : parseFloat(amount),
                        additional_type: additional_type
                    }
                    setInputValuesSubService({
                        sub_service_additionals: newPayload
                    })
                }
                setShowModalSubAdd(false)
                setSubmitAdd(false)
                setSubServiceAddIndex(null)
                setInputValuesSubAdditional({
                    id: -1,
                    sub_service_id: -1,
                    title: '',
                    amount: '',
                    discount: 0.0,
                    additional_type: '',
                    createdAt: '',
                    updatedAt: '',
                    sub_add_index: -1,
                    remove_sub_service_add_id: -1,
                    remove_sub_service_add_password_confirm: ''
                })
                resetSubAddState()
                MySwal.fire(
                    'Status',
                    'Berhasil memperbarui layanan tambahan',
                    'success'
                )
                break
            case 'Failed':
                setLoadingSubAdd(false)
                break
        }
    }, [subServiceAdditionalUpdate])

    useEffect(() => {
        switch (subServiceAdditionalRemove.type) {
            case 'Success':
                setSubmit(false)
                setShowModalRemove(false)
                setInputValuesSubAdditional({
                    id: null,
                    title: ''
                })
                setRemoveValuesSubAdd({
                    remove_sub_additional_title: '',
                    remove_password: ''
                })
                if (subServiceIndex != null && subServiceAddRemoveIndex != null) {
                    const payload = inputValues.selectedSubService[subServiceIndex].sub_service_additionals
                    payload.splice(subServiceAddRemoveIndex!, 1)

                    const subService = inputValues.selectedSubService[subServiceIndex]
                    subService.sub_service_additionals = payload

                    const subServiceList = inputValues.selectedSubService
                    subServiceList[subServiceIndex] = subService

                    setInputValues({
                        selectedSubService: subServiceList
                    })
                }
                resetSubAddState()
                MySwal.fire(
                    'Status',
                    'Berhasil menghapus tambahan',
                    'success'
                )
                break
            case 'Failed':
                setStatus({
                    type: 'Failed',
                    message: subServiceAdditionalRemove.message
                })
                setToast('SlideDown')
                break
        }
    }, [subServiceAdditionalRemove])

    useEffect(() => {
        if (toast == 'SlideDown') {
            setTimeout(() => {
                setToast('SlideUp')
                setTimeout(() => {
                    setToast('Hide')
                }, 200)
            }, 1500)
        }
    }, [toast])

    const createSubAddExist = () => {
        setSubmitAdd(true)
        const {
            title,
            amount,
            discount,
            additional_type
        } = inputValuesSubAdditional
        if (inputValuesSubService.id != null &&
            validateEmpty(title) &&
            validateEmpty(additional_type)) {
            if (additional_type.toLowerCase() === 'discount') {
                if (validateEmpty(discount.toString())) {
                    const payload: SubServiceAdditionalCreate = {
                        sub_service_id: inputValuesSubService.id,
                        title: title,
                        amount: discount,
                        additional_type: additional_type
                    }
                    console.log('woy')
                    createSubAdd(payload)
                }
            } else {
                if (validateEmpty(amount)) {
                    const payload: SubServiceAdditionalCreate = {
                        sub_service_id: inputValuesSubService.id,
                        title: title,
                        amount: parseFloat(amount),
                        additional_type: additional_type
                    }
                    createSubAdd(payload)
                }
            }

        }
    }

    const updateSubAddExist = () => {
        setSubmitAdd(true)
        const {
            id,
            sub_service_id,
            title,
            amount,
            discount,
            additional_type
        } = inputValuesSubAdditional
        if (id != null &&
            subServiceAddIndex != null &&
            validateEmpty(title) &&
            validateEmpty(additional_type)) {
            if (additional_type.toLowerCase() === 'discount' && validateEmpty(discount.toString())) {
                const payload: SubServiceAdditionalUpdate = {
                    sub_service_id: sub_service_id,
                    title: title,
                    amount: discount,
                    additional_type: additional_type
                }
                console.log(`index : ${subServiceAddIndex}`)
                console.log(`Payload : ${JSON.stringify(payload, null, 2)}`)
                updateSubAdd(id, payload)
            } else {
                if (validateEmpty(amount)) {
                    const payload: SubServiceAdditionalUpdate = {
                        sub_service_id: sub_service_id,
                        title: title,
                        amount: parseFloat(amount),
                        additional_type: additional_type
                    }
                    console.log(`index : ${subServiceAddIndex}`)
                    console.log(`Payload : ${JSON.stringify(payload, null, 2)}`)
                    updateSubAdd(id, payload)
                }
            }

        }
    }

    const buildServiceDetailPage = () => {
        switch (serviceDetailState.type) {
            case 'Idle':
            case 'Loading':
                return (
                    <>
                        <div className="w-full rounded overflow-hidden shadow-lg bg-white p-6">
                            <div className="py-2">
                                <div className="flex flex-wrap -mx-2">
                                    <div className="w-full sm:w-1/2 p-2">
                                        <div className="flex flex-wrap">
                                            <div className="w-full">
                                                <Skeleton width="100%" height={40} highlightColor={MaterialGray1} />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap" style={{ marginTop: '15px' }}>
                                            <div className="w-full">
                                                <Skeleton width="100%" height={40} highlightColor={MaterialGray1} />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap" style={{ marginTop: '15px' }}>
                                            <div className="w-full">
                                                <Skeleton width="100%" height={40} highlightColor={MaterialGray1} />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap" style={{ marginTop: '15px' }}>
                                            <div className="w-full">
                                                <Skeleton width="100%" height={40} highlightColor={MaterialGray1} />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap" style={{ marginTop: '15px' }}>
                                            <div className="w-full">
                                                <Skeleton width="100%" height={350} highlightColor={MaterialGray1} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full sm:w-1/2 p-2">
                                        <div className="flex flex-wrap">
                                            <div className="w-full">
                                                <Skeleton width="100%" height={350} highlightColor={MaterialGray1} />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap" style={{ marginTop: '15px' }}>
                                            <div className="w-full">
                                                <Skeleton style={{ position: 'absolute', right: 24 }} width="20%" height={30} highlightColor={MaterialGray1} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <Skeleton width="100%" height={40} highlightColor={MaterialGray1} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            case 'Success':
                if (lastServiceType == '')
                    setLastServiceType(serviceDetailState.data.service_type != '' ? serviceDetailState.data.service_type : '')

                return (
                    <>
                        <div className="w-full rounded overflow-hidden shadow-lg bg-white p-6">
                            <div className="py-2">
                                <form onSubmit={(e) => {
                                    e.preventDefault()
                                    updateServiceData()
                                }} className="w-full mx-auto bg-white rounded pt-6 pb-8 px-8 mb-4">
                                    <div className="flex flex-wrap -mx-2">
                                        <div className="w-full sm:w-1/2 p-2">
                                            <div className="flex flex-wrap -mx-2">
                                                <div className="w-full p-2">
                                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                                                        Nama Service
                                                    </label>
                                                    <input className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 text-sm leading-tight focus:outline-none focus:shadow-outline" id="service_name" type="text" placeholder="Nama Service" name="service_name" onChange={onChangeHandler} value={inputValues.service_name} />
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-2">
                                                <div className="w-full sm:w-1/2 p-2">
                                                    <SelectComponent
                                                        label="Tipe Service"
                                                        options={options_type}
                                                        valueKey="value"
                                                        labelKey="label"
                                                        onChange={(value) => {
                                                            setInputValues({
                                                                service_type: value
                                                            })
                                                        }}
                                                        initValue={inputValues.service_type}
                                                        isError={isSubmit && inputValues.service_type == ''}
                                                    />
                                                </div>
                                                <div className="w-full sm:w-1/2 p-2">
                                                    <SelectComponent
                                                        label="Kategori Service"
                                                        options={options_category}
                                                        valueKey="value"
                                                        labelKey="label"
                                                        onChange={(value) => {
                                                            setInputValues({
                                                                service_category: value
                                                            })
                                                        }}
                                                        initValue={inputValues.service_category}
                                                        isError={isSubmit && inputValues.service_category == ''}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                inputValues.selectedSubService.length > 0 ?
                                                    <>
                                                        <h5>{serviceDetailState.data.service_type}</h5>
                                                        <div className="grid grid-cols-2 gap-4">
                                                            {serviceDetailState.data.sub_services.map((item, index) => (
                                                                <div className="w-full" key={index}>
                                                                    <div
                                                                        className="cursor-pointer bg-blue-500 shadow p-4 rounded"
                                                                        style={{ backgroundColor: '#3b82f6' }} // Tambahkan jika MaterialBlue1 memiliki warna spesifik
                                                                    >
                                                                        <p className="text-center text-white rounded mt-5 mx-6 py-1.5 px-4">{item.sub_price_service_title}</p>
                                                                    </div>
                                                                    <div className="mt-2 flex">
                                                                        <div
                                                                            onClick={() => {
                                                                                if (!isUpdating) {
                                                                                    let formattedPrice = validateEmpty(item.sub_price_service.toString())
                                                                                        ? formatRupiah(item.sub_price_service.toString(), 'Rp. ')
                                                                                        : formatRupiah('', 'Rp. ');

                                                                                    const formattedObj = {
                                                                                        ...item,
                                                                                        sub_price_service: formattedPrice,
                                                                                    };

                                                                                    setInputValuesSubService(formattedObj);
                                                                                    setInputValuesSubService({
                                                                                        id: item.id
                                                                                    })
                                                                                    setSubServiceIndex(index)
                                                                                    setShowModalCreateUpdate(true)
                                                                                }
                                                                            }}
                                                                            className="w-1/2 cursor-pointer rounded-lg mr-1.5 pt-3 px-2 bg-blue-600"
                                                                        >
                                                                            <p className="text-center text-xs text-white font-bold" style={{ paddingBottom: '11px' }}>Perbarui</p>
                                                                        </div>
                                                                        <div
                                                                            onClick={() => {
                                                                                if (!isUpdating) {
                                                                                    setInputValuesSubService({
                                                                                        id: item.id,
                                                                                        sub_price_service_title: item.sub_price_service_title
                                                                                    });
                                                                                    setShowModalRemove(true)
                                                                                }
                                                                            }}
                                                                            className="w-1/2 cursor-pointer rounded-lg ml-1.5 pt-3 px-2 bg-red-500"
                                                                        >
                                                                            <p className="text-center text-xs text-white font-bold">Hapus</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className="w-full">
                                                                <div
                                                                    onClick={handleOpenCloseModalCreate}
                                                                    className="cursor-pointer border border-black shadow p-4 rounded"
                                                                >
                                                                    <p className="text-center py-5">+</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="grid grid-cols-2">
                                                            <div className="w-1/2">
                                                                <div
                                                                    onClick={handleOpenCloseModalCreate}
                                                                    className="cursor-pointer border border-black shadow p-4 rounded"
                                                                >
                                                                    <p className="text-center py-5">+</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                        <div className="w-full sm:w-1/2 p-2">
                                            <div className="flex flex-wrap">
                                                <div className="w-full">
                                                    <h5>Gambar Service</h5>
                                                    {
                                                        serviceDetailState.data.service_image_thumbnail === '' ? (
                                                            <div id="placeholder_image"
                                                                style={{
                                                                    width: '100%',
                                                                    height: '260px',
                                                                    borderRadius: '10px',
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    textAlign: 'center',
                                                                    backgroundColor: MaterialGray2,
                                                                    border: '1px dashed black',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={e => {
                                                                    fileRef.current?.click()
                                                                }}
                                                            >
                                                                <input type="file" name="image_picker" ref={fileRef} hidden />
                                                            </div>
                                                        ) : (
                                                            <div style={{
                                                                position: 'relative', // make the container relative
                                                                width: '100%',
                                                                height: '450px',
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                border: serviceImage === null ? '1px dashed black' : '',
                                                                borderRadius: '10px'
                                                            }}>
                                                                {serviceImage === null ? (
                                                                    <div style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        alignItems: 'center',
                                                                        display: 'flex',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <img style={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            objectFit: 'cover',
                                                                            borderRadius: '10px',
                                                                            transition: '0.45s'
                                                                        }} src={`${BASE_URL}/api/images/${serviceDetailState.data.service_image_thumbnail}`} />
                                                                    </div>
                                                                ) : (
                                                                    <div style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        border: validateBorderImg(),
                                                                        borderRadius: '10px'
                                                                    }}>
                                                                        <p style={{
                                                                            position: 'absolute',
                                                                            right: 8,
                                                                            top: 8,
                                                                            zIndex: 10,
                                                                            cursor: 'pointer',
                                                                            color: 'white',
                                                                            border: '1px solid black',
                                                                            paddingBottom: '3px',
                                                                            paddingLeft: '9px',
                                                                            paddingRight: '9px',
                                                                            borderRadius: '50%',
                                                                            backgroundColor: MaterialRed1
                                                                        }}
                                                                            onClick={() => {
                                                                                setServiceFile(null);
                                                                                setServiceImage(null);
                                                                            }}
                                                                        >
                                                                            &times;
                                                                        </p>
                                                                        <img src={serviceImage} style={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            objectFit: 'cover',
                                                                            borderRadius: '10px',
                                                                            display: 'block'
                                                                        }} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap" style={{ marginTop: '10px' }}>
                                                <div className="w-3/4">
                                                    <p className="text-sm text-black">Ukuran gambar 450px x 450px</p>
                                                </div>
                                                <div className="w-1/4">
                                                    <div>
                                                        <input type="file" onChange={onChangeImageHandler} name="image_picker_change" ref={fileRefChange} hidden />
                                                        <button onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!isUpdating) {
                                                                fileRefChange.current?.click();
                                                            }
                                                        }} style={{
                                                            width: '100%',
                                                            backgroundColor: MaterialBlue3,
                                                            paddingTop: '5px',
                                                            paddingBottom: '5px',
                                                            borderRadius: '10px'
                                                        }} className={`text-xs md:text-sm text-white`}>
                                                            Ganti gambar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap">
                                                <div className="w-1/2">
                                                    {validateTextImg()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap">
                                        <div className="w-full">
                                            <button style={{ width: '100%' }} className={`bg-[#2DCE89] rounded-lg py-2 text-white text-sm font-bold`}>
                                                Simpan
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                )
            case 'Failed':
                return (
                    <></>
                )
        }
    }

    return (
        <div className="relative flex flex-col">
            {buildServiceDetailPage()}
            {/*Create Area*/}
            {showModalCreateUpdate && inputValuesSubService.id == null && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-[25%] max-h-[90vh] overflow-y-auto p-6 relative">
                        <button
                            onClick={() => {
                                if (status.type != 'Loading') {
                                    setShowModalCreateUpdate(false)
                                    setSubmit(false)
                                }
                            }}
                            className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                        >
                            <IoClose size={24} />
                        </button>
                        <h4 className="text-lg font-semibold mb-4">Atur Harga</h4>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault()
                                createSubServiceItem()
                            }}
                        >
                            <div className="mb-4">
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="w-full">
                                        <label
                                            className="block text-sm font-medium text-gray-700"
                                            htmlFor="input-title"
                                        >
                                            Satuan Harga
                                        </label>
                                        <input
                                            className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                isSubmitCreateUpdate && !inputValuesSubService.sub_price_service_title
                                            )}`}
                                            id="input-title"
                                            type="text"
                                            name="sub_price_service_title"
                                            onChange={onChangeCreateHandler}
                                            value={inputValuesSubService.sub_price_service_title}
                                        />
                                        {isSubmitCreateUpdate && !inputValuesSubService.sub_price_service_title && (
                                            <p className="text-red-500 text-xs mt-1">Satuan Harga must be filled</p>
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <label
                                            className="block text-sm font-medium text-gray-700"
                                            htmlFor="input-title"
                                        >
                                            Harga
                                        </label>
                                        <input
                                            className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                isSubmitCreateUpdate && !inputValuesSubService.sub_price_service
                                            )}`}
                                            id="input-title"
                                            type="text"
                                            name="sub_price_service"
                                            onChange={onChangeCreateHandler}
                                            value={inputValuesSubService.sub_price_service === '' ? 'Rp. ' : formatRupiah(inputValuesSubService.sub_price_service.toString(), "")}
                                        />
                                        {isSubmitCreateUpdate && !inputValuesSubService.sub_price_service && (
                                            <p className="text-red-500 text-xs mt-1">Harga must be filled</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="w-full">
                                        <label
                                            className="block text-sm font-medium text-gray-700"
                                            htmlFor="input-title"
                                        >
                                            Bagi Hasil Perusahaan
                                        </label>
                                        <input
                                            className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                isSubmitCreateUpdate && !inputValuesSubService.company_percentage
                                            )}`}
                                            id="input-title"
                                            step="0.1"
                                            type="number"
                                            name="company_percentage"
                                            onChange={onChangeCreateHandler}
                                            value={inputValuesSubService.company_percentage}
                                        />
                                        {isSubmitCreateUpdate && !inputValuesSubService.company_percentage && (
                                            <p className="text-red-500 text-xs mt-1">Bagi Hasil Perusahaan must be filled</p>
                                        )}
                                    </div>
                                    {serviceDetailState.type == 'Success' && serviceDetailState.data.service_type === 'Durasi' && (
                                        <div className="w-full">
                                            <label
                                                className="block text-sm font-medium text-gray-700"
                                                htmlFor="input-title"
                                            >
                                                Durasi
                                            </label>
                                            <input
                                                className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                    isSubmitCreateUpdate && !inputValuesSubService.minutes_sub_services
                                                )}`}
                                                id="input-title"
                                                step="0.1"
                                                type="number"
                                                name="minutes_sub_services"
                                                onChange={onChangeCreateHandler}
                                                value={inputValuesSubService.minutes_sub_services}
                                            />
                                            {isSubmitCreateUpdate && inputValuesSubService.minutes_sub_services <= 0 && (
                                                <p className="text-red-500 text-xs mt-1">Menit Durasi must be filled</p>
                                            )}
                                        </div>
                                    )}
                                    {serviceDetailState.type == 'Success' && serviceDetailState.data.service_type === 'Luas Ruangan' && (
                                        <div className="w-full">
                                            <label
                                                className="block text-sm font-medium text-gray-700"
                                                htmlFor="input-title"
                                            >
                                                Luas Ruangan
                                            </label>
                                            <input
                                                className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                    isSubmitCreateUpdate && !inputValuesSubService.criteria
                                                )}`}
                                                id="input-title"
                                                name="criteria"
                                                onChange={onChangeCreateHandler}
                                                value={inputValuesSubService.criteria}
                                            />
                                            {isSubmitCreateUpdate && !inputValuesSubService.criteria && (
                                                <p className="text-red-500 text-xs mt-1">Luas Ruangan must be filled</p>
                                            )}
                                        </div>
                                    )}
                                    {serviceDetailState.type == 'Success' && serviceDetailState.data.service_type === 'Single' && (
                                        <div className="w-full">
                                            <label
                                                className="block text-sm font-medium text-gray-700"
                                                htmlFor="input-title"
                                            >
                                                Single
                                            </label>
                                            <input
                                                className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                    isSubmitCreateUpdate && !inputValuesSubService.criteria
                                                )}`}
                                                id="input-title"
                                                name="criteria"
                                                onChange={onChangeCreateHandler}
                                                value={inputValuesSubService.criteria}
                                            />
                                            {isSubmitCreateUpdate && !inputValuesSubService.criteria && (
                                                <p className="text-red-500 text-xs mt-1">Single must be filled</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="grid grid-cols-4 gap-4">
                                <div className="col-span-3">
                                    <p style={{
                                        fontSize: '13px',
                                        color: 'black'
                                    }}>Layanan Tambahan</p>
                                </div>
                                <div className="col-span-1 text-right">
                                    <div style={{
                                        border: `1px solid ${MaterialGreen1}`,
                                        borderRadius: '10px',
                                        color: addHover ? 'white' : 'black',
                                        fontSize: '11px',
                                        backgroundColor: addHover ? MaterialGreen1 : 'transparent',
                                        cursor: 'pointer'
                                    }}
                                        onClick={() => {
                                            setShowModalSubAdd(true)
                                        }}
                                        onMouseOver={() => {
                                            setAddHover(true)
                                        }}
                                        onMouseOut={() => {
                                            setAddHover(false)
                                        }}
                                    ><p className="text-center">+ Tambah</p></div>
                                </div>
                            </div>
                            {
                                inputValuesSubService.sub_service_additionals_create.length > 0 ?
                                    <div className="grid grid-cols-2 gap-2" style={{ marginTop: '5px' }}>
                                        {
                                            inputValuesSubService.sub_service_additionals_create.map((item, index) => (
                                                <div className="w-full" key={index}>
                                                    <div
                                                        className="cursor-pointer bg-blue-500 shadow p-4 rounded"
                                                        style={{ backgroundColor: '#3b82f6' }} // Tambahkan jika MaterialBlue1 memiliki warna spesifik
                                                    >
                                                        <p className="text-center text-white rounded mx-6 px-4" style={{ fontSize: '13px' }}>{item.title}</p>
                                                    </div>
                                                    <div className="mt-2 flex">
                                                        <div
                                                            onClick={() => {
                                                                setSubServiceCreateIndex(index)
                                                                const item = inputValuesSubService.sub_service_additionals_create[index]
                                                                setInputValuesSubAdditional({
                                                                    title: item.title,
                                                                    amount: item.amount.toString(),
                                                                    additional_type: item.additional_type
                                                                })
                                                                setShowModalSubAdd(true)
                                                            }}
                                                            className="w-1/2 cursor-pointer rounded-lg pt-3 mr-1 px-2 bg-blue-600"
                                                        >
                                                            <p className="text-center text-xs text-white font-bold" style={{ paddingBottom: '11px' }}>Perbarui</p>
                                                        </div>
                                                        <div
                                                            onClick={() => {
                                                                const payload = inputValuesSubService.sub_service_additionals_create
                                                                payload.splice(index, 1)
                                                                setInputValuesSubService({
                                                                    sub_service_additionals_create: payload
                                                                })
                                                            }}
                                                            className="w-1/2 cursor-pointer rounded-lg ml-1 pt-3 px-2 bg-red-500"
                                                        >
                                                            <p className="text-center text-xs text-white font-bold">Hapus</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className="grid grid-cols-1">
                                        <div className="w-full">
                                            <p style={{
                                                color: MaterialGray4,
                                                fontSize: '11px'
                                            }}>Layanan Tambahan belum ada *opsional</p>
                                        </div>
                                    </div>
                            }
                            <div className="grid grid-cols-1" style={{ marginTop: '5px' }}>
                                <div className="w-full">
                                    <label
                                        className="block text-sm font-medium text-gray-700"
                                        htmlFor="input-title"
                                    >
                                        Direkomendasikan ke User
                                    </label>
                                    <div className="grid grid-cols-2 gap-2" style={{ marginTop: '5px' }}>
                                        <div style={{
                                            backgroundColor: yesHover || inputValuesSubService.is_recommended === '1' ? MaterialBlue3 : MaterialGray4,
                                            color: 'white',
                                            fontSize: '15px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            paddingTop: '10px',
                                            paddingBottom: '10px',
                                            borderRadius: '10px',
                                            border: isSubmitCreateUpdate && inputValuesSubService.is_recommended === '' ? `2px solid ${MaterialRed5}` : '',
                                        }}
                                            onMouseOver={() => {
                                                setYesHover(true)
                                            }}
                                            onMouseOut={() => {
                                                setYesHover(false)
                                            }}
                                            onClick={() => {
                                                setInputValuesSubService({ ['is_recommended']: '1' })
                                            }}
                                            className="w-full">
                                            Ya
                                        </div>
                                        <div style={{
                                            backgroundColor: noHover || inputValuesSubService.is_recommended === '0' ? MaterialRed2 : MaterialGray4,
                                            color: 'white',
                                            fontSize: '15px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            paddingTop: '10px',
                                            paddingBottom: '10px',
                                            borderRadius: '10px',
                                            border: isSubmitCreateUpdate && inputValuesSubService.is_recommended === '' ? `2px solid ${MaterialRed5}` : '',
                                        }}
                                            onMouseOver={() => {
                                                setNoHover(true)
                                            }}
                                            onMouseOut={() => {
                                                setNoHover(false)
                                            }}
                                            onClick={() => {
                                                setInputValuesSubService({ ['is_recommended']: '0' })
                                            }}
                                            className="w-full">
                                            Tidak
                                        </div>
                                    </div>
                                    {isSubmitCreateUpdate && inputValuesSubService.is_recommended === '' && (
                                        <p className="text-red-500 text-xs mt-1">Direkomendasikan ke user must be filled</p>
                                    )}
                                </div>
                            </div>
                            <div className="grid grid-cols-1" style={{ marginTop: '10px' }}>
                                <div className="w-full">
                                    <label
                                        className="block text-sm font-medium text-gray-700"
                                        htmlFor="input-description"
                                    >
                                        Deskripsi
                                    </label>
                                    <textarea
                                        className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                            isSubmitCreateUpdate && !inputValuesSubService.sub_service_description
                                        )}`}
                                        id="input-description"
                                        name="sub_service_description"
                                        style={{ height: '120px', textAlign: 'start' }}
                                        onChange={(e) => {
                                            setInputValuesSubService({ ['sub_service_description']: e.target.value })
                                        }}
                                        value={inputValuesSubService.sub_service_description}
                                    />
                                    {isSubmitCreateUpdate && !inputValuesSubService.sub_service_description && (
                                        <p className="text-red-500 text-xs mt-1">Deskripsi must be filled</p>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-wrap">
                                <div className="w-full">
                                    <button style={{ width: '100%' }} className={`bg-[#2DCE89] rounded-lg py-2 text-white text-sm font-bold`}>
                                        Tambah
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Update Area */}
            {showModalCreateUpdate && inputValuesSubService.id != null && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-[25%] max-h-[90vh] overflow-y-auto p-6 relative">
                        <button
                            onClick={() => {
                                if (status.type != 'Loading') {
                                    setShowModalCreateUpdate(false)
                                    setInputValuesSubService({
                                        id: null
                                    })
                                    setSubmitAdd(false)
                                    setInputValuesSubService({
                                        id: 0,
                                        service_id: 0,
                                        sub_price_service_title: '',
                                        sub_price_service: '',
                                        sub_service_description: '',
                                        company_percentage: 0.0,
                                        minutes_sub_services: 0,
                                        criteria: '',
                                        is_recommended: '',
                                        sub_service_additionals: [],
                                        sub_service_additionals_create: []
                                    })
                                }
                            }}
                            className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                        >
                            <IoClose size={24} />
                        </button>
                        <h4 className="text-lg font-semibold mb-4">Atur Harga</h4>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault()
                                updateSubServiceItem()
                            }}
                        >
                            <div className="mb-4">
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="w-full">
                                        <label
                                            className="block text-sm font-medium text-gray-700"
                                            htmlFor="input-title"
                                        >
                                            Satuan Harga
                                        </label>
                                        <input
                                            className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                isSubmitCreateUpdate && !inputValuesSubService.sub_price_service_title
                                            )}`}
                                            id="input-title"
                                            type="text"
                                            name="sub_price_service_title"
                                            onChange={onChangeCreateHandler}
                                            value={inputValuesSubService.sub_price_service_title}
                                        />
                                        {isSubmitCreateUpdate && !inputValuesSubService.sub_price_service_title && (
                                            <p className="text-red-500 text-xs mt-1">Satuan Harga must be filled</p>
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <label
                                            className="block text-sm font-medium text-gray-700"
                                            htmlFor="input-title"
                                        >
                                            Harga
                                        </label>
                                        <input
                                            className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                isSubmitCreateUpdate && !inputValuesSubService.sub_price_service
                                            )}`}
                                            id="input-title"
                                            type="text"
                                            name="sub_price_service"
                                            onChange={onChangeCreateHandler}
                                            value={inputValuesSubService.sub_price_service === '' ? 'Rp. ' : formatRupiah(inputValuesSubService.sub_price_service.toString(), "")}
                                        />
                                        {isSubmitCreateUpdate && !inputValuesSubService.sub_price_service && (
                                            <p className="text-red-500 text-xs mt-1">Harga must be filled</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="w-full">
                                        <label
                                            className="block text-sm font-medium text-gray-700"
                                            htmlFor="input-title"
                                        >
                                            Bagi Hasil Perusahaan
                                        </label>
                                        <input
                                            className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                isSubmitCreateUpdate && !inputValuesSubService.company_percentage
                                            )}`}
                                            id="input-title"
                                            step="0.1"
                                            type="number"
                                            name="company_percentage"
                                            onChange={onChangeCreateHandler}
                                            value={inputValuesSubService.company_percentage}
                                        />
                                        {isSubmitCreateUpdate && !inputValuesSubService.company_percentage && (
                                            <p className="text-red-500 text-xs mt-1">Bagi Hasil Perusahaan must be filled</p>
                                        )}
                                    </div>
                                    {serviceDetailState.type == 'Success' && serviceDetailState.data.service_type === 'Durasi' && (
                                        <div className="w-full">
                                            <label
                                                className="block text-sm font-medium text-gray-700"
                                                htmlFor="input-title"
                                            >
                                                Durasi
                                            </label>
                                            <input
                                                className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                    isSubmitCreateUpdate && !inputValuesSubService.minutes_sub_services
                                                )}`}
                                                id="input-title"
                                                step="0.1"
                                                type="number"
                                                name="minutes_sub_services"
                                                onChange={onChangeCreateHandler}
                                                value={inputValuesSubService.minutes_sub_services}
                                            />
                                            {isSubmitCreateUpdate && inputValuesSubService.minutes_sub_services <= 0 && (
                                                <p className="text-red-500 text-xs mt-1">Menit Durasi must be filled</p>
                                            )}
                                        </div>
                                    )}
                                    {serviceDetailState.type == 'Success' && serviceDetailState.data.service_type === 'Luas Ruangan' && (
                                        <div className="w-full">
                                            <label
                                                className="block text-sm font-medium text-gray-700"
                                                htmlFor="input-title"
                                            >
                                                Luas Ruangan
                                            </label>
                                            <input
                                                className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                    isSubmitCreateUpdate && !inputValuesSubService.criteria
                                                )}`}
                                                id="input-title"
                                                name="criteria"
                                                onChange={onChangeCreateHandler}
                                                value={inputValuesSubService.criteria}
                                            />
                                            {isSubmitCreateUpdate && !inputValuesSubService.criteria && (
                                                <p className="text-red-500 text-xs mt-1">Luas Ruangan must be filled</p>
                                            )}
                                        </div>
                                    )}
                                    {serviceDetailState.type == 'Success' && serviceDetailState.data.service_type === 'Single' && (
                                        <div className="w-full">
                                            <label
                                                className="block text-sm font-medium text-gray-700"
                                                htmlFor="input-title"
                                            >
                                                Single
                                            </label>
                                            <input
                                                className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                    isSubmitCreateUpdate && !inputValuesSubService.criteria
                                                )}`}
                                                id="input-title"
                                                name="criteria"
                                                onChange={onChangeCreateHandler}
                                                value={inputValuesSubService.criteria}
                                            />
                                            {isSubmitCreateUpdate && !inputValuesSubService.criteria && (
                                                <p className="text-red-500 text-xs mt-1">Single must be filled</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="grid grid-cols-4 gap-4">
                                <div className="col-span-3">
                                    <p style={{
                                        fontSize: '13px',
                                        color: 'black'
                                    }}>Layanan Tambahan</p>
                                </div>
                                <div className="col-span-1text-right">
                                    <div style={{
                                        border: `1px solid ${MaterialGreen1}`,
                                        borderRadius: '10px',
                                        color: addHover ? 'white' : 'black',
                                        fontSize: '11px',
                                        backgroundColor: addHover ? MaterialGreen1 : 'transparent',
                                        cursor: 'pointer'
                                    }}
                                        onClick={() => {
                                            setShowModalSubAdd(true)
                                            setInputValuesSubAdditional({
                                                id: null
                                            })
                                            setSubServiceAddIndex(null)
                                        }}
                                        onMouseOver={() => {
                                            setAddHover(true)
                                        }}
                                        onMouseOut={() => {
                                            setAddHover(false)
                                        }}
                                    ><p className="text-center">+ Tambah</p></div>
                                </div>
                            </div>
                            {
                                inputValuesSubService.sub_service_additionals.length > 0 ?
                                    <div className="grid grid-cols-2 gap-2" style={{ marginTop: '5px' }}>
                                        {
                                            inputValuesSubService.sub_service_additionals.map((item, index) => (
                                                <div className="w-full" key={index}>
                                                    <div
                                                        className="cursor-pointer bg-blue-500 shadow p-4 rounded"
                                                        style={{ backgroundColor: '#3b82f6' }} // Tambahkan jika MaterialBlue1 memiliki warna spesifik
                                                    >
                                                        <p className="text-center text-white rounded mx-6 px-4" style={{ fontSize: '13px' }}>{item.title}</p>
                                                    </div>
                                                    <div className="mt-2 flex">
                                                        <div
                                                            onClick={() => {
                                                                const item = inputValuesSubService.sub_service_additionals[index]
                                                                setInputValuesSubAdditional({
                                                                    id: item.id,
                                                                    sub_service_id: item.sub_service_id,
                                                                    title: item.title,
                                                                    amount: item.amount.toString(),
                                                                    discount: item.amount,
                                                                    additional_type: item.additional_type
                                                                })
                                                                setShowModalSubAdd(true)
                                                                setSubServiceAddIndex(index)
                                                            }}
                                                            className="w-1/2 cursor-pointer rounded-lg pt-3 mr-1 px-2 bg-blue-600"
                                                        >
                                                            <p className="text-center text-xs text-white font-bold" style={{ paddingBottom: '11px' }}>Perbarui</p>
                                                        </div>
                                                        <div
                                                            onClick={() => {
                                                                if (!isUpdating) {
                                                                    setInputValuesSubAdditional({
                                                                        id: item.id,
                                                                        title: item.title
                                                                    })
                                                                    setSubServiceAddRemoveIndex(index)
                                                                    setShowModalRemove(true)
                                                                }
                                                            }}
                                                            className="w-1/2 cursor-pointer rounded-lg ml-1 pt-3 px-2 bg-red-500"
                                                        >
                                                            <p className="text-center text-xs text-white font-bold">Hapus</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className="grid grid-cols-1">
                                        <div className="w-full">
                                            <p style={{
                                                color: MaterialGray4,
                                                fontSize: '11px'
                                            }}>Layanan Tambahan belum ada *opsional</p>
                                        </div>
                                    </div>
                            }
                            <div className="grid grid-cols-1">
                                <div className="w-full">
                                    <label
                                        className="block text-sm font-medium text-gray-700"
                                        htmlFor="input-title"
                                    >
                                        Direkomendasikan ke User
                                    </label>
                                    <div className="grid grid-cols-2 gap-2" >
                                        <div style={{
                                            backgroundColor: yesHover || inputValuesSubService.is_recommended === '1' ? MaterialBlue3 : MaterialGray4,
                                            color: 'white',
                                            fontSize: '15px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            paddingTop: '10px',
                                            paddingBottom: '10px',
                                            borderRadius: '10px',
                                            border: isSubmitCreateUpdate && inputValuesSubService.is_recommended === '' ? `2px solid ${MaterialRed5}` : ''
                                        }}
                                            onMouseOver={() => {
                                                setYesHover(true)
                                            }}
                                            onMouseOut={() => {
                                                setYesHover(false)
                                            }}
                                            onClick={() => {
                                                setInputValuesSubService({ ['is_recommended']: '1' })
                                            }}
                                            className="w-full">
                                            Ya
                                        </div>
                                        <div style={{
                                            backgroundColor: noHover || inputValuesSubService.is_recommended === '0' ? MaterialRed2 : MaterialGray4,
                                            color: 'white',
                                            fontSize: '15px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            paddingTop: '10px',
                                            paddingBottom: '10px',
                                            borderRadius: '10px',
                                            border: isSubmitCreateUpdate && inputValuesSubService.is_recommended === '' ? `2px solid ${MaterialRed5}` : ''
                                        }}
                                            onMouseOver={() => {
                                                setNoHover(true)
                                            }}
                                            onMouseOut={() => {
                                                setNoHover(false)
                                            }}
                                            onClick={() => {
                                                setInputValuesSubService({ ['is_recommended']: '0' })
                                            }}
                                            className="w-full">
                                            Tidak
                                        </div>
                                    </div>
                                    {isSubmitCreateUpdate && inputValuesSubService.is_recommended === '' && (
                                        <p className="text-red-500 text-xs mt-1">Direkomendasikan ke user must be filled</p>
                                    )}
                                </div>
                            </div>
                            <div className="grid grid-cols-1" style={{ marginTop: '10px' }}>
                                <div className="w-full">
                                    <label
                                        className="block text-sm font-medium text-gray-700"
                                        htmlFor="input-description"
                                    >
                                        Deskripsi
                                    </label>
                                    <textarea
                                        className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                            isSubmitCreateUpdate && !inputValuesSubService.sub_service_description
                                        )}`}
                                        id="input-description"
                                        name="sub_service_description"
                                        style={{ height: '120px', textAlign: 'start' }}
                                        onChange={(e) => {
                                            setInputValuesSubService({ ['sub_service_description']: e.target.value })
                                        }}
                                        value={inputValuesSubService.sub_service_description}
                                    />
                                    {isSubmitCreateUpdate && !inputValuesSubService.sub_service_description && (
                                        <p className="text-red-500 text-xs mt-1">Deskripsi must be filled</p>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-wrap">
                                <div className="w-full">
                                    <button style={{ width: '100%' }} className={`bg-[#2DCE89] rounded-lg py-2 text-white text-sm font-bold`}>
                                        Perbarui
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {/* Remove Area */}
            {showModalRemove && subServiceAddRemoveIndex == null && (
                <ModalAlert
                    type="warning"
                    title="Perhatian"
                    subtitle={
                        <p className="text-black text-base font-normal mb-4">
                            Yakin ingin menghapus layanan ini?<br />Ketik nama sub service <p className="text-black text-base font-bold">{inputValuesSubService.sub_price_service_title}</p> dan kata sandi akun
                        </p>
                    }
                    showToast={toast}
                    showToastMessage={status.type == 'Success' ? status.message ?? '' : status.type == 'Failed' ? status.message ?? '' : ''}
                    showToastType={buildShowToastType(subServiceRemoveState.type)}
                    inputs={[
                        {
                            placeholder: 'Nama sub service',
                            type: "text",
                            name: 'remove_sub_price_service_title',
                            errorInputState: isSubmit && (!validateEmpty(removeValues.remove_sub_price_service_title) || removeValues.remove_sub_price_service_title != inputValuesSubService.sub_price_service_title),
                            errorBorderColor: MaterialRed1,
                            errorText: !validateEmpty(removeValues.remove_sub_price_service_title) ? 'Harap isi nama sub service' : removeValues.remove_sub_price_service_title != inputValuesSubService.sub_price_service_title ? 'Nama sub service tidak sesuai' : '',
                            errorTextColor: MaterialRed1,
                            value: removeValues.remove_sub_price_service_title,
                            onChange: (value) => {
                                setRemoveValues((prevVals) => ({
                                    ...prevVals,
                                    remove_sub_price_service_title: value
                                }))
                            }
                        },
                        {
                            placeholder: 'Kata sandi',
                            type: "password",
                            name: 'remove_password',
                            errorInputState: isSubmit && !validateEmpty(removeValues.remove_password),
                            errorBorderColor: MaterialRed1,
                            errorText: "Harap isi kata sandi",
                            errorTextColor: MaterialRed1,
                            value: removeValues.remove_password,
                            onChange: (value) => {
                                setRemoveValues((prevVals) => ({
                                    ...prevVals,
                                    remove_password: value
                                }))
                            }
                        },
                    ]}
                    onClose={() => {
                        if (subServiceRemoveState.type != 'Loading') {
                            setSubServiceAddRemoveIndex(null)
                            setSubmit(false)
                            setInputValuesSubService({
                                id: 0,
                                sub_price_service_title: ''
                            })
                            setRemoveValues((prevVals) => ({
                                ...prevVals,
                                remove_layanan_title: '',
                                remove_password: ''
                            }))
                            setShowModalRemove(false)
                        }
                    }}
                    onConfirmEnabled={subServiceRemoveState.type != 'Loading'}
                    onCancelEnabled={subServiceRemoveState.type != 'Loading'}
                    enableConfirmBgColor={MaterialRed1}
                    onConfirm={() => {
                        if (subServiceRemoveState.type != 'Loading' && inputValuesSubService.id != null) {
                            setSubmit(true)
                            if ((validateEmpty(removeValues.remove_sub_price_service_title) && removeValues.remove_sub_price_service_title == inputValuesSubService.sub_price_service_title) &&
                                validateEmpty(removeValues.remove_password)) {
                                removeSubService(inputValuesSubService.id, removeValues.remove_password)
                            }
                        }
                    }}
                    onCancel={() => {
                        if (subServiceRemoveState.type != 'Loading') {
                            setSubServiceAddRemoveIndex(null)
                            setSubmit(false)
                            setInputValuesSubService({
                                id: 0,
                                sub_price_service_title: ''
                            })
                            setRemoveValues((prevVals) => ({
                                ...prevVals,
                                remove_layanan_title: '',
                                remove_password: ''
                            }))
                            setShowModalRemove(false)
                        }
                    }}
                    showLoading={subServiceRemoveState.type == "Loading"}
                />
            )}
            {showModalSubAdd && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-[25%] max-h-[90vh] overflow-y-auto p-6 relative">
                        <button
                            onClick={() => {
                                if (status.type != 'Loading') {
                                    setShowModalSubAdd(false)
                                    setInputValuesSubAdditional({
                                        id: null,
                                        sub_service_id: -1,
                                        title: '',
                                        amount: '',
                                        discount: 0.0,
                                        additional_type: '',
                                        createdAt: '',
                                        updatedAt: '',
                                        sub_add_index: -1,
                                        remove_sub_service_add_id: -1,
                                        remove_sub_service_add_password_confirm: ''
                                    })
                                }
                            }}
                            className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                        >
                            <IoClose size={24} />
                        </button>
                        <h4 className="text-lg font-semibold mb-4">Tambahan</h4>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault()
                                if (inputValuesSubService.id != null) {
                                    if (inputValuesSubAdditional.id != null) {
                                        updateSubAddExist()
                                    } else {
                                        createSubAddExist()
                                    }
                                } else {
                                    if (subServiceCreateIndex != null) {
                                        updateSubAddItemNotExist()
                                    } else {
                                        createSubAddItemNotExist()
                                    }
                                }
                            }}
                        >
                            <div className="mb-4">
                                <div className="grid grid-cols-1">
                                    <div className="w-full">
                                        <label
                                            className="block text-sm font-medium text-gray-700"
                                            htmlFor="input-title"
                                        >
                                            Judul
                                        </label>
                                        <input
                                            className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                isSubmitAdd && !inputValuesSubAdditional.title
                                            )}`}
                                            id="input-title"
                                            type="text"
                                            name="title"
                                            onChange={onChangeSubAdditionalHandler}
                                            value={inputValuesSubAdditional.title}
                                        />
                                        {isSubmitAdd && !inputValuesSubAdditional.title && (
                                            <p className="text-red-500 text-xs mt-1">Judul must be filled</p>
                                        )}
                                    </div>
                                </div>
                                <div className="grid grid-cols-1" style={{ marginTop: '10px' }}>
                                    <div className="w-full">
                                        <SelectComponent
                                            label="Tipe tambahan"
                                            options={options}
                                            valueKey="value"
                                            labelKey="label"
                                            onChange={(value) => {
                                                setInputValuesSubAdditional({
                                                    amount: '',
                                                    additional_type: value
                                                })
                                            }}
                                            initValue={inputValuesSubAdditional.additional_type}
                                            isError={isSubmitAdd && inputValuesSubAdditional.additional_type === ''}
                                        />
                                    </div>
                                    {isSubmitAdd && !inputValuesSubAdditional.additional_type && (
                                        <p className="text-red-500 text-xs mt-1">Tipe tambahan must be filled</p>
                                    )}
                                </div>
                                <div className="grid grid-cols-1" style={{ marginTop: '10px' }}>
                                    {
                                        inputValuesSubAdditional.additional_type.toLowerCase() === 'discount' ?
                                            <div className="w-full">
                                                <label
                                                    className="block text-sm font-medium text-gray-700"
                                                    htmlFor="input-title"
                                                >
                                                    Besaran discount
                                                </label>
                                                <input
                                                    className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                        isSubmitAdd && !inputValuesSubAdditional.discount
                                                    )}`}
                                                    id="input-title"
                                                    step="0.1"
                                                    type="number"
                                                    name="discount"
                                                    onChange={onChangeSubAdditionalHandler}
                                                    value={inputValuesSubAdditional.discount}
                                                />
                                                {isSubmitAdd && !inputValuesSubAdditional.discount && (
                                                    <p className="text-red-500 text-xs mt-1">Besaran discount must be filled</p>
                                                )}
                                            </div>
                                            :
                                            <div className="w-full">
                                                <label
                                                    className="block text-sm font-medium text-gray-700"
                                                    htmlFor="input-title"
                                                >
                                                    Besaran tambahan
                                                </label>
                                                <input
                                                    className={`w-full mt-4 px-3 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${validateErrorBorder(
                                                        isSubmitAdd && !inputValuesSubAdditional.amount
                                                    )}`}
                                                    id="input-title"
                                                    type="text"
                                                    name="amount"
                                                    onChange={onChangeSubAdditionalHandler}
                                                    value={inputValuesSubAdditional.amount === '' ? 'Rp. ' : formatRupiah(inputValuesSubAdditional.amount.toString(), "")}
                                                />
                                                {isSubmitAdd && !inputValuesSubAdditional.amount && (
                                                    <p className="text-red-500 text-xs mt-1">Besaran must be filled</p>
                                                )}
                                            </div>
                                    }
                                </div>
                            </div>

                            <div className="flex flex-wrap">
                                {
                                    inputValuesSubService.id != null ?
                                        <>
                                            {
                                                isLoadingSubAdd ?
                                                    <div className="mb-2 w-full text-center"><Spinner size="sm" /></div>
                                                    :
                                                    <div className="w-full">
                                                        <button style={{ width: '100%' }} className={`bg-[#6cc4ec] rounded-lg py-2 text-white text-sm font-bold`}>
                                                            {inputValuesSubAdditional.id != null ? 'Perbarui' : 'Tambah'}
                                                        </button>
                                                    </div>
                                            }
                                        </>
                                        :
                                        <div className="w-full">
                                            <button style={{ width: '100%' }} className={`bg-[#6cc4ec] rounded-lg py-2 text-white text-sm font-bold`}>
                                                {subServiceCreateIndex != null ? 'Perbarui' : 'Tambah'}
                                            </button>
                                        </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Remove Sub Additional Area */}
            {showModalRemove && subServiceAddRemoveIndex != null && (
                <ModalAlert
                    type="warning"
                    title="Perhatian"
                    subtitle={
                        <p className="text-black text-base font-normal mb-4">
                            Yakin ingin menghapus tambahan ini?<br />Ketik nama tambahan <p className="text-black text-base font-bold">{inputValuesSubAdditional.title}</p> dan kata sandi akun
                        </p>
                    }
                    showToast={toast}
                    showToastMessage={status.type == 'Success' ? status.message ?? '' : status.type == 'Failed' ? status.message ?? '' : ''}
                    showToastType={buildShowToastType(subServiceAdditionalRemove.type)}
                    inputs={[
                        {
                            placeholder: 'Nama tambahan',
                            type: "text",
                            name: 'remove_sub_additional_title',
                            errorInputState: isSubmit && (!validateEmpty(removeValuesSubAdd.remove_sub_additional_title) || removeValuesSubAdd.remove_sub_additional_title != inputValuesSubAdditional.title),
                            errorBorderColor: MaterialRed1,
                            errorText: !validateEmpty(removeValuesSubAdd.remove_sub_additional_title) ? 'Harap isi nama tambahan' : removeValuesSubAdd.remove_sub_additional_title != inputValuesSubAdditional.title ? 'Nama tambahan tidak sesuai' : '',
                            errorTextColor: MaterialRed1,
                            value: removeValuesSubAdd.remove_sub_additional_title,
                            onChange: (value) => {
                                setRemoveValuesSubAdd((prevVals) => ({
                                    ...prevVals,
                                    remove_sub_additional_title: value
                                }))
                            }
                        },
                        {
                            placeholder: 'Kata sandi',
                            type: "password",
                            name: 'remove_password',
                            errorInputState: isSubmit && !validateEmpty(removeValuesSubAdd.remove_password),
                            errorBorderColor: MaterialRed1,
                            errorText: "Harap isi kata sandi",
                            errorTextColor: MaterialRed1,
                            value: removeValuesSubAdd.remove_password,
                            onChange: (value) => {
                                setRemoveValuesSubAdd((prevVals) => ({
                                    ...prevVals,
                                    remove_password: value
                                }))
                            }
                        },
                    ]}
                    onClose={() => {
                        if (subServiceRemoveState.type != 'Loading') {
                            setSubServiceAddRemoveIndex(null)
                            setSubmit(false)
                            setInputValuesSubAdditional({
                                id: 0,
                                title: ''
                            })
                            setRemoveValuesSubAdd((prevVals) => ({
                                ...prevVals,
                                remove_sub_additional_title: '',
                                remove_password: ''
                            }))
                            setShowModalRemove(false)
                        }
                    }}
                    onConfirmEnabled={subServiceAdditionalRemove.type != 'Loading'}
                    onCancelEnabled={subServiceAdditionalRemove.type != 'Loading'}
                    enableConfirmBgColor={MaterialRed1}
                    onConfirm={() => {
                        if (subServiceAdditionalRemove.type != 'Loading' && inputValuesSubAdditional.id != null) {
                            setSubmit(true)
                            if ((validateEmpty(removeValuesSubAdd.remove_sub_additional_title) && removeValuesSubAdd.remove_sub_additional_title == inputValuesSubAdditional.title) &&
                                validateEmpty(removeValuesSubAdd.remove_password)) {
                                removeSubAdd(inputValuesSubAdditional.id, removeValuesSubAdd.remove_password)
                            }
                        }
                    }}
                    onCancel={() => {
                        if (subServiceAdditionalRemove.type != 'Loading') {
                            setSubServiceAddRemoveIndex(null)
                            setSubmit(false)
                            setInputValuesSubAdditional({
                                id: 0,
                                title: ''
                            })
                            setRemoveValuesSubAdd((prevVals) => ({
                                ...prevVals,
                                remove_sub_additional_title: '',
                                remove_password: ''
                            }))
                            setShowModalRemove(false)
                        }
                    }}
                    showLoading={subServiceAdditionalRemove.type == "Loading"}
                />
            )}
        </div>
    )
}

type ServiceType = {
    label: string;
    value: string;
};

type SubServiceAdditionalType = {
    value: string;
    label: string;
};

type InputValues = {
    service_id: number,
    service_name: string,
    service_type: string,
    service_category: string,
    service_description: string,
    service_image_thumbnail: string
    selectedSubService: SubService[];
};


type InputValuesSubAdditional = {
    id: number | null;
    sub_service_id: number;
    title: string;
    amount: string;
    discount: number;
    additional_type: string;
    createdAt: string;
    updatedAt: string;
    sub_add_index: number;
    remove_sub_service_add_id: number;
    remove_sub_service_add_password_confirm: string;
};

type InputValuesSubService = {
    id: number | null;
    service_id: number;
    sub_price_service_title: string;
    sub_price_service: string;
    sub_service_description: string;
    company_percentage: number;
    minutes_sub_services: number;
    criteria: string;
    is_recommended: string;
    sub_service_additionals: SubServiceAdditional[];
    sub_service_additionals_create: SubServiceAdditionalCreate[];
};